import React, { useEffect } from 'react';
import { WarningFilled } from '@carbon/icons-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tablePaginationClasses,
  IndeterminateCheckBoxOutlinedIcon,
  AddBoxOutlinedIcon,
} from '../../../utils/MuiComponents';
import {
  Data,
  columns as Fields,
  Column,
  initialRowData,
  subColumns,
} from '../../../interfaces/report-case';
import { environment } from '../../../environment';
import { apiGet, apiPost } from '../../../services/apiService';
import { ReactComponent as Expiry } from '../../../assets/svg/Expiry.svg';
import { ReactComponent as BlueTag } from '../../../assets/svg/BlueTag.svg';
import './report-cases.scss';
import { DefinitionTooltip, Tooltip } from '@carbon/react';
import {
  Box,
  Collapse,
  IconButton,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import formatDate, {
  dateFormat,
  dateFormatHover,
  expiryDateFormat,
} from '../../../utils/date';
import eventBus from '../../../utils/EventBus';
import CaseModal from '../case-modal-popup/case-modal';
import Loading from '@carbon/react/lib/components/Loading/Loading';
const tooltip_data = require('../../../assets/data/tooltipdesc.json');

interface ReportCasesProps {
  viewType: string;
  type: string;
  selectedTiles: any;
  form: any;
  sendData: any;
  backButtonClicked: string;
  setReportsData: any;
}

export const ReportCases: React.FC<ReportCasesProps> = ({
  viewType,
  type,
  selectedTiles,
  form,
  sendData,
  backButtonClicked,
  setReportsData,
}) => {
  const [rowsCount, setRowsCount] = React.useState(0);
  const [rows, setRows] = React.useState<any[]>([]);
  const [expPage, setExpPage] = React.useState(0);
  const [exprowsPerPage, setExpRowsPerPage] = React.useState(5);
  const [columns, setColumns] = React.useState<Column[]>([]);
  const [sortBy, setSortBy] = React.useState<{
    field: keyof Data | string | undefined;
    order: 'asc' | 'desc' | undefined;
  }>({
    field: undefined,
    order: undefined,
  });
  const [isOpenModal, setOpenModal] = React.useState(false);
  const [rowData, setRowData] = React.useState<Data>(initialRowData);
  const [open, setOpen] = React.useState(null);
  const [wrin, setWrin] = React.useState<string>();
  const [wrinData, setWrinData] = React.useState<any[]>();
  const setExpansion = false;
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [backButtnClicked, setBackButtonClicked] = React.useState(false);
  const [role, setUserRole] = React.useState();

  const handleSort = (columnId: string) => {
    // Determine the sorting order for the clicked column
    setSortBy({
      field: columnId,
      order:
        sortBy.field === columnId && sortBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    setBackButtonClicked(backButtonClicked ? true : false);
  }, [backButtonClicked]);

  useEffect(() => {
    const defaultController = {
      page: 0,
      rowsPerPage: 10,
    };
    const defaultSort = {
      field: undefined,
      order: undefined,
    };
    if (form && selectedTiles) {
      const savedController: any = sessionStorage.getItem('controller');
      const controller = savedController
        ? JSON.parse(savedController)
        : defaultController;
      setController({
        page: backButtnClicked ? controller.page : 0,
        rowsPerPage: controller.rowsPerPage,
      });
      const savedSort: any = sessionStorage.getItem('sortBy');
      setSortBy(savedSort ? JSON.parse(savedSort) : defaultSort);
      if (backButtnClicked) {
        setBackButtonClicked(false);
      }
    }
  }, [selectedTiles, form, viewType]);

  useEffect(() => {
    let URI: any;
    const formData: any = {};

    const user = sessionStorage.getItem('user');
    const userRole = user && JSON.parse(user).role;
    setUserRole(userRole);

    if (type === 'cte5_shelf_life') {
      if (viewType === 'WRIN_view') {
        URI = environment.reportEightWrinViewURL;
        const custom_fields = Fields.filter(
          (item) =>
            item.id !== 'case_serial_number' &&
            item.id !== 'status' &&
            item.id !== 'supplier' &&
            item.id !== 'dc' &&
            item.id !== 'expiration_date' &&
            item.id !== 'days_of_shelf_life' &&
            item.id !== 'outbound_timestamp' &&
            item.id !== 'trash_timestamp'
        );
        // Remove the second item from the array and store it in a variable
        const secondItem = custom_fields.splice(1, 1)[0];

        // Insert the second item at the beginning of the array
        custom_fields.unshift(secondItem);
        setColumns(custom_fields);
      } else {
        URI = environment.reportEightURL;
        setColumns(
          Fields.filter(
            (item) =>
              item.id !== 'status' &&
              item.id !== 'supplier' &&
              item.id !== 'dc' &&
              item.id !== 'outbound_timestamp'
          )
        );
      }
      if (selectedTiles && selectedTiles.label) {
        formData.discrepancy =
          selectedTiles.id === 'all' ? [] : selectedTiles.id;
      }
      if (form) {
        if (viewType !== 'case_view') {
          formData.wrin = form.wrin && form.wrin.param;
        }

        formData.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);

        formData.startExpirationDate =
          form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
        formData.endExpirationDate =
          form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
        formData.category =
          form.category && form.category.category_name
            ? form.category.param
            : formData.category;
        formData.wrinDescription =
          form.item && form.item.wrin_description
            ? form.item.param
            : formData.wrin;
      }
      setRows([]);
    }
    if (type === 'cte2_shelf_life' || type === 'cte4_dc_shelf_life') {
      if (type === 'cte2_shelf_life') {
        setColumns(
          Fields.filter(
            (item) =>
              item.id !== 'status' &&
              item.id !== 'restaurant_id' &&
              item.id !== 'dc' &&
              item.id !== 'storage_location' &&
              item.id !== 'trash_timestamp'
          )
        );
      } else if (type === 'cte4_dc_shelf_life') {
        setColumns(
          Fields.filter(
            (item) =>
              item.id !== 'status' &&
              item.id !== 'restaurant_id' &&
              item.id !== 'storage_location' &&
              item.id !== 'trash_timestamp'
          )
        );
      }
      if (selectedTiles && selectedTiles.label) {
        formData.discrepancy =
          selectedTiles.id === 'all' ? [] : selectedTiles.id;
      }
      if (form) {
        if (type === 'cte2_shelf_life') {
          formData.supplier =
            form.supplier && form.supplier.node_name
              ? form.supplier.node_name === 'All'
                ? []
                : [form.supplier.node_name]
              : formData.supplier;
        }
        if (type === 'cte4_dc_shelf_life') {
          formData.dc =
            form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
        }

        formData.startExpirationDate =
          form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
        formData.endExpirationDate =
          form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
        formData.category =
          form.category && form.category.category_name
            ? form.category.param
            : formData.category;
        formData.wrin =
          form.item && form.item.wrin_description
            ? form.item.param
            : formData.wrin;
      }
      setRows([]);
      URI =
        type === 'cte2_shelf_life'
          ? environment.reportCasesSupplierURL
          : environment.report_fiveURL;
    }
    formData.sorting_for = sortBy ? sortBy.field : '';
    formData.sorting_type = sortBy ? sortBy.order : '';
    const getData = async () => {
      setIsLoading(true);
      const offset = controller.page * controller.rowsPerPage;
      apiPost(
        `${URI}?offset=${offset}&limit=${controller.rowsPerPage}`,
        formData
      )
        .then((data: any) => {
          data.list.map((item: any, index: number) => {
            item.sNo = index;
          });
          setRowsCount(data.totalListCount);
          type === 'cte5_shelf_life'
            ? eventBus.emit('dataEvent', data.last_updated_time)
            : eventBus.emit('dataEvent', data.lastUpdatedTime);
          setRows([...data.list]);
          if (formData.discrepancy === 'all' || !formData.discrepancy.length) {
            setReportsData({ ...data });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setRowsCount(0);
          console.log('Error fetching data: ' + error.message);
        });
    };

    const debouncedFetchData = debounce(getData, 400); // Debounce for 400 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [selectedTiles, form, sortBy, viewType, controller]); // This effect runs only once when the component mounts
  const handleChangePage = (event: unknown, newPage: number) => {
    const _controller = {
      ...controller,
      page: newPage,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  useEffect(() => {
    if (type === 'cte5_shelf_life' && viewType === 'WRIN_view') {
      if (wrin !== undefined) {
        const getWrinData = async () => {
          apiGet<any>(`${environment.reportEightWrinExpansionURL}/${wrin}`)
            .then((data: any) => {
              setWrinData([...data]);
            })
            .catch((error) => {
              console.log('Error fetching data: ' + error.message);
            });
        };
        getWrinData();
      }
    }
  }, [wrin, open, type, viewType]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _controller = {
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  const openModal = (row: Data) => {
    setOpenModal(!isOpenModal);
    setRowData(row);
    sendData(row);
    sessionStorage.setItem('controller', JSON.stringify(controller));
    sessionStorage.setItem('sortBy', JSON.stringify(sortBy));
  };

  const handleExpChangePage = (event: unknown, newPage: number) => {
    setExpPage(newPage);
  };

  const handleExpChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExpRowsPerPage(+event.target.value);
    setExpPage(0);
  };

  const handleOpen = (clickedIndex: any, wrinId: any) => {
    if (open === clickedIndex) {
      setOpen(null);
      setWrinData([]);
    } else {
      setOpen(clickedIndex);
      setWrin(wrinId);
      setExpPage(0);
      setExpRowsPerPage(5);
    }
  };

  return (
    <div className={`custom-table report-case ${type}-table`}>
      {isLoading && <Loading />}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          key="table_1"
          size="small"
        >
          <TableHead>
            <TableRow className="header">
              {viewType === 'WRIN_view' && setExpansion && (
                <TableCell colSpan={1}></TableCell>
              )}
              {columns.map((column) => (
                <TableCell key={column.id} className={role}>
                  <TableSortLabel
                    active={(sortBy && sortBy.field === column.id) || undefined} // Check if sortBy.field matches the current column id
                    direction={
                      sortBy && sortBy.field === column.id
                        ? sortBy.order
                        : undefined // default direction when the column is not active
                    }
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? ( // Check if there are no records
              <TableRow>
                <TableCell colSpan={11}>
                  {' '}
                  <Typography variant="body1" align="center">
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => {
                return (
                  <React.Fragment key={row.sNo}>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.sNo}
                      className={`transition_${row.transition}`}
                    >
                      {viewType === 'WRIN_view' && setExpansion && (
                        <TableCell key={`expand-${index}`}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpen(index, row.wrin)}
                          >
                            {open === index ? (
                              <IndeterminateCheckBoxOutlinedIcon />
                            ) : (
                              <AddBoxOutlinedIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id !== 'case_serial_number' &&
                              column.id !== 'status' &&
                              column.id !== 'discrepancy' &&
                              column.id !== 'expiration_date' &&
                              column.id !== 'outbound_timestamp' &&
                              column.id !== 'days_of_shelf_life' &&
                              value}
                            {column.id === 'discrepancy' &&
                              value &&
                              value.length > 0 &&
                              value.map((item: any) => (
                                <>
                                  {item !== 'Nearing Expiry' && (
                                    <p
                                      className={`discrepancy-col ${item}`}
                                      key={index}
                                    >
                                      {item === 'Expired Cases' && (
                                        <Tooltip
                                          className="tool-tip"
                                          label={tooltip_data[type][item]}
                                          align="bottom"
                                        >
                                          <WarningFilled size={18} />
                                        </Tooltip>
                                      )}
                                      {item === 'FEFO Non Compliant' && (
                                        <Tooltip
                                          className="tool-tip"
                                          label={tooltip_data[type][item]}
                                          align="bottom"
                                          autoAlign
                                        >
                                          <WarningFilled size={18} />
                                        </Tooltip>
                                      )}
                                      {item}
                                    </p>
                                  )}
                                  {item === 'Nearing Expiry' && (
                                    <span className="status-col expiry">
                                      <Tooltip
                                        className="tool-tip"
                                        label={tooltip_data[type][item]}
                                        align="bottom"
                                      >
                                        <Expiry className="near-expiry" />
                                      </Tooltip>
                                      {item}
                                    </span>
                                  )}
                                </>
                              ))}
                            {column.id === 'status' && value && (
                              <span className="status-col expiry">
                                {value === 'Expired' && (
                                  <WarningFilled
                                    className="expired"
                                    size={18}
                                  />
                                )}
                                {value === 'Nearing Expiry' && (
                                  <Expiry className="near-expiry" />
                                )}
                                {value}
                              </span>
                            )}
                            {column.id === 'expiration_date' && value && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={formatDate(value)}
                                >
                                  {expiryDateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'days_of_shelf_life' &&
                              (value > 0 ? value : 0)}
                            {column.id === 'outbound_timestamp' && value && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'case_serial_number' && (
                              <span
                                className="caseId"
                                onClick={() => openModal(row)}
                              >
                                {type === 'cte5_shelf_life' &&
                                  role === 'Admin' &&
                                  (row.is_dc_flag &&
                                  (row.wrin === '13297' ||
                                    row.wrin === '16160') ? (
                                    <BlueTag className="blue-tag-svg" />
                                  ) : (
                                    <span className="blue-tag-vacant-svg"></span>
                                  ))}
                                {value}
                              </span>
                            )}
                            {column.id !== 'case_serial_number' &&
                              column.id !== 'days_of_shelf_life' && (
                                <span className={`${column.id}`}>
                                  {!value && '--'}
                                </span>
                              )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {viewType === 'WRIN_view' && (
                      <TableRow className="expand-row">
                        <TableCell colSpan={12}>
                          <Collapse
                            in={open === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1 }}>
                              <Table
                                size="small"
                                aria-label="purchases"
                                key="table_2"
                              >
                                <TableHead>
                                  <TableRow>
                                    {subColumns.map((column: any) => (
                                      <TableCell key={column.id}>
                                        {column.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {wrinData
                                    ?.slice(
                                      expPage * exprowsPerPage,
                                      expPage * exprowsPerPage + exprowsPerPage
                                    )
                                    .map((historyRow: any) => (
                                      <TableRow
                                        key={
                                          historyRow &&
                                          historyRow.case_serial_number
                                        }
                                      >
                                        {subColumns.map((column) => (
                                          <TableCell key={column.id}>
                                            {column.id !== 'exp_date' &&
                                              historyRow[column.id]}
                                            {column.id === 'exp_date' &&
                                              historyRow[column.id] && (
                                                <span className="date-hover">
                                                  <DefinitionTooltip
                                                    openOnHover
                                                    definition={formatDate(
                                                      historyRow[column.id]
                                                    )}
                                                  >
                                                    {expiryDateFormat(
                                                      historyRow[column.id]
                                                    )}
                                                  </DefinitionTooltip>
                                                </span>
                                              )}
                                            {column.id !==
                                              'case_serial_number' && (
                                              <span className={`${column.id}`}>
                                                {!historyRow[column.id] && '--'}
                                              </span>
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                component="div"
                                count={wrinData ? wrinData.length : 0}
                                rowsPerPage={exprowsPerPage}
                                rowsPerPageOptions={[]}
                                page={expPage}
                                onPageChange={handleExpChangePage}
                                onRowsPerPageChange={handleExpChangeRowsPerPage}
                                sx={{
                                  [`& .${tablePaginationClasses.spacer}`]: {
                                    display: 'none',
                                  },
                                  [`& .${tablePaginationClasses.toolbar}`]: {
                                    justifyContent: 'right',
                                  },
                                }}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rowsCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="custom-pagination"
        sx={{
          [`& .${tablePaginationClasses.spacer}`]: {
            display: 'none',
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: 'center',
          },
        }}
      />
      {isOpenModal && rowData && <CaseModal rowData={rowData} />}
    </div>
  );
};
