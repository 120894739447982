import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
  DefinitionTooltip,
  HeaderMenuItem,
  HeaderNavigation,
} from '@carbon/react';
import { Menu } from '../../interfaces/Menu';
import { AppBar, Drawer, DrawerHeader, MenuList } from './sideNav';
import { UserAvatar } from '@carbon/icons-react';
import MenuItem from './MenuItem';
import {
  lastUpdateDateFormat,
  lastUpdateDateFormatHover,
} from '../../utils/date';
import { HeaderMenu } from '@carbon/react/lib/components/UIShell/HeaderMenu';
import eventBus from '../../utils/EventBus';
import { useEffect } from 'react';
import HeaderLogo from '../../assets/img/McD.png';

export default function Layout({ header }: Readonly<{ header: Menu }>) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  const getUserName = () => {
    const user = sessionStorage.getItem('user');
    return user ? JSON.parse(user).username : '';
  };
  const [receivedData, setReceivedData] = React.useState('');

  useEffect(() => {
    // Subscribe to the data event
    const onDataReceived = (data: string) => {
      setReceivedData(data);
    };

    eventBus.on('dataEvent', onDataReceived);

    // Cleanup
    return () => {
      eventBus.off('dataEvent', onDataReceived);
    };
  }, [receivedData]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: '#F1E9FF', color: ' #000000' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="headerIcon"
          >
            {/* <MenuItem dynamicComponent={header.icon} size={24} /> */}
            <img src={HeaderLogo} alt="HeaderLogo" className="header-logo" />
            <span>{header.text}</span>
          </Typography>
          <HeaderNavigation aria-label="header-menu">
            {receivedData && (
              <HeaderMenuItem>
                <span className="headerTimestamp">
                  <span style={{ marginLeft: '3px' }}>
                    <span>Last Update: </span>
                    <DefinitionTooltip
                      openOnHover
                      definition={
                        receivedData === ''
                          ? ''
                          : lastUpdateDateFormatHover(receivedData)
                      }
                    >
                      {lastUpdateDateFormat(
                        receivedData === '' ? '' : receivedData
                      )}
                    </DefinitionTooltip>
                  </span>
                </span>
              </HeaderMenuItem>
            )}
            <UserAvatar size={24} className="userSVG" />
            <HeaderMenu aria-label="user" menuLinkName={''}>
              <HeaderMenuItem>{getUserName()}</HeaderMenuItem>
              <HeaderMenuItem onClick={() => logout()}>Logout</HeaderMenuItem>
            </HeaderMenu>
          </HeaderNavigation>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {theme.direction !== 'rtl' && (
            <div>
              {/* <p style={{ fontFamily: 'IBM Plex Sans', fontWeight: 600 }}>
                IBM Consulting
              </p> */}
              <p>Efficient Digital</p>
              <p>Supply Chain Platform</p>
            </div>
          )}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {MenuList.map((obj) => (
            <Link key={obj.text} to={obj.link}>
              <ListItem key={obj.text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <MenuItem dynamicComponent={obj.icon} size={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
