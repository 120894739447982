export interface Column {
  id:
    | 'case_serial_number'
    | 'restaurant_id'
    | 'supplier'
    | 'dc'
    | 'wrin'
    | 'wrin_description'
    | 'category_name'
    | 'storage_location'
    | 'expiration_date'
    | 'days_of_shelf_life'
    | 'outbound_timestamp'
    | 'trash_timestamp'
    | 'discrepancy'
    | 'status';
  label?: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
export const columns: Column[] = [
  {
    id: 'case_serial_number',
    label: 'Case ID',
  },
  {
    id: 'restaurant_id',
    label: 'Restaurant ID',
  },
  {
    id: 'supplier',
    label: 'Supplier',
  },
  {
    id: 'dc',
    label: 'DC',
  },
  {
    id: 'wrin',
    label: 'WRIN',
  },
  { id: 'wrin_description', label: 'WRIN Description' },
  {
    id: 'category_name',
    label: 'Category',
  },
  {
    id: 'storage_location',
    label: 'Storage Location',
  },
  {
    id: 'expiration_date',
    label: 'Expiration Date',
  },
  {
    id: 'days_of_shelf_life',
    label: 'Days of Shelf Life',
  },
  {
    id: 'outbound_timestamp',
    label: 'Outbound Timestamp',
  },
  {
    id: 'trash_timestamp',
    label: 'Trash Timestamp',
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
  },
  {
    id: 'status',
    label: 'Status',
  },
];
interface SubColumn {
  id:
    | 'case_serial_number'
    | 'lot_no'
    | 'exp_date'
    | 'days_of_shelf_life'
    | 'onHand'
    | 'unit_of_measure'
    | 'discrepancy';
  label?: string;
  minWidth?: number;
  align?: 'right';
}

export const subColumns: SubColumn[] = [
  {
    id: 'case_serial_number',
    label: 'Case ID',
  },
  {
    id: 'lot_no',
    label: 'Lot No.',
  },
  {
    id: 'exp_date',
    label: 'Expiration Date',
  },
  {
    id: 'days_of_shelf_life',
    label: 'Days of Shelf Life',
  },
  {
    id: 'onHand',
    label: 'On-Hand',
  },
  {
    id: 'unit_of_measure',
    label: 'UOM',
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
  },
];

export interface Data {
  sNo: number;
  case_serial_number: string;
  restaurant?: string;
  supplier?: string;
  dc?: string;
  wrin: string;
  wrin_description: string;
  category_name: string;
  expiration_date: string;
  location: string;
  days_of_shelf_life?: string;
  status?: string;
  trash_timestamp: string;
  outbound_timestamp?: string;
  discrepancy?: string;
  transition: string;
}

export const initialRowData: Data = {
  sNo: 0,
  case_serial_number: '',
  restaurant: '',
  supplier: '',
  dc: '',
  wrin: '',
  wrin_description: '',
  category_name: '',
  expiration_date: '',
  location: '',
  days_of_shelf_life: '',
  status: '',
  outbound_timestamp: '',
  trash_timestamp: '',
  discrepancy: '',
  transition: '',
};
