import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft, Download } from '@carbon/icons-react';
import { Modal } from '@carbon/react';
import './reports.scss';

import { ReportReceipt } from './report-receipt/report-receipt';
import { Filters } from './filters/filters';
import Tiles from './selectable-tiles/tiles';
import { ReceiptComparison } from './report-comparison/report-comparison';
import { ReportCases } from './report-cases/report-cases';
import { InventoryStorage } from './inventory-storage/inventory-storage';
import { environment } from '../../environment';
import { Checkbox, Loading, Toggle } from '@carbon/react';
import { ReactComponent as UnderReview } from '../../assets/svg/under-review.svg';
import CaseDigitalJourney from './case-digital-journey/case-digital-journey';
import { apiPostDownload } from '../../services/apiService';
import { RestaurantOrderAccuracy } from './restaurant-order-accuracy/restaurant-order-accuracy';
import { InventoryDailySummary } from './inventory-daily-summary/inventory-daily-summary';
const Dropdowns = require('../../assets/data/report-filters.json');
const Tiles_Data = require('../../assets/data/tiles.json');
import { daysDifference } from '../../assets/data/date-range.json';
import { ReactComponent as Alert } from '../../assets/svg/Alert.svg';
import { ReactComponent as BlueTag } from '../../assets/svg/BlueTag.svg';

interface Data {
  id: string;
  text: string;
  tabletext: string;
  form: any;
  range?: () => {
    startDate: null | Date;
    endDate: null | Date;
  };
}

const Reports: React.FC = () => {
  const [dataFromChild, setDataFromChild] = useState<Data>(
    Dropdowns.reportsList.Admin[0]
  );
  const [dataFromTiles, setDataFromTiles] = useState(
    Tiles_Data['cte1_vs_cte2'][1]
  );
  const [viewType, setViewType] = useState('case_view');
  const [viewTransferType, setTransferViewType] = useState('Transfer_In');
  const [selectedTileLabel, setSelectedTileLabel] = useState('All');
  const [showDigitalJourney, setShowDigitalJourney] = useState(false);
  const [caseIDSearch, setCaseIDSearch] = useState('');
  const [formSavedData, setFormData] = useState<Data | null>(null);
  const [selectedTileIndex, setTileIndex] = useState(null);
  const [sendReportsData, setReportsData] = useState<any>();
  const [downloadAlert, setDownloadAlert] = useState<boolean>(false);

  const [checkBoxChecked, setCheckBox] = useState(false);
  const toggleStateRef = useRef(viewTransferType);
  const [active, setActive] = useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const handleDataFromChild = (data: {
    id: string;
    text: string;
    tabletext: string;
    form: any;
  }) => {
    setDataFromChild({ ...data });
  };

  const onChangeReport = (data: any) => {
    setCaseIDSearch('');
    if (data.id === 'cte2_vs_cte3' || data.id === 'cte5_shelf_life') {
      setViewType('case_view');
    }
  };

  useEffect(() => {
    if (
      dataFromTiles.id == 'all' ||
      selectedTileLabel === 'Compliant' ||
      selectedTileLabel === 'On-Time'
    ) {
      setCheckBox(false);
    }
  }, [dataFromTiles, selectedTileLabel]);

  useEffect(() => {
    // This will run after the state has updated
    setCheckBox(checkBoxChecked);
  }, [checkBoxChecked]);

  const handleTilesData = (data: { id: string; label: string }) => {
    setDataFromTiles(data);
    setSelectedTileLabel(
      data.label === 'Compliant' || data.label === 'On-Time'
        ? 'All'
        : data.label
    );
  };

  const handleToggleChange = (selected: boolean) => {
    setViewType(selected ? 'pallet_view' : 'case_view');
  };
  const handleToggleChangeWRIN = (selected: boolean) => {
    setViewType(selected ? 'WRIN_view' : 'case_view');
    if (selected) {
      handleTilesData({
        id: 'all',
        label: 'All',
      });
    }
  };

  const handleRestTransferToggleChange = (selected: boolean) => {
    // setTransferViewType(selected ? 'Transfer_Out' : 'Transfer_In');
    if (selected) {
      toggleStateRef.current = 'Transfer_Out';
      setActive(true);
      setTransferViewType('Transfer_Out');
    } else {
      toggleStateRef.current = 'Transfer_In';
      setActive(false);
      setTransferViewType('Transfer_In');
    }
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckBox(true);
      setDataFromTiles({
        label: 'On-Time',
        count: 0,
        id: 'On-Time',
        pass_value: 'ontimecount',
      });
    } else {
      setDataFromTiles({
        label: 'All',
        count: 0,
        id: 'all',
        pass_value: 'totalCount',
      });
    }
  };
  const handleClickComplaint = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckBox(true);
      setDataFromTiles({
        label: 'Compliant',
        count: 0,
        id: ['', 'Nearing Expiry'],
        pass_value: 'complaint_count',
      });
    } else {
      setDataFromTiles({
        label: 'All',
        count: 0,
        id: 'all',
        pass_value: 'totalCount',
      });
    }
  };

  const handleClickExact = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckBox(true);
      setDataFromTiles({
        label: 'Exact',
        count: 0,
        id: 'exact',
        pass_value: 'exact_count',
      });
    } else {
      setDataFromTiles({
        label: 'All',
        count: 0,
        id: 'all',
        pass_value: 'totalCount',
        default_selected: true,
      });
    }
  };

  const isAdmin = () => {
    const user = sessionStorage.getItem('user');
    const userRole = user && JSON.parse(user).role;
    return userRole === 'Admin';
  };

  const formData = {
    supplier: [] as string[],
    startPutAwayDate: 'all',
    endPutAwayDate: 'all',
    startDispatchDate: 'all',
    endDispatchDate: 'all',
    discrepancy: 'all',
  };

  const downloadValidation = (startDate: any, endDate: any) => {
    if (startDate === 'all' || endDate === 'all') {
      return true;
    }
    const daysDiff = daysDifference(startDate, endDate);
    console.log(daysDiff);
    return daysDiff >= 31;
  };

  const onDownload = () => {
    let isBlockDownload = false;
    const formReportCasesData: any = {};
    if (dataFromChild.id === 'cte1_vs_cte2') {
      if (dataFromTiles.id) {
        formData.discrepancy = dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        formData.supplier =
          form.supplier && form.supplier.node_name
            ? form.supplier.node_name === 'All'
              ? []
              : [form.supplier.node_name]
            : formData.supplier;

        formData.startDispatchDate = form.startDispatchDate;
        formData.endDispatchDate = form.endDispatchDate;
        formData.startPutAwayDate = form.startPutAwayDate;
        formData.endPutAwayDate = form.endPutAwayDate;

        const startDispatchDate = form.startDispatchDate?.toLowerCase();
        const endDispatchDate = form.endDispatchDate?.toLowerCase();
        const startPutAwayDate = form.startPutAwayDate?.toLowerCase();
        const endPutAwayDate = form.endPutAwayDate?.toLowerCase();

        const isDispatchDateValid = downloadValidation(
          startDispatchDate,
          endDispatchDate
        );
        const isPutAwayDateValid = downloadValidation(
          startPutAwayDate,
          endPutAwayDate
        );
        if (
          (startDispatchDate === 'all' || endDispatchDate === 'all') &&
          (startPutAwayDate !== 'all' || endPutAwayDate !== 'all')
        ) {
          isBlockDownload = isPutAwayDateValid;
        } else if (
          (startDispatchDate !== 'all' || endDispatchDate !== 'all') &&
          (startPutAwayDate === 'all' || endPutAwayDate === 'all')
        ) {
          isBlockDownload = isDispatchDateValid;
        } else if (
          (startDispatchDate !== 'all' && endDispatchDate !== 'all') ||
          (startPutAwayDate !== 'all' && endPutAwayDate !== 'all')
        ) {
          isBlockDownload = isDispatchDateValid && isPutAwayDateValid;
        } else if (
          startDispatchDate === 'all' &&
          endDispatchDate === 'all' &&
          startPutAwayDate === 'all' &&
          endPutAwayDate === 'all'
        ) {
          isBlockDownload = true;
        }
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.report_six_downloadURL,
          formData,
          'Inventory Storage and Ageing Report at Suppliers'
        ); // Proceed with download if not blocked
      }
    } else if (dataFromChild.id === 'cte2_shelf_life') {
      if (dataFromTiles.id) {
        formReportCasesData.discrepancy =
          dataFromTiles.id === 'all' ? [] : dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        formReportCasesData.supplier =
          form.supplier && form.supplier.node_name
            ? form.supplier.node_name === 'All'
              ? []
              : [form.supplier.node_name]
            : formData.supplier;

        formReportCasesData.startExpirationDate =
          form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
        formReportCasesData.endExpirationDate =
          form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
        formReportCasesData.category =
          form.category && form.category.category_name
            ? form.category.param
            : formReportCasesData.category;
        formReportCasesData.wrin =
          form.item && form.item.wrin_description
            ? form.item.param
            : formReportCasesData.wrin;

        const startExpirationDate = formReportCasesData.startExpirationDate;
        const endExpirationDate = formReportCasesData.endExpirationDate;
        isBlockDownload = downloadValidation(
          startExpirationDate,
          endExpirationDate
        );
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportCasesSupplierDownloadURL,
          formReportCasesData,
          'Cases with shelf life details at Suppliers'
        );
      }
    } else if (dataFromChild.id === 'cte2_vs_cte3') {
      const formReportThreeData: any = {};
      if (dataFromTiles.id) {
        formReportThreeData.discrepancy = dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        formReportThreeData.viewType =
          viewType === 'case_view' ? viewType : 'pallet_view';
        formReportThreeData.supplier =
          form.supplier && form.supplier.node_name
            ? form.supplier.node_name === 'All'
              ? []
              : [form.supplier.node_name]
            : formReportThreeData.supplier;

        formReportThreeData.startDispatchDate = form.startDispatchDate;
        formReportThreeData.endDispatchDate = form.endDispatchDate;
        formReportThreeData.startReceiptDate = form.startReceiptDate;
        formReportThreeData.endReceiptDate = form.endReceiptDate;
        formReportThreeData.startExpectedDeliveryDate = 'all';
        formReportThreeData.endExpectedDeliveryDate = 'all';
        formReportThreeData.wrin = form.wrin && form.wrin.param;
        formReportThreeData.lotNo =
          form.lotNo && form.lotNo.lot_no === 'All' ? [] : [form.lotNo.lot_no];
        formReportThreeData.dispatchTo =
          form.dispatchTo.node_name === 'All'
            ? []
            : [form.dispatchTo.node_name];
        const startDispatchDate = form.startDispatchDate?.toLowerCase();
        const endDispatchDate = form.endDispatchDate?.toLowerCase();
        const startReceiptDate = form.startReceiptDate?.toLowerCase();
        const endReceiptDate = form.endReceiptDate?.toLowerCase();

        const isDispatchDateValid = downloadValidation(
          startDispatchDate,
          endDispatchDate
        );
        const isRcptDateValid = downloadValidation(
          startReceiptDate,
          endReceiptDate
        );
        if (
          (startDispatchDate === 'all' || endDispatchDate === 'all') &&
          (startReceiptDate !== 'all' || endReceiptDate !== 'all')
        ) {
          isBlockDownload = isRcptDateValid;
        } else if (
          (startDispatchDate !== 'all' || endDispatchDate !== 'all') &&
          (startReceiptDate === 'all' || endReceiptDate === 'all')
        ) {
          isBlockDownload = isDispatchDateValid;
        } else if (
          (startDispatchDate !== 'all' && endDispatchDate !== 'all') ||
          (startReceiptDate !== 'all' && endReceiptDate !== 'all')
        ) {
          isBlockDownload = isDispatchDateValid && isRcptDateValid;
        } else if (
          startDispatchDate === 'all' &&
          endDispatchDate === 'all' &&
          startReceiptDate === 'all' &&
          endReceiptDate === 'all'
        ) {
          isBlockDownload = true;
        }
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.report_threeDownloadURL,
          formReportThreeData,
          'DC Receipt vs Supplier Dispatch'
        );
      }
    } else if (dataFromChild.id === 'cte3_vs_cte4') {
      const report_fourData: any = {};
      if (dataFromTiles.id) {
        report_fourData.discrepancy = dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        report_fourData.distributionCentre =
          form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
        report_fourData.startReceiptDate = form.startReceiptDate;
        report_fourData.endReceiptDate = form.endReceiptDate;
        report_fourData.startDispatchDate = form.startDispatchDate;
        report_fourData.endDispatchDate = form.endDispatchDate;
        report_fourData.wrinNumber = form.wrin && form.wrin.param;
        const startDispatchDate = form.startDispatchDate?.toLowerCase();
        const endDispatchDate = form.endDispatchDate?.toLowerCase();
        const startReceiptDate = form.startReceiptDate?.toLowerCase();
        const endReceiptDate = form.endReceiptDate?.toLowerCase();

        const isDispatchDateValid = downloadValidation(
          startDispatchDate,
          endDispatchDate
        );
        const isRcptDateValid = downloadValidation(
          startReceiptDate,
          endReceiptDate
        );
        if (
          (startDispatchDate === 'all' || endDispatchDate === 'all') &&
          (startReceiptDate !== 'all' || endReceiptDate !== 'all')
        ) {
          isBlockDownload = isRcptDateValid;
        } else if (
          (startDispatchDate !== 'all' || endDispatchDate !== 'all') &&
          (startReceiptDate === 'all' || endReceiptDate === 'all')
        ) {
          isBlockDownload = isDispatchDateValid;
        } else if (
          (startDispatchDate !== 'all' && endDispatchDate !== 'all') ||
          (startReceiptDate !== 'all' && endReceiptDate !== 'all')
        ) {
          isBlockDownload = isDispatchDateValid && isRcptDateValid;
        } else if (
          startDispatchDate === 'all' &&
          endDispatchDate === 'all' &&
          startReceiptDate === 'all' &&
          endReceiptDate === 'all'
        ) {
          isBlockDownload = true;
        }
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportFourDownloadURL,
          report_fourData,
          'DC Inventory Storage and Ageing Report'
        );
      }
    } else if (dataFromChild.id === 'cte4_dc_shelf_life') {
      const report_fiveData: any = {};
      if (dataFromTiles.id) {
        report_fiveData.discrepancy =
          dataFromTiles.id === 'all' ? [] : dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;

        report_fiveData.startExpirationDate =
          form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
        report_fiveData.endExpirationDate =
          form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
        report_fiveData.category =
          form.category && form.category.category_name
            ? form.category.param
            : report_fiveData.category;
        report_fiveData.wrin =
          form.item && form.item.wrin_description
            ? form.item.param
            : report_fiveData.wrin;
        report_fiveData.dc =
          form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
        const startExpirationDate = report_fiveData.startExpirationDate;
        const endExpirationDate = report_fiveData.endExpirationDate;
        isBlockDownload = downloadValidation(
          startExpirationDate,
          endExpirationDate
        );
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.report_fiveDownloadURL,
          report_fiveData,
          'DC Cases with Shelf Life Details'
        );
      }
    } else if (dataFromChild.id === 'cte4_vs_cte5') {
      const report_sixData: any = {};
      if (dataFromTiles.id) {
        report_sixData.discrepancy = dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        report_sixData.dc =
          form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
        const user = sessionStorage.getItem('user');
        const userRole = user && JSON.parse(user).role;
        report_sixData.startDispatchDate =
          userRole === 'Restaurant' ? 'All' : form.startDispatchDate;
        report_sixData.endDispatchDate =
          userRole === 'Restaurant' ? 'All' : form.endDispatchDate;
        report_sixData.startRestPutAwayDate = form.startPutAwayDate;
        report_sixData.endRestPutAwayDate = form.endPutAwayDate;
        report_sixData.wrin = form.wrin && form.wrin.param;
        report_sixData.lotNo =
          form.lotNo && form.lotNo.lot_no === 'All' ? [] : [form.lotNo.lot_no];
        report_sixData.dispatchTo =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);
        report_sixData.orderNo =
          form.orderNo && form.orderNo.order_no === 'All'
            ? []
            : [form.orderNo.order_no];

        const startDispatchDate =
          report_sixData.startDispatchDate?.toLowerCase();
        const endDispatchDate = report_sixData.endDispatchDate?.toLowerCase();
        const startPutAwayDate = form.startPutAwayDate?.toLowerCase();
        const endPutAwayDate = form.endPutAwayDate?.toLowerCase();

        const isDispatchDateValid = downloadValidation(
          startDispatchDate,
          endDispatchDate
        );
        const isPutAwayDateValid = downloadValidation(
          startPutAwayDate,
          endPutAwayDate
        );
        if (
          (startDispatchDate === 'all' || endDispatchDate === 'all') &&
          (startPutAwayDate !== 'all' || endPutAwayDate !== 'all')
        ) {
          isBlockDownload = isPutAwayDateValid;
        } else if (
          (startDispatchDate !== 'all' || endDispatchDate !== 'all') &&
          (startPutAwayDate === 'all' || endPutAwayDate === 'all')
        ) {
          isBlockDownload = isDispatchDateValid;
        } else if (
          (startDispatchDate !== 'all' && endDispatchDate !== 'all') ||
          (startPutAwayDate !== 'all' && endPutAwayDate !== 'all')
        ) {
          isBlockDownload = isDispatchDateValid && isPutAwayDateValid;
        } else if (
          startDispatchDate === 'all' &&
          endDispatchDate === 'all' &&
          startPutAwayDate === 'all' &&
          endPutAwayDate === 'all'
        ) {
          isBlockDownload = true;
        }
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportSixDownloadURL,
          report_sixData,
          'Restaurant Receipt vs DC Dispatch'
        );
      }
    } else if (dataFromChild.id === 'cte5_shelf_life') {
      const report_eightData: any = {};
      if (dataFromTiles.id) {
        report_eightData.discrepancy =
          dataFromTiles.id === 'all' ? [] : dataFromTiles.id;
      }
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        report_eightData.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);

        report_eightData.startExpirationDate =
          form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
        report_eightData.endExpirationDate =
          form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
        report_eightData.category =
          form.category && form.category.category_name
            ? form.category.param
            : report_eightData.category;
        report_eightData.wrinDescription =
          form.item && form.item.wrin_description
            ? form.item.param
            : report_eightData.wrin;

        const startExpirationDate = report_eightData.startExpirationDate;
        const endExpirationDate = report_eightData.endExpirationDate;
        isBlockDownload = downloadValidation(
          startExpirationDate,
          endExpirationDate
        );
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportEightDownloadURL,
          report_eightData,
          'Restaurant Cases with Shelf Life Details'
        );
      }
    } else if (dataFromChild.id === 'cte5_vs_cte6_compare_daily_inv') {
      const report_nineData: any = {};
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        report_nineData.date = form.startReceiptDate || 'all';
        report_nineData.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);

        report_nineData.wrin = form.item && form.item.param;
        report_nineData.category =
          form.category && form.category.category_name
            ? form.category.param
            : report_nineData.category;
      }
      download(
        environment.reportNineDownloadURL,
        report_nineData,
        'Comparison of Daily Inventory'
      );
    } else if (dataFromChild.id === 'cte5_vs_cte6_consumed_inv') {
      const data: any = {};
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        data.date = form.startReceiptDate || 'all';
        data.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);
        data.wrin = form.item && form.item.wrin_description && form.item.param;
        data.category =
          form.category && form.category.category_name
            ? form.category.param
            : data.category;
      }
      download(
        environment.CTE5_CTE6_CONSUMED_INV_DOWNLOAD_URL,
        data,
        'Consumed Inventory'
      );
    } else if (
      dataFromChild.id === 'restaurant_case_transfer' &&
      viewTransferType === 'Transfer_Out'
    ) {
      const report_tenData: any = {};
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        report_tenData.wrin = form.wrin && form.wrin.param;
        report_tenData.origin =
          form.origin && form.origin.node_name === 'All'
            ? []
            : [form.origin.node_name];
        report_tenData.startDispatchDate = form.startDispatchDate;
        report_tenData.endDispatchDate = form.endDispatchDate;
        const startDispatchDate = form.startDispatchDate?.toLowerCase();
        const endDispatchDate = form.endDispatchDate?.toLowerCase();
        isBlockDownload = downloadValidation(
          startDispatchDate,
          endDispatchDate
        );
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportTenTransferOutDownloadURL,
          report_tenData,
          'Restaurant Case Transfer-Out'
        );
      }
    } else if (
      dataFromChild.id === 'restaurant_case_transfer' &&
      viewTransferType === 'Transfer_In'
    ) {
      const report_tenData: any = {};
      if (dataFromChild.form) {
        const { form } = dataFromChild;
        report_tenData.wrin = form.wrin && form.wrin.param;
        report_tenData.origin =
          form.origin && form.origin.node_name === 'All'
            ? []
            : [form.origin.node_name];
        report_tenData.startReceiptDate = form.startDispatchDate;
        report_tenData.endReceiptDate = form.endDispatchDate;
        const startReceiptDate = form.startDispatchDate?.toLowerCase();
        const endReceiptDate = form.endDispatchDate?.toLowerCase();
        isBlockDownload = downloadValidation(startReceiptDate, endReceiptDate);
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportTenTransferInDownloadURL,
          report_tenData,
          'Restaurant Case Transfer-In'
        );
      }
    } else if (dataFromChild.id === 'cte4_vs_cte5_order_accuracy') {
      const { form } = dataFromChild;
      const formData: any = {};
      if (form) {
        formData.orderNo =
          form.orderNo && form.orderNo.order_no === 'All'
            ? []
            : [form.orderNo.order_no];
        formData.wrin = form.wrin && form.wrin.param;
        formData.startOrderDate = form.startReceiptDate;
        formData.endOrderDate = form.endReceiptDate;
        formData.discrepancy = 'all';
        formData.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);

        const startOrderDate = formData.startOrderDate?.toLowerCase();
        const endOrderDate = formData.endOrderDate?.toLowerCase();
        isBlockDownload = downloadValidation(startOrderDate, endOrderDate);
      }
      if (isBlockDownload) {
        setDownloadAlert(isBlockDownload); // Show alert and block download
      } else {
        download(
          environment.reportThirteenDownloadURL,
          formData,
          'Report Order Accuracy'
        );
      }
    } else if (dataFromChild.id === 'daily_summary_inventory') {
      const { form } = dataFromChild;
      const formData: any = {};
      if (form) {
        formData.date = form.reportFourReceiptDtRange;
        formData.summary_restaurant = form.summary_restaurant.node_name;
      }
      download(
        environment.reportFourteenDownloadURL,
        formData,
        'Inventory Daily Summary'
      );
    } else {
      console.log(dataFromChild.id, viewType);
      console.log('no report');
    }
  };

  const download = (url: any, payLoad: any, filename: string) => {
    const downloadData = async () => {
      setIsLoader(true);
      apiPostDownload(url, payLoad)
        .then((data: any) => {
          // Create a blob from the response data
          const blob = new Blob([data.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          // Create a URL for the blob
          const downloadUrl = window.URL.createObjectURL(blob);

          // Create a link element and click it to trigger the download
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = filename; // Provide the desired filename with .xlsx extension
          document.body.appendChild(link);
          link.click();

          // Clean up
          link.remove();
          window.URL.revokeObjectURL(downloadUrl);
          setIsLoader(false);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
          setIsLoader(false);
        });
    };
    downloadData();
  };

  const handleData = (data: any) => {
    // Handle the data received from the child component
    if (data) {
      setCaseIDSearch(data.case_serial_number);
      setShowDigitalJourney(true);
    } else {
      setCaseIDSearch('');
    }
  };

  const setRowsforTiles = (data: any) => {
    if (data) {
      setReportsData(data);
    }
  };

  const saveForm = (formData: Data) => {
    setFormData(formData);
  };

  const handleBackButtonClick = () => {
    if (toggleStateRef.current) {
      setTransferViewType(toggleStateRef.current);
    }
    setShowDigitalJourney(false);
    if (dataFromChild) {
      saveForm(dataFromChild);
    }
    if (dataFromTiles && dataFromChild.id && Tiles_Data[dataFromChild.id]) {
      setTileIndex(dataFromTiles);
      const lastIndex = Tiles_Data[dataFromChild.id].length - 1;
      if (dataFromTiles.label === 'Compliant') {
        setCheckBox(true);
        setDataFromTiles({
          label: 'Compliant',
          count: 0,
          id: ['', 'Nearing Expiry'],
          pass_value: 'complaint_count',
        });
        setTileIndex({
          ...Tiles_Data[dataFromChild.id][lastIndex],
          index: lastIndex,
          isComplaint: true,
          isOnTime: false,
        });
      } else if (dataFromTiles.label === 'On-Time') {
        setCheckBox(true);
        setDataFromTiles({
          label: 'On-Time',
          count: 0,
          id: 'On-Time',
          pass_value: 'ontimecount',
        });
        setTileIndex({
          ...Tiles_Data[dataFromChild.id][lastIndex],
          index: lastIndex,
          isComplaint: false,
          isOnTime: true,
        });
      } else if (dataFromTiles.label === 'All') {
        setTileIndex({
          ...Tiles_Data[dataFromChild.id][lastIndex],
          index: lastIndex,
        });
        setDataFromTiles({
          label: 'All',
          count: 0,
          id: 'all',
          pass_value: 'totalCount',
          isComplaint: false,
          isOnTime: false,
        });
      }
    }
  };

  return (
    <div className="report-container">
      {isLoader && <Loading />}
      {!showDigitalJourney ? (
        <div className="reports">
          <section className="dropdown-section">
            <Filters
              sendDataToParent={handleDataFromChild}
              selectedReport={onChangeReport}
              backButtonClicked={caseIDSearch}
              savedFormData={formSavedData}
              restTransViewType={viewTransferType}
            ></Filters>
          </section>
          <section className="table-section">
            {dataFromChild.id === 'cte2_vs_cte3' && (
              <div className="toggle-section">
                <span className="toggle-text">Case View</span>
                <Toggle
                  id="toggle-component"
                  hideLabel
                  size="sm"
                  onToggle={(e: boolean) => {
                    handleToggleChange(e);
                  }}
                />
                <span className="toggle-text">Pallet View</span>
              </div>
            )}
            {false && dataFromChild.id === 'cte5_shelf_life' && (
              <div className="toggle-section">
                <span className="toggle-text">Case View</span>
                <Toggle
                  id="toggle-component"
                  hideLabel
                  size="sm"
                  onToggle={(e: boolean) => {
                    handleToggleChangeWRIN(e);
                  }}
                />
                <span className="toggle-text">WRIN View</span>
              </div>
            )}
            {dataFromChild.id === 'restaurant_case_transfer' && (
              <div className="toggle-section">
                <span className="toggle-text">Transfer-In</span>
                <Toggle
                  id="toggle-component"
                  hideLabel
                  size="sm"
                  toggled={active}
                  onToggle={(e: boolean) => {
                    handleRestTransferToggleChange(e);
                  }}
                />
                <span className="toggle-text">Transfer-Out</span>
              </div>
            )}
            {(dataFromChild.id == 'cte1_vs_cte2' ||
              dataFromChild.id === 'cte2_shelf_life' ||
              dataFromChild.id === 'cte2_vs_cte3' ||
              dataFromChild.id === 'cte3_vs_cte4' ||
              dataFromChild.id === 'cte4_dc_shelf_life' ||
              dataFromChild.id === 'cte4_vs_cte5' ||
              dataFromChild.id === 'cte5_shelf_life' ||
              dataFromChild.id === 'cte4_vs_cte5_order_accuracy') && (
              <Tiles
                type={dataFromChild.id}
                sendDataToReport={handleTilesData}
                form={dataFromChild.form}
                viewType={viewType}
                backButtonClicked={caseIDSearch}
                savedTileIndex={selectedTileIndex}
                savedReportsData={sendReportsData}
              ></Tiles>
            )}
            <div className="legend-section">
              <div className="table-name">
                {dataFromChild.id !== 'restaurant_case_transfer' &&
                  dataFromChild.id !== 'daily_summary_inventory' &&
                  dataFromChild.tabletext}
                {dataFromChild.id === 'restaurant_case_transfer' &&
                  (viewTransferType === 'Transfer_In'
                    ? 'Restaurant Case Transfer-In'
                    : 'Restaurant Case Transfer-Out')}
                {dataFromChild.id === 'daily_summary_inventory' &&
                  dataFromChild.tabletext +
                    '  ' +
                    '(' +
                    dataFromChild.form.reportFourReceiptDtRange +
                    ')'}
              </div>
              <div className="float-right">
                {(dataFromChild.id === 'cte2_vs_cte3' ||
                  dataFromChild.id === 'cte4_vs_cte5') &&
                  selectedTileLabel === 'All' && (
                    <Checkbox
                      id="checkbox-ontime"
                      checked={checkBoxChecked}
                      onChange={(event) => handleClick(event)}
                      labelText={'On-Time'}
                      className={`cds--checkbox-wrapper ${dataFromChild.id}-${isAdmin() ? 'top-add' : ''}`}
                    />
                  )}
                {dataFromChild.id === 'cte5_shelf_life' && isAdmin() && (
                  <div className="blue-tag">
                    <BlueTag className="blue-tag-svg" />
                    Blue Tag
                  </div>
                )}
                {(dataFromChild.id === 'cte2_shelf_life' ||
                  dataFromChild.id === 'cte4_dc_shelf_life' ||
                  dataFromChild.id === 'cte5_shelf_life') &&
                  selectedTileLabel === 'All' && (
                    <Checkbox
                      id="checkbox-compaint"
                      checked={checkBoxChecked}
                      onChange={(event) => handleClickComplaint(event)}
                      labelText={'Compliant'}
                    />
                  )}
                {dataFromChild.id === 'cte4_vs_cte5_order_accuracy' &&
                  selectedTileLabel === 'All' && (
                    <Checkbox
                      id="checkbox-exact"
                      checked={checkBoxChecked}
                      onChange={(event) => handleClickExact(event)}
                      labelText={'Exact'}
                    />
                  )}
                {dataFromChild.id === 'cte4_vs_cte5' && isAdmin() && (
                  <>
                    <div className="under-review">
                      <BlueTag className="blue-tag-svg" />
                      Blue Tag
                    </div>
                    <div className="under-review">
                      <UnderReview className="under-review-svg" />
                      Under Review
                    </div>
                  </>
                )}
                {dataFromChild.id !== 'digital_journey' && (
                  <div className={`download-section ${selectedTileLabel}`}>
                    <div className="icon-text" onClick={onDownload}>
                      Download
                    </div>
                    <Download size={18} onClick={onDownload} />
                  </div>
                )}
              </div>
            </div>
            {(dataFromChild.id === 'cte2_vs_cte3' ||
              dataFromChild.id === 'cte4_vs_cte5' ||
              dataFromChild.id === 'restaurant_case_transfer') && (
              <ReportReceipt
                key={dataFromChild.id}
                viewType={
                  dataFromChild.id === 'restaurant_case_transfer'
                    ? viewTransferType
                    : viewType
                }
                reportType={dataFromChild.id}
                form={dataFromChild.form}
                selectedTiles={dataFromTiles}
                sendData={handleData}
                setReportsData={setRowsforTiles}
                backButtonClicked={caseIDSearch}
              ></ReportReceipt>
            )}
            {(dataFromChild.id === 'cte5_vs_cte6_compare_daily_inv' ||
              dataFromChild.id === 'cte5_vs_cte6_consumed_inv') && (
              <ReceiptComparison
                key={dataFromChild.id}
                type={dataFromChild.id}
                form={dataFromChild.form}
              ></ReceiptComparison>
            )}
            {(dataFromChild.id === 'cte5_shelf_life' ||
              dataFromChild.id === 'cte2_shelf_life' ||
              dataFromChild.id === 'cte4_dc_shelf_life') && (
              <ReportCases
                key={dataFromChild.id}
                type={dataFromChild.id}
                selectedTiles={dataFromTiles}
                form={dataFromChild.form}
                viewType={viewType}
                sendData={handleData}
                backButtonClicked={caseIDSearch}
                setReportsData={setRowsforTiles}
              ></ReportCases>
            )}
            {(dataFromChild.id == 'cte1_vs_cte2' ||
              dataFromChild.id == 'cte3_vs_cte4') && (
              <InventoryStorage
                key={dataFromChild.id}
                type={dataFromChild.id}
                selectedTiles={dataFromTiles}
                form={dataFromChild.form}
                sendData={handleData}
                backButtonClicked={caseIDSearch}
                setReportsData={setRowsforTiles}
              ></InventoryStorage>
            )}
            {dataFromChild.id === 'digital_journey' && (
              <CaseDigitalJourney
                searchValue={
                  dataFromChild.form && dataFromChild.form.caseIDSearch
                }
              ></CaseDigitalJourney>
            )}
            {dataFromChild.id === 'cte4_vs_cte5_order_accuracy' && (
              <RestaurantOrderAccuracy
                form={dataFromChild.form}
                selectedTiles={dataFromTiles}
                setReportsData={setRowsforTiles}
              ></RestaurantOrderAccuracy>
            )}
            {dataFromChild.id === 'daily_summary_inventory' && (
              <InventoryDailySummary form={dataFromChild.form} />
            )}
          </section>
        </div>
      ) : (
        <div className="reports view-page">
          <div className="table-section">
            <div className="legend-section">
              <p className="back-btn" onClick={handleBackButtonClick}>
                <ArrowLeft size={18} />
                <span>Back</span>
              </p>
              <div className="table-name">{'Digital Journey of the Case'}</div>
            </div>

            <div className="case-journey">
              <CaseDigitalJourney searchValue={caseIDSearch} />
            </div>
          </div>
        </div>
      )}
      {downloadAlert && (
        <Modal
          className="download-alert"
          open={downloadAlert}
          onRequestClose={() => setDownloadAlert(false)}
          modalHeading={<>Download Disabled</>}
        >
          <div className="modal-content">
            <Alert className="alert-svg" />
            <p className="alert-text">
              Please ensure that the selected date filter(s) data range does not
              exceed 31 days.
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Reports;
