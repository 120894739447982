import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  subDays,
  differenceInDays,
} from 'date-fns';

export const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: subDays(new Date(), 7),
  endOfLastWeek: subDays(new Date(), 1),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  endOfLast30Days: subDays(new Date(), 29),
  tomorrow: addDays(new Date(), 1),
  next5Days: addDays(new Date(), 5),
  next10Days: addDays(new Date(), 10),
};

export const TYPE_1 = [
  {
    label: 'All',
    range: () => ({
      startDate: null,
      endDate: new Date(''),
      label: 'defined',
      isAll: true,
    }),
  },
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: defineds.endOfLast30Days,
      endDate: defineds.startOfToday,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Custom Date Range',
    range: () => ({
      startDate: null,
      endDate: null,
      key: 'selection',
      label: 'custom',
      isAll: false,
    }),
  },
];

export const TYPE_2 = [
  {
    label: 'All',
    range: () => ({
      startDate: null,
      endDate: new Date(''),
      label: 'defined',
      isAll: true,
    }),
  },
  {
    label: 'Tommorow',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.tomorrow,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Next 5 Days',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.next5Days,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Next 10 Days',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.next10Days,
      label: 'defined',
      isAll: false,
    }),
  },
  {
    label: 'Custom Date Range',
    range: () => ({
      startDate: null,
      endDate: null,
      key: 'selection',
      label: 'custom',
      isAll: false,
    }),
  },
];

export const TYPE_3 = [
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
      label: 'defined',
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
      label: 'defined',
    }),
  },
  {
    label: 'Custom Date Range',
    range: () => ({
      startDate: null,
      endDate: null,
      key: 'selection',
      label: 'custom',
    }),
  },
];

export function daysDifference(startDate: string, endDate: string) {
  return differenceInDays(new Date(endDate), new Date(startDate));
}
