import './inventory-daily-summary.scss';
import {
  columns as Fields,
  Columns,
  Data,
} from '../../../interfaces/inventory-daily-summary';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  tablePaginationClasses,
} from '@mui/material';
import React, { useEffect } from 'react';
import { apiPost } from '../../../services/apiService';
import eventBus from '../../../utils/EventBus';
import { debounce } from 'lodash';
import { environment } from '../../../environment';
import { Loading } from '@carbon/react';

export const InventoryDailySummary = ({ form }: { form: any }) => {
  const [columns] = React.useState<Columns[]>(Fields);
  const [rowsCount, setRowsCount] = React.useState(0);
  const [rows, setRows] = React.useState<any>([]);
  const [loading, isLoading] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [sortBy, setSortBy] = React.useState<{
    field: keyof Data | string | undefined;
    order: 'asc' | 'desc' | undefined;
  }>({
    field: undefined,
    order: undefined,
  });
  const handleSort = (columnId: string) => {
    // Determine the sorting order for the clicked column
    setSortBy({
      field: columnId,
      order:
        sortBy.field === columnId && sortBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    const defaultController = {
      page: 0,
      rowsPerPage: 10,
    };
    const defaultSort = {
      field: undefined,
      order: undefined,
    };
    if (form) {
      const savedController: any = sessionStorage.getItem('controller');
      setController(
        savedController ? JSON.parse(savedController) : defaultController
      );
      const savedSort: any = sessionStorage.getItem('sortBy');
      setSortBy(savedSort ? JSON.parse(savedSort) : defaultSort);
    }
  }, [form]);

  useEffect(() => {
    const formData: any = {};
    const url = environment.reportFourteenURL;
    if (form) {
      formData.date = form.reportFourReceiptDtRange;
      formData.summary_restaurant = form.summary_restaurant.node_name;
    }
    setRows([]);
    isLoading(false);
    setIsLoader(true);
    formData.sorting_for = sortBy ? sortBy.field : '';
    formData.sorting_type = sortBy ? sortBy.order : '';
    const fetchData = async () => {
      const offset = controller.page * controller.rowsPerPage;
      apiPost(
        `${url}?offset=${offset}&limit=${controller.rowsPerPage}`,
        formData
      )
        .then((data: any) => {
          isLoading(true);
          setRowsCount(data.totalListCount);
          data.list.map((item: any, index: number) => {
            item.sNo = index;
            item.trash = item.trash === '' ? 0 : Number(item.trash);
            item.consumedInventory =
              item.consumedInventory === '' ||
              item.consumedInventory === '0.000'
                ? 0
                : item.consumedInventory;
          });
          eventBus.emit('dataEvent', data.lastUpdatedTime);
          setRows([...data.list]);
          setIsLoader(false);
        })
        .catch((error: { message: string }) => {
          setIsLoader(false);
          setRowsCount(0);
          console.log('Error fetching data: ' + error.message);
        });
    };
    const debouncedFetchData = debounce(fetchData, 400); // Debounce for 400 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [form, sortBy, controller]);

  const handleChangePage = (event: unknown, newPage: number) => {
    const _controller = {
      ...controller,
      page: newPage,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _controller = {
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  return (
    <>
      <div className="custom-table summary-table">
        {isLoader && <Loading />}
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            key="inventory_table"
            size="small"
          >
            <TableHead>
              <TableRow className="header">
                {columns.map((column) =>
                  column.id !== 'gap' ? (
                    <TableCell key={column.id}>
                      <TableSortLabel
                        active={
                          (sortBy && sortBy.field === column.id) || undefined
                        } // Check if sortBy.field matches the current column id
                        direction={
                          sortBy && sortBy.field === column.id
                            ? sortBy.order
                            : undefined // default direction when the column is not active
                        }
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      style={{
                        width: '1%',
                        backgroundColor: '#F4F4F4',
                        borderBottom: 'none',
                      }}
                    ></TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && rows.length === 0 ? ( // Check if there are no records
                <TableRow>
                  <TableCell colSpan={11}>
                    {' '}
                    <Typography variant="body1" align="center">
                      No records found.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row: any) => {
                  return (
                    <React.Fragment key={row.wrin}>
                      <TableRow hover tabIndex={-1} key={row.sNo}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id !== 'gap' ? (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ minWidth: `${column.minWidth}vw` }}
                            >
                              {value}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.id}
                              style={{
                                width: '1%',
                                backgroundColor: '#F4F4F4',
                                borderBottom: 'none',
                              }}
                            ></TableCell>
                          );
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rowsCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="custom-pagination"
          sx={{
            [`& .${tablePaginationClasses.spacer}`]: {
              display: 'none',
            },
            [`& .${tablePaginationClasses.toolbar}`]: {
              justifyContent: 'center',
            },
          }}
        />
      </div>
    </>
  );
};
