export const DigitalJourneySteps = [
  {
    heading: 'Palletizing & Wrapping',
    label: 'CTE: 1',
    type: 'cte1',
    field: 'supplier',
    param: 'CTE1',
    timeStamp: '',
  },
  {
    heading: 'Shipping from Supplier to DC',
    label: 'CTE: 2',
    type: 'cte2',
    field: 'supplier',
    param: 'CTE2',
    timeStamp: '',
  },
  {
    heading: 'Receipt at DC',
    label: 'CTE: 3',
    type: 'cte3',
    field: 'dc',
    param: 'CTE3',
    timeStamp: '',
  },
  {
    heading: 'Shipping from DC to Restaurant',
    label: 'CTE: 4',
    type: 'cte4',
    field: 'supplier',
    param: 'CTE4',
    timeStamp: '',
  },
  {
    heading: 'Wrapped in Freezer Zone',
    label: 'CTE: 4a',
    type: 'cte4a',
    field: 'supplier',
    param: 'CTE4a',
    timeStamp: '',
  },
  {
    heading: 'Wrapped in Cooler Zone',
    label: 'CTE: 4b',
    type: 'cte4b',
    field: 'supplier',
    param: 'CTE4b',
    timeStamp: '',
  },
  {
    heading: 'Wrapped in Dry Zone',
    label: 'CTE: 4c',
    type: 'cte4c',
    field: 'supplier',
    param: 'CTE4c',
    timeStamp: '',
  },
  {
    heading: 'Put Away at Restaurant',
    label: 'CTE: 5',
    type: 'cte5',
    field: 'receive',
    param: 'CTE5',
    timeStamp: '',
  },
  {
    heading: 'Put Away at Dry Zone',
    label: 'CTE: 5a',
    type: 'cte5a',
    field: 'receive',
    param: 'CTE5a',
    timeStamp: '',
  },
  {
    heading: 'Put Away at Cooler Zone',
    label: 'CTE: 5b',
    type: 'cte5b',
    field: 'receive',
    param: 'CTE5b',
    timeStamp: '',
  },
  {
    heading: 'Put Away at Freezer Zone',
    label: 'CTE: 5c',
    type: 'cte5c',
    field: 'receive',
    param: 'CTE5c',
    timeStamp: '',
  },
  {
    heading: 'Prep Area',
    label: 'CTE: 6a',
    type: 'cte6a',
    field: 'destination',
    param: 'CTE6a',
    timeStamp: '',
  },
  {
    heading: 'Consumption',
    label: 'CTE: 6b',
    type: 'cte6b',
    field: 'destination',
    param: 'CTE6b',
    timeStamp: '',
  },
  {
    heading: 'Trash',
    label: 'CTE: 6c',
    type: 'cte6c',
    field: 'destination',
    param: 'CTE6c',
    timeStamp: '',
  },
  {
    heading: 'Trash Door',
    label: 'CTE: 6d',
    type: 'cte6d',
    field: 'destination',
    param: 'CTE6d',
    timeStamp: '',
  },
  {
    heading: 'Transfer',
    label: 'TE Front',
    type: 'cte7',
    field: 'destination',
    param: 'CTE7',
    timeStamp: '',
  },
];

export const DigitalJourneyRightPanel = {
  section_1: [
    {
      field: 'node_name',
      label: 'Node',
    },
    {
      field: 'process',
      label: 'Process',
    },
    {
      field: 'pallet_serial_number',
      label: 'Pallet ID',
    },
  ],
  section_2: [
    {
      field: 'device_type_name',
      label: 'Reader Type',
    },
    {
      field: 'tag_type',
      label: 'Tag Type',
    },
  ],
  section_3: [
    {
      field: 'discrepancy',
      label: 'Discrepancy',
    },
  ],
};
