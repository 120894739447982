import React, { useEffect } from 'react';
import { WarningFilled } from '@carbon/icons-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tablePaginationClasses,
} from '../../../utils/MuiComponents';
import './inventory-storage.scss';
import {
  columns as Fields,
  Column,
} from '../../../interfaces/inventory-storage';
import { environment } from '../../../environment';
import { TableSortLabel, Typography } from '@mui/material';
import { Data, initialRowData } from '../../../interfaces/inventory-storage';
import { DefinitionTooltip, Tooltip } from '@carbon/react';
import formatDate, {
  dateFormat,
  expiryDateFormat,
  dateFormatHover,
} from '../../../utils/date';
import eventBus from '../../../utils/EventBus';
import { apiPost } from '../../../services/apiService';
import { debounce } from 'lodash';
import CaseModal from '../case-modal-popup/case-modal';
import Loading from '@carbon/react/lib/components/Loading/Loading';
const tooltip_data = require('../../../assets/data/tooltipdesc.json');

export const InventoryStorage = ({
  type,
  selectedTiles,
  form,
  sendData,
  backButtonClicked,
  setReportsData,
}: {
  type: string;
  selectedTiles: any;
  form: any;
  sendData: any;
  backButtonClicked: string;
  setReportsData: any;
}) => {
  const [rowsCount, setRowsCount] = React.useState(0);
  const [rows, setRows] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<Column[]>([]);
  const [loading, isLoading] = React.useState(false);
  const [isOpenModal, setOpenModal] = React.useState(false);
  const [rowData, setRowData] = React.useState<Data>(initialRowData);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [isLoader, setIsLoader] = React.useState(false);
  const [backButtnClicked, setBackButtonClicked] = React.useState(false);

  const [sortBy, setSortBy] = React.useState<{
    field: keyof Data | string | undefined;
    order: 'asc' | 'desc' | undefined;
  }>({
    field: undefined,
    order: undefined,
  });
  const handleSort = (columnId: string) => {
    // Determine the sorting order for the clicked column
    setSortBy({
      field: columnId,
      order:
        sortBy.field === columnId && sortBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    setBackButtonClicked(backButtonClicked ? true : false);
  }, [backButtonClicked]);

  useEffect(() => {
    const defaultController = {
      page: 0,
      rowsPerPage: 10,
    };
    const defaultSort = {
      field: undefined,
      order: undefined,
    };
    if (form && selectedTiles) {
      const savedController: any = sessionStorage.getItem('controller');
      const controller = savedController
        ? JSON.parse(savedController)
        : defaultController;
      setController({
        page: backButtnClicked ? controller.page : 0,
        rowsPerPage: controller.rowsPerPage,
      });
      const savedSort: any = sessionStorage.getItem('sortBy');
      setSortBy(savedSort ? JSON.parse(savedSort) : defaultSort);
      if (backButtnClicked) {
        setBackButtonClicked(false);
      }
    }
  }, [selectedTiles, form]);

  useEffect(() => {
    const formData: any = {};
    let url: any;
    if (type === 'cte1_vs_cte2') {
      url = environment.inventoryURL;
      setColumns(
        Fields.filter((item) => item.id !== 'dc' && item.id !== 'receipt_date')
      );
      if (selectedTiles && selectedTiles.label) {
        formData.discrepancy = selectedTiles.id;
      }
      if (form) {
        formData.supplier =
          form.supplier && form.supplier.node_name
            ? form.supplier.node_name === 'All'
              ? []
              : [form.supplier.node_name]
            : formData.supplier;

        formData.startDispatchDate = form.startDispatchDate;
        formData.endDispatchDate = form.endDispatchDate;
        formData.startPutAwayDate = form.startPutAwayDate;
        formData.endPutAwayDate = form.endPutAwayDate;
      }
    }
    if (type === 'cte3_vs_cte4') {
      url = environment.reportFourURL;
      setColumns(Fields.filter((item) => item.id !== 'put_away_time'));
      if (selectedTiles && selectedTiles.label) {
        formData.discrepancy = selectedTiles.id;
      }

      if (form) {
        formData.distributionCentre =
          form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
        formData.startReceiptDate = form.startReceiptDate;
        formData.endReceiptDate = form.endReceiptDate;
        formData.startDispatchDate = form.startDispatchDate;
        formData.endDispatchDate = form.endDispatchDate;
        formData.wrinNumber = form.wrin && form.wrin.param;
      }
    }

    setRows([]);
    isLoading(false);
    setIsLoader(true);
    formData.sorting_for = sortBy ? sortBy.field : '';
    formData.sorting_type = sortBy ? sortBy.order : '';
    const fetchData = async () => {
      const offset = controller.page * controller.rowsPerPage;
      apiPost(
        `${url}?offset=${offset}&limit=${controller.rowsPerPage}`,
        formData
      )
        .then((data: any) => {
          isLoading(true);
          setRowsCount(data.totalListCount);
          data.list.map((item: any, index: number) => {
            item.sNo = index;
            const val = item['days_in_storage'];
            item['days_in_storage'] = val === '1 Days' ? '1 Day' : val;
          });
          eventBus.emit('dataEvent', data.lastUpdatedTime);
          setRows([...data.list]);
          if (formData.discrepancy === 'all') {
            setReportsData({ ...data });
          }
          setIsLoader(false);
        })
        .catch((error: { message: string }) => {
          setIsLoader(false);
          setRowsCount(0);
          console.log('Error fetching data: ' + error.message);
        });
    };
    const debouncedFetchData = debounce(fetchData, 400); // Debounce for 400 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [selectedTiles, form, sortBy, controller]); // This effect runs only once when the component mounts

  const handleChangePage = (event: unknown, newPage: number) => {
    const _controller = {
      ...controller,
      page: newPage,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _controller = {
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  const openModal = (row: Data) => {
    setOpenModal(!isOpenModal);
    setRowData(row);
    sendData(row);
    sessionStorage.setItem('controller', JSON.stringify(controller));
    sessionStorage.setItem('sortBy', JSON.stringify(sortBy));
  };

  return (
    <div className="custom-table receipt-table">
      {isLoader && <Loading />}
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          key="inventory_table"
          size="small"
        >
          <TableHead>
            <TableRow className="header">
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={(sortBy && sortBy.field === column.id) || undefined} // Check if sortBy.field matches the current column id
                    direction={
                      sortBy && sortBy.field === column.id
                        ? sortBy.order
                        : undefined // default direction when the column is not active
                    }
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && rows.length === 0 ? ( // Check if there are no records
              <TableRow>
                <TableCell colSpan={11}>
                  {' '}
                  <Typography variant="body1" align="center">
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row: any) => {
                return (
                  <React.Fragment key={row.case_serial_number}>
                    <TableRow hover tabIndex={-1} key={row.sNo}>
                      {columns.map((column) => {
                        const value = row[column.id] || '--';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ minWidth: `${column.minWidth}vw` }}
                          >
                            {column.id !== 'case_serial_number' &&
                              column.id !== 'discrepancy' &&
                              column.id !== 'exp_dt' &&
                              column.id !== 'put_away_time' &&
                              column.id !== 'dispatch_timestamp' &&
                              column.id !== 'receipt_date' &&
                              value}
                            {column.id === 'discrepancy' && value !== '--' && (
                              <span className="discrepancy-col">
                                {row.discrepancy !== 'On-Time' && (
                                  <Tooltip
                                    label={
                                      tooltip_data[type][row['supplier']][value]
                                    }
                                    align="bottom"
                                  >
                                    <WarningFilled size={18} />
                                  </Tooltip>
                                )}
                                {value}
                              </span>
                            )}
                            {column.id === 'discrepancy' && value === '--' && (
                              <span className="td-pl">{value}</span>
                            )}
                            {column.id === 'case_serial_number' && (
                              <span
                                className="caseId"
                                onClick={() => openModal(row)}
                              >
                                {value}
                              </span>
                            )}
                            {column.id === 'exp_dt' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={formatDate(value)}
                                >
                                  {expiryDateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'put_away_time' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'dispatch_timestamp' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'receipt_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rowsCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="custom-pagination"
        sx={{
          [`& .${tablePaginationClasses.spacer}`]: {
            display: 'none',
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: 'center',
          },
        }}
      />
      {isOpenModal && rowData && <CaseModal rowData={rowData} />}
    </div>
  );
};
