export interface Columns {
  id:
    | 'wrin'
    | 'wrin_description'
    | 'openingInventory'
    | 'closingInventory'
    | 'consumedInventory'
    | 'calculatedClosingInventory'
    | 'trash'
    | 'receipt'
    | 'gap';
  label?: string;
  minWidth?: number;
  align?: 'right';
}

export const columns: Columns[] = [
  {
    id: 'wrin',
    label: 'WRIN',
    minWidth: 9,
  },
  {
    id: 'wrin_description',
    label: 'WRIN Description',
    minWidth: 9,
  },
  {
    id: 'openingInventory',
    label: 'Opening Inventory (Cases)',
    minWidth: 9,
  },
  {
    id: 'closingInventory',
    label: 'Closing Inventory (Cases)',
    minWidth: 9,
  },
  {
    id: 'consumedInventory',
    label: 'Consumed Inventory (Cases) - HAVI',
  },
  {
    id: 'calculatedClosingInventory',
    label: 'Calculated Closing Inventory (Cases)',
  },
  {
    id: 'gap',
    label: '',
  },
  {
    id: 'trash',
    label: 'Trash (Cases)',
    minWidth: 9,
  },
  {
    id: 'receipt',
    label: 'Receipt (Cases)',
    minWidth: 9,
  },
];

export interface Data {
  wrin: string;
  wrin_description: string;
  openingInventory: string;
  closingInventory: string;
  consumedInventory: string;
  calculatedClosingInventory: string;
  gap: string;
  trash: string;
  receipt: string;
}
