export interface Column {
  id:
    | 'case_serial_number'
    | 'order_no'
    | 'pallet_no'
    | 'wrin'
    | 'total_cases'
    | 'wrin_description'
    | 'lot_no'
    | 'exp_date'
    | 'origin'
    | 'dc_dispatch_date'
    | 'rest_putaway_date'
    | 'first_silent_read'
    | 'last_silent_read'
    | 'dispatch_timestamp'
    | 'dispatch_date'
    | 'expected_delivery_date'
    | 'receipt_date'
    | 'destination'
    | 'discrepancy';
  label?: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
export const columns: Column[] = [
  {
    id: 'case_serial_number',
    label: 'Case ID',
  },
  {
    id: 'order_no',
    label: 'Order No.',
  },
  {
    id: 'pallet_no',
    label: 'Pallet ID',
  },
  {
    id: 'wrin',
    label: 'WRIN',
  },
  {
    id: 'total_cases',
    label: 'Total Cases',
  },
  {
    id: 'wrin_description',
    label: 'WRIN Description',
  },
  {
    id: 'lot_no',
    label: 'Lot No.',
  },
  { id: 'exp_date', label: 'Expiration Dt.' },
  {
    id: 'origin',
    label: 'Origin',
    minWidth: 6,
  },
  {
    id: 'dc_dispatch_date',
    label: 'DC Dispatch Dt.',
    minWidth: 8,
  },
  {
    id: 'dispatch_timestamp',
    label: 'Dispatch Dt.',
  },
  {
    id: 'dispatch_date',
    label: 'Dispatch Dt.',
  },
  {
    id: 'expected_delivery_date',
    label: 'Expected Delivery Dt.',
    minWidth: 8,
  },
  {
    id: 'rest_putaway_date',
    label: 'Rest. Put Away Dt.',
    minWidth: 8,
  },
  {
    id: 'first_silent_read',
    label: 'First Silent Read',
    minWidth: 8,
  },
  {
    id: 'last_silent_read',
    label: 'Last Silent Read',
    minWidth: 8,
  },
  {
    id: 'receipt_date',
    label: 'Receipt Dt.',
  },

  {
    id: 'destination',
    label: 'Destination',
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
  },
];

interface SubColumn {
  id: 'caseId' | 'wrinNo' | 'itemDesc' | 'status';
  label?: string;
  minWidth?: number;
  align?: 'right';
}

export const subColumns: SubColumn[] = [
  {
    id: 'caseId',
    label: 'Case ID',
  },
  {
    id: 'wrinNo',
    label: 'WRIN No.',
  },
  {
    id: 'itemDesc',
    label: 'Item Description',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

export interface Data {
  case_serial_number?: string;
  order_no?: string;
  pallet_no?: string;
  WRIN: string;
  total_cases?: string;
  wrin_description: string;
  lot_no: string;
  exp_date: string;
  rest_putaway_date: string;
  first_silent_read: string;
  last_silent_read: string;
  origin: string;
  dc_dispatch_date: string;
  dispatch_timestamp: string;
  dispatch_date: string;
  expected_delivery_date?: string;
  receipt_date: string;
  Destination: string;
  discrepancy: number;
  history: [];
  transition: string;
}

export const initialRowData: Data = {
  case_serial_number: '',
  order_no: '',
  pallet_no: '',
  WRIN: '',
  total_cases: '',
  wrin_description: '',
  lot_no: '',
  exp_date: '',
  origin: '',
  dc_dispatch_date: '',
  dispatch_timestamp: '',
  dispatch_date: '',
  expected_delivery_date: '',
  rest_putaway_date: '',
  first_silent_read: '',
  last_silent_read: '',
  receipt_date: '',
  Destination: '',
  discrepancy: 0,
  history: [],
  transition: '',
};
