import { Modal } from '@carbon/react';
import React, { useEffect } from 'react';
import './case-modal.scss';
import { WarningFilled } from '@carbon/icons-react';
import CaseLifeCycleModal from '../case-lifecycle/case-lifecycle';
import { apiGet } from '../../../services/apiService';
import { environment } from '../../../environment';
import { debounce } from 'lodash';
import { CASE, initialCaseData } from '../../../interfaces/case';

const CaseModal: React.FC<{ rowData: any }> = ({ rowData }) => {
  const [openModal, setOpenModal] = React.useState(true);
  const [caseDetails, setDetails] = React.useState<CASE>(initialCaseData);
  useEffect(() => {
    const fetchData = async () => {
      apiGet(`${environment.caseURL}/${rowData.case_serial_number}`)
        .then((data: any) => {
          setDetails(data[0]);
        })
        .catch((error: { message: string }) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    const debouncedFetchData = debounce(fetchData, 100); // Debounce for 100 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [rowData]);
  return (
    <Modal
      open={openModal}
      onRequestClose={() => setOpenModal(false)}
      modalHeading={
        <>
          Case ID: {rowData.case_serial_number}
          {rowData.discrepancy && (
            <span className="discrepancy-legend">
              {rowData.discrepancy !== 'On-Time' && <WarningFilled size={18} />}{' '}
              {rowData.discrepancy}
            </span>
          )}
        </>
      }
    >
      <div className="modal-content">
        <CaseLifeCycleModal caseDetails={caseDetails} />
      </div>
    </Modal>
  );
};

export default CaseModal;
