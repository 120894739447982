export interface CASE {
  case_id: number;
  pallet_serial_no: string;
  case_serial_number: string;
  wrin: string;
  wrin_description: string;
  lot_no: string;
  category_name: string;
  exp_dt: string;
  cte1_supplier: string;
  cte1_date: string;
  cte2_supplier: string;
  cte2_date: string;
  cte3_dc: string;
  cte3_date: string;
  cte4_supplier: string;
  cte4_date: string;
  cte5_receive: string;
  cte5_date: string;
  cte2_discrepancy: string;
  cte3_discrepancy: string;
  cte4_discrepancy: string;
  cte5_discrepancy: string;
  cte6_discrepancy: string;
}

export const initialCaseData = {
  case_id: 0,
  pallet_serial_no: '',
  case_serial_number: '',
  wrin: '',
  wrin_description: '',
  lot_no: '',
  category_name: '',
  exp_dt: '',
  cte1_supplier: '',
  cte1_date: '',
  cte2_supplier: '',
  cte2_date: '',
  cte3_dc: '',
  cte3_date: '',
  cte4_supplier: '',
  cte4_date: '',
  cte5_receive: '',
  cte5_date: '',
  cte2_discrepancy: '',
  cte3_discrepancy: '',
  cte4_discrepancy: '',
  cte5_discrepancy: '',
  cte6_discrepancy: '',
};
