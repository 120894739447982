import TileGroup from '@carbon/react/lib/components/TileGroup/TileGroup';
import RadioTile from '@carbon/react/lib/components/RadioTile/RadioTile';
import React, { useState, useEffect } from 'react';
import './tiles.scss';
import { ChevronDown } from '@carbon/icons-react';
import { environment } from '../../../environment';
import { debounce } from 'lodash';
import { apiPost } from '../../../services/apiService';
const Tiles_Data = require('../../../assets/data/tiles.json');

interface TileProps {
  type: string;
  sendDataToReport: any;
  form: any;
  viewType: any;
  backButtonClicked: any;
  savedTileIndex: any;
  savedReportsData: any;
}

const Tiles: React.FC<TileProps> = ({
  type,
  sendDataToReport,
  form,
  viewType,
  backButtonClicked,
  savedTileIndex,
  savedReportsData,
}) => {
  const formData: any = {
    discrepancy: 'all',
  };

  const report_seven: any = {
    discrepancy: [],
  };

  const report_three_payload: any = {
    discrepancy: 'all',
  };
  const report_fiveData: any = {
    discrepancy: [],
  };

  const report_fourData: any = {
    discrepancy: 'all',
  };

  const report_sixData: any = {
    discrepancy: 'all',
    dc: [],
  };

  const report_eightData: any = {
    discrepancy: [],
  };

  const [rows, setRows] = React.useState<any>([]);
  const [selectedTile, setSelectedIndex] = useState(1);
  const [tile, setTile] = useState<any>();
  const [displayedTiles, setDisplayedTiles] = useState<any[]>([]);
  const onTileChange = (item: any, index: any) => {
    setTile({ ...item });
    sendDataToReport({
      id: item.id,
      label: item.label,
      index: index,
    });
  };

  const getData = async (URI: string, payload: any) => {
    if (URI) {
      apiPost(`${URI}?offset=0&limit=10`, payload)
        .then((data: any) => {
          setRows(data);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    }
  };
  const debouncedFetchData = debounce(getData, 300); // Debounce for 300 milliseconds

  useEffect(() => {
    const user = sessionStorage.getItem('user');
    const userRole = user && JSON.parse(user).role;
    if (type === 'cte4_vs_cte5' && userRole !== 'Admin') {
      const restaurantTiles = Tiles_Data[type].filter(
        (item: any) => item.id !== 'under_review'
      );
      setDisplayedTiles(restaurantTiles);
      restaurantTiles.map((data: any, index: any) => {
        if (data.default_selected) {
          setSelectedIndex(index);
          sendDataToReport({
            id: data.id,
            label: data.label,
            index: index,
          });
          setTile({
            id: data.id,
            label: data.label,
            index: index,
          });
        }
      });
    } else {
      setDisplayedTiles(Tiles_Data[type]);
      Tiles_Data[type].map((data: any, index: any) => {
        if (data.default_selected) {
          setSelectedIndex(index);
          sendDataToReport({
            id: data.id,
            label: data.label,
            index: index,
          });
          setTile({
            id: data.id,
            label: data.label,
            index: index,
          });
        }
      });
    }
  }, [type]);

  useEffect(() => {
    if (backButtonClicked) {
      setSelectedIndex(savedTileIndex.index);
      if (savedTileIndex.isComplaint) {
        sendDataToReport({
          label: 'Compliant',
          count: 0,
          id: ['', 'Nearing Expiry'],
          pass_value: 'complaint_count',
        });
      } else if (savedTileIndex.isOnTime) {
        sendDataToReport({
          label: 'On-Time',
          count: 0,
          id: 'On-Time',
          pass_value: 'ontimecount',
        });
      } else {
        sendDataToReport({
          id: savedTileIndex.id,
          label: savedTileIndex.label,
          index: savedTileIndex.index,
        });
      }
    }
  }, [backButtonClicked, savedTileIndex]);

  useEffect(() => {
    setRows({ ...savedReportsData });
  }, [savedReportsData]);

  useEffect(() => {
    if (tile && tile.id !== 'all') {
      if (type === 'cte1_vs_cte2') {
        if (form) {
          formData.supplier =
            form.supplier && form.supplier.node_name
              ? form.supplier.node_name === 'All'
                ? []
                : [form.supplier.node_name]
              : formData.supplier;

          formData.startDispatchDate = form.startDispatchDate;
          formData.endDispatchDate = form.endDispatchDate;
          formData.startPutAwayDate = form.startPutAwayDate;
          formData.endPutAwayDate = form.endPutAwayDate;
          debouncedFetchData(environment.inventoryURL, formData);
        }
      }
      if (type === 'cte2_shelf_life') {
        if (form) {
          report_seven.supplier =
            form.supplier && form.supplier.node_name
              ? form.supplier.node_name === 'All'
                ? []
                : [form.supplier.node_name]
              : report_seven.supplier;
          report_seven.startExpirationDate =
            form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
          report_seven.endExpirationDate =
            form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
          report_seven.category =
            form.category && form.category.category_name
              ? form.category.param
              : report_seven.category;
          report_seven.wrin =
            form.item && form.item.wrin_description
              ? form.item.param
              : report_seven.wrin;
          debouncedFetchData(environment.reportCasesSupplierURL, report_seven);
        }
      }
      if (type === 'cte2_vs_cte3') {
        if (form) {
          report_three_payload.viewType =
            viewType === 'case_view' ? viewType : 'pallet_view';
          report_three_payload.supplier =
            form.supplier && form.supplier.node_name
              ? form.supplier.node_name === 'All'
                ? []
                : [form.supplier.node_name]
              : formData.supplier;

          report_three_payload.startDispatchDate = form.startDispatchDate;
          report_three_payload.endDispatchDate = form.endDispatchDate;
          report_three_payload.startReceiptDate = form.startReceiptDate;
          report_three_payload.endReceiptDate = form.endReceiptDate;
          report_three_payload.startExpectedDeliveryDate = 'all';
          report_three_payload.endExpectedDeliveryDate = 'all';
          report_three_payload.wrin = form.wrin && form.wrin.param;
          report_three_payload.lotNo =
            form.lotNo && form.lotNo.lot_no === 'All'
              ? []
              : [form.lotNo.lot_no];
          report_three_payload.dispatchTo =
            form.dispatchTo.node_name === 'All'
              ? []
              : [form.dispatchTo.node_name];
          debouncedFetchData(environment.report_threeURL, report_three_payload);
        }
      }
      if (type === 'cte3_vs_cte4') {
        if (form) {
          report_fourData.distributionCentre =
            form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
          report_fourData.startReceiptDate = form.startReceiptDate;
          report_fourData.endReceiptDate = form.endReceiptDate;
          report_fourData.startDispatchDate = form.startDispatchDate;
          report_fourData.endDispatchDate = form.endDispatchDate;
          report_fourData.wrinNumber = form.wrin && form.wrin.param;
          debouncedFetchData(environment.reportFourURL, report_fourData);
        }
      }
      if (type === 'cte4_dc_shelf_life') {
        if (form) {
          report_fiveData.startExpirationDate =
            form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
          report_fiveData.endExpirationDate =
            form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
          report_fiveData.category =
            form.category && form.category.category_name
              ? form.category.param
              : report_fiveData.category;
          report_fiveData.wrin =
            form.item && form.item.wrin_description
              ? form.item.param
              : report_fiveData.wrin;
          report_fiveData.dc =
            form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];
          debouncedFetchData(environment.report_fiveURL, report_fiveData);
        }
      }
      if (type === 'cte4_vs_cte5') {
        if (form) {
          report_sixData.dc =
            form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];

          report_sixData.startDispatchDate = form.startDispatchDate;
          report_sixData.endDispatchDate = form.endDispatchDate;
          report_sixData.startRestPutAwayDate = form.startPutAwayDate;
          report_sixData.endRestPutAwayDate = form.endPutAwayDate;
          report_sixData.wrin = form.wrin && form.wrin.param;
          report_sixData.lotNo =
            form.lotNo && form.lotNo.lot_no === 'All'
              ? []
              : [form.lotNo.lot_no];
          report_sixData.dispatchTo =
            form.restaurant &&
            form.restaurant.node_name &&
            form.restaurant.node_name === 'All'
              ? []
              : form.restaurant
                  .filter(
                    (node: { node_name: string }) => node.node_name !== 'All'
                  )
                  .map((node: { node_name: string }) => node.node_name);
          report_sixData.orderNo =
            form.orderNo && form.orderNo.order_no === 'All'
              ? []
              : [form.orderNo.order_no];
          debouncedFetchData(environment.reportSixURL, report_sixData);
        }
      }
      if (type === 'cte5_shelf_life') {
        if (form) {
          report_eightData.restaurant =
            form.restaurant.node_name && form.restaurant.node_name === 'All'
              ? []
              : form.restaurant
                  .filter(
                    (node: { node_name: string }) => node.node_name !== 'All'
                  )
                  .map((node: { node_name: string }) => node.node_name);

          report_eightData.startExpirationDate =
            form.expirationStartDate !== '' ? form.expirationStartDate : 'all';
          report_eightData.endExpirationDate =
            form.expirationEndDate !== '' ? form.expirationEndDate : 'all';
          report_eightData.category =
            form.category && form.category.category_name
              ? form.category.param
              : report_eightData.category;
          report_eightData.wrinDescription =
            form.item && form.item.wrin_description
              ? form.item.param
              : report_eightData.wrin;
        }
        if (viewType === 'WRIN_view') {
          report_eightData.wrin = form.wrin && form.wrin.param;
        }
        const URI =
          viewType === 'WRIN_view'
            ? environment.reportEightWrinViewURL
            : environment.reportEightURL;
        debouncedFetchData(URI, report_eightData);
      }
      if (type === 'cte4_vs_cte5_order_accuracy') {
        const formData: any = {};
        if (form) {
          formData.orderNo =
            form.orderNo && form.orderNo.order_no === 'All'
              ? []
              : [form.orderNo.order_no];
          formData.wrin = form.wrin && form.wrin.param;
          formData.startOrderDate = form.startReceiptDate;
          formData.endOrderDate = form.endReceiptDate;
          formData.discrepancy = 'all';
          formData.restaurant =
            form.restaurant.node_name && form.restaurant.node_name === 'All'
              ? []
              : form.restaurant
                  .filter(
                    (node: { node_name: string }) => node.node_name !== 'All'
                  )
                  .map((node: { node_name: string }) => node.node_name);
        }
        debouncedFetchData(environment.reportThirteenURL, formData);
      }
    }
    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [form, viewType]);
  // }, [form, viewType, type]); // This effect runs only once when the component mounts

  const setTilesData = (item: any) => {
    if (type === 'cte2_vs_cte3' && viewType === 'pallet_view') {
      return rows[item.pallet_pass_value] ? rows[item.pallet_pass_value] : 0;
    } else {
      return rows[item.pass_value] ? rows[item.pass_value] : 0;
    }
  };

  return (
    <div className="tiles-section">
      {displayedTiles.map((item: any, index: any) => (
        <TileGroup
          className={`radio-tile radio-tile-${index}`}
          defaultSelected={0}
          name={item.label}
          key={item.label}
        >
          <RadioTile
            key={`radio-${item.label}`}
            className={
              selectedTile === index ? 'cds--tile--is--selected' : 'tile-border'
            }
            id={`radio-tile-${index}`}
            value={index}
            onClick={() => {
              setSelectedIndex(index);
              onTileChange(item, index);
            }}
          >
            <div key={`tile-div-${item.label}`}>
              {item.label}
              <p>
                {setTilesData(item)}
                <span
                  key={`tile-show_down-${item.label}`}
                  className="show_down"
                >
                  <ChevronDown size={24} />
                </span>
              </p>
            </div>
          </RadioTile>
        </TileGroup>
      ))}
    </div>
  );
};

export default Tiles;
