import React, { useEffect } from 'react';
import { WarningFilled } from '@carbon/icons-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tablePaginationClasses,
} from '../../../utils/MuiComponents';
import './report-receipt.scss';
import {
  Data,
  initialRowData,
  columns as Fields,
  Column,
} from '../../../interfaces/report-receipt';
import CaseModal from '../case-modal-popup/case-modal';
import { TableSortLabel, Typography } from '@mui/material';
import { environment } from '../../../environment';
import { apiPost } from '../../../services/apiService';
import { DefinitionTooltip, Tooltip } from '@carbon/react';
import { ReactComponent as BlueTag } from '../../../assets/svg/BlueTag.svg';
import formatDate, {
  expiryDateFormat,
  dateFormat,
  dateFormatHover,
} from '../../../utils/date';
import eventBus from '../../../utils/EventBus';
import { debounce } from 'lodash';
import { ReactComponent as UnderReview } from '../../../assets/svg/under-review.svg';
import Loading from '@carbon/react/lib/components/Loading/Loading';
const tooltip_data = require('../../../assets/data/tooltipdesc.json');

interface ToggleProps {
  viewType: string;
  reportType: string;
  form: any;
  selectedTiles: any;
  sendData: any;
  backButtonClicked: string;
  setReportsData: any;
}

export const ReportReceipt: React.FC<ToggleProps> = ({
  viewType,
  form,
  reportType,
  selectedTiles,
  sendData,
  backButtonClicked,
  setReportsData,
}) => {
  const [rowsCount, setRowsCount] = React.useState(0);
  const [isOpenModal, setOpenModal] = React.useState(false);
  const [rowData, setRowData] = React.useState<Data>(initialRowData);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [columns, setColumns] = React.useState<Column[]>([]);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [backButtnClicked, setBackButtonClicked] = React.useState(false);
  const [role, setUserRole] = React.useState();

  const [sortBy, setSortBy] = React.useState<{
    field: keyof Data | string | undefined;
    order: 'asc' | 'desc' | undefined;
  }>({
    field: undefined,
    order: undefined,
  });
  const handleSort = (columnId: string) => {
    // Determine the sorting order for the clicked column
    setSortBy({
      field: columnId,
      order:
        sortBy.field === columnId && sortBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    setBackButtonClicked(backButtonClicked ? true : false);
  }, [backButtonClicked]);

  useEffect(() => {
    const defaultController = {
      page: 0,
      rowsPerPage: 10,
    };
    const defaultSort = {
      field: undefined,
      order: undefined,
    };
    if (form && selectedTiles) {
      const savedController: any = sessionStorage.getItem('controller');
      const controller = savedController
        ? JSON.parse(savedController)
        : defaultController;
      setController({
        page: backButtnClicked ? controller.page : 0,
        rowsPerPage: controller.rowsPerPage,
      });
      const savedSort: any = sessionStorage.getItem('sortBy');
      setSortBy(savedSort ? JSON.parse(savedSort) : defaultSort);
      if (backButtnClicked) {
        setBackButtonClicked(false);
      }
    }
  }, [selectedTiles, form, viewType]);

  useEffect(() => {
    const formData: any = {};
    let url: any;
    if (reportType === 'cte2_vs_cte3') {
      if (viewType === 'case_view') {
        setColumns(
          Fields.filter(
            (item) =>
              item.id !== 'pallet_no' &&
              item.id !== 'total_cases' &&
              item.id !== 'order_no' &&
              item.id !== 'dc_dispatch_date' &&
              item.id !== 'rest_putaway_date' &&
              item.id !== 'first_silent_read' &&
              item.id !== 'last_silent_read' &&
              item.id !== 'dispatch_date'
          )
        );
      } else {
        setColumns(
          Fields.filter(
            (item) =>
              item.id !== 'case_serial_number' &&
              item.id !== 'dc_dispatch_date' &&
              item.id !== 'order_no' &&
              item.id !== 'rest_putaway_date' &&
              item.id !== 'first_silent_read' &&
              item.id !== 'last_silent_read' &&
              item.id !== 'dispatch_date'
          )
        );
      }
      url = environment.report_threeURL;
      formData.viewType = viewType === 'case_view' ? viewType : 'pallet_view';
      if (selectedTiles && selectedTiles.label) {
        formData.discrepancy = selectedTiles.id;
      }
      if (form) {
        formData.supplier =
          form.supplier && form.supplier.node_name
            ? form.supplier.node_name === 'All'
              ? []
              : [form.supplier.node_name]
            : formData.supplier;

        formData.startDispatchDate = form.startDispatchDate;
        formData.endDispatchDate = form.endDispatchDate;
        formData.startReceiptDate = form.startReceiptDate;
        formData.endReceiptDate = form.endReceiptDate;
        formData.startExpectedDeliveryDate = 'all';
        formData.endExpectedDeliveryDate = 'all';
        formData.wrin = form.wrin && form.wrin.param;
        formData.lotNo =
          form.lotNo && form.lotNo.lot_no === 'All' ? [] : [form.lotNo.lot_no];
        formData.dispatchTo =
          form.dispatchTo.node_name === 'All'
            ? []
            : [form.dispatchTo.node_name];
      }
    }
    if (reportType === 'cte4_vs_cte5') {
      const user = sessionStorage.getItem('user');
      const userRole = user && JSON.parse(user).role;
      setUserRole(userRole);
      url = environment.reportSixURL;
      setColumns(
        Fields.filter(
          (item) =>
            item.id !== 'pallet_no' &&
            item.id !== 'total_cases' &&
            item.id !== 'dispatch_timestamp' &&
            item.id !== 'receipt_date' &&
            item.id !== 'dispatch_date'
        )
      );
      if (userRole !== 'Admin') {
        setColumns(
          Fields.filter(
            (item) =>
              item.id !== 'pallet_no' &&
              item.id !== 'total_cases' &&
              item.id !== 'dispatch_timestamp' &&
              item.id !== 'receipt_date' &&
              item.id !== 'first_silent_read' &&
              item.id !== 'last_silent_read' &&
              item.id !== 'dispatch_date'
          )
        );
      }

      if (selectedTiles && selectedTiles.label) {
        formData.discrepancy = selectedTiles.id;
      }
      if (form) {
        formData.dc =
          form.dc && form.dc.node_name === 'All' ? [] : [form.dc.node_name];

        formData.startDispatchDate =
          userRole === 'Restaurant' ? 'All' : form.startDispatchDate;
        formData.endDispatchDate =
          userRole === 'Restaurant' ? 'All' : form.endDispatchDate;
        formData.startRestPutAwayDate = form.startPutAwayDate;
        formData.endRestPutAwayDate = form.endPutAwayDate;
        formData.wrin = form.wrin && form.wrin.param;
        formData.lotNo =
          form.lotNo && form.lotNo.lot_no === 'All' ? [] : [form.lotNo.lot_no];
        formData.dispatchTo =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);
        formData.orderNo =
          form.orderNo && form.orderNo.order_no === 'All'
            ? []
            : [form.orderNo.order_no];
      }
    }
    if (reportType === 'restaurant_case_transfer') {
      setColumns(
        Fields.filter(
          (item) =>
            item.id !== 'pallet_no' &&
            item.id !== 'total_cases' &&
            item.id !== 'order_no' &&
            item.id !== 'dc_dispatch_date' &&
            item.id !== 'rest_putaway_date' &&
            item.id !== 'first_silent_read' &&
            item.id !== 'last_silent_read' &&
            item.id !== 'expected_delivery_date' &&
            item.id !== 'discrepancy' &&
            item.id !== 'destination' &&
            item.id !== 'dispatch_timestamp'
        )
      );
      if (viewType === 'Transfer_Out') {
        url = environment.reportTenTransferOutURL;
        if (form) {
          formData.wrin = form.wrin && form.wrin.param;
          formData.origin =
            form.origin && form.origin.node_name === 'All'
              ? []
              : [form.origin.node_name];
          formData.startDispatchDate = form.startDispatchDate;
          formData.endDispatchDate = form.endDispatchDate;
        }
      } else {
        url = environment.reportTenTransferInURL;
        if (form) {
          formData.wrin = form.wrin && form.wrin.param;
          formData.origin =
            form.origin && form.origin.node_name === 'All'
              ? []
              : [form.origin.node_name];
          formData.startReceiptDate = form.startDispatchDate;
          formData.endReceiptDate = form.endDispatchDate;
        }
      }
    }
    formData.sorting_for = sortBy ? sortBy.field : '';
    formData.sorting_type = sortBy ? sortBy.order : '';
    const getData = async () => {
      setIsLoading(true);
      const offset = controller.page * controller.rowsPerPage;
      apiPost(
        `${url}?offset=${offset}&limit=${controller.rowsPerPage}`,
        formData
      )
        .then((data: any) => {
          if (reportType === 'cte4_vs_cte5') {
            // To be removed later once below logic is been hanlded in API
            data.list.map((dt: any) => {
              dt.discrepancy = dt.summary;
              if (dt.summary === 'Under Review') {
                dt.summary = '';
              } else if (dt.summary === 'Under Review,Delayed') {
                dt.summary = 'Delayed';
              } else if (dt.summary === 'Under Review,Missing') {
                dt.summary = 'Missing';
              } else if (dt.summary === 'delayed') {
                dt.summary = 'Delayed';
              } else if (dt.summary === 'missing') {
                dt.summary = 'Missing';
              }
            });
          }
          setRowsCount(data.totalListCount);
          eventBus.emit('dataEvent', data.lastUpdatedTime);
          setRows([...data.list]);

          if (
            formData.discrepancy === 'all' ||
            reportType === 'restaurant_case_transfer'
          ) {
            setReportsData({ ...data });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setRowsCount(0);
          setRows([]);
          console.log('Error fetching data: ' + error.message);
        });
    };
    const debouncedFetchData = debounce(getData, 400); // Debounce for 500 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [sortBy, form, selectedTiles, viewType, controller]); // This effect runs only once when the component mounts

  const handleChangePage = (event: unknown, newPage: number) => {
    const _controller = {
      ...controller,
      page: newPage,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _controller = {
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    };
    setController(_controller);
    sessionStorage.setItem('controller', JSON.stringify(_controller));
  };

  const openModal = (row: Data) => {
    setOpenModal(!isOpenModal);
    setRowData(row);
    sendData(row);
    sessionStorage.setItem('controller', JSON.stringify(controller));
    sessionStorage.setItem('sortBy', JSON.stringify(sortBy));
  };

  return (
    <div className={`custom-table receipt-table ${reportType}-table`}>
      {isLoading && <Loading />}
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          key="table_1"
          size="small"
        >
          <TableHead>
            <TableRow className="header">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{ minWidth: `${column.minWidth}vw` }}
                >
                  <TableSortLabel
                    active={(sortBy && sortBy.field === column.id) || undefined} // Check if sortBy.field matches the current column id
                    direction={
                      sortBy && sortBy.field === column.id
                        ? sortBy.order
                        : undefined // default direction when the column is not active
                    }
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? ( // Check if there are no records
              <TableRow>
                <TableCell colSpan={11}>
                  {' '}
                  <Typography variant="body1" align="center">
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row: any) => {
                return (
                  <React.Fragment key={row['Case Id']}>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row['Case Id']}
                      className={`transition_${row.transition}`}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ minWidth: `${column.minWidth}vw` }}
                          >
                            {column.id !== 'case_serial_number' &&
                              column.id !== 'discrepancy' &&
                              column.id !== 'exp_date' &&
                              column.id !== 'dc_dispatch_date' &&
                              column.id !== 'dispatch_timestamp' &&
                              column.id !== 'expected_delivery_date' &&
                              column.id !== 'receipt_date' &&
                              column.id !== 'rest_putaway_date' &&
                              column.id !== 'first_silent_read' &&
                              column.id !== 'last_silent_read' &&
                              column.id !== 'dispatch_date' &&
                              (value ? value : '--')}
                            {column.id === 'discrepancy' &&
                              row.discrepancy === 'Under Review' && (
                                <span
                                  className={`discrepancy-col ${row.discrepancy} ${row.summary} bg-none`}
                                >
                                  <Tooltip
                                    label={
                                      tooltip_data[reportType][row.discrepancy]
                                    }
                                    align="bottom"
                                  >
                                    <UnderReview className="under-review-svg" />
                                  </Tooltip>
                                </span>
                              )}
                            {column.id === 'discrepancy' && row.summary && (
                              <span
                                className={`discrepancy-col ${row.discrepancy} ${row.summary}`}
                              >
                                {row.summary !== 'On-Time' &&
                                  !row.discrepancy.includes('Under Review') && (
                                    <Tooltip
                                      label={
                                        tooltip_data[reportType][row.summary]
                                      }
                                      align="bottom"
                                    >
                                      <WarningFilled size={18} />
                                    </Tooltip>
                                  )}
                                {row.discrepancy.includes('Under Review') && (
                                  <Tooltip
                                    label={
                                      tooltip_data[reportType][row.discrepancy]
                                    }
                                    align="bottom"
                                  >
                                    <UnderReview className="under-review-svg" />
                                  </Tooltip>
                                )}
                                {row.discrepancy.includes('Under Review') && (
                                  <Tooltip
                                    label={
                                      tooltip_data[reportType][row.summary]
                                    }
                                    align="bottom"
                                  >
                                    <WarningFilled size={18} />
                                  </Tooltip>
                                )}
                                {row.summary}
                              </span>
                            )}
                            {column.id === 'case_serial_number' && (
                              <span
                                className="caseId"
                                onClick={() => openModal(row)}
                              >
                                {reportType === 'cte4_vs_cte5' &&
                                  role === 'Admin' &&
                                  (row.is_dc_flag &&
                                  (row.wrin === '13297' ||
                                    row.wrin === '16160') ? (
                                    <BlueTag className="blue-tag-svg" />
                                  ) : (
                                    <span className="blue-tag-vacant-svg"></span>
                                  ))}

                                {value}
                              </span>
                            )}
                            {column.id === 'exp_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={formatDate(value)}
                                >
                                  {expiryDateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'dispatch_timestamp' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'dispatch_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'dc_dispatch_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'expected_delivery_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'rest_putaway_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {(column.id === 'first_silent_read' ||
                              column.id === 'last_silent_read') && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={dateFormatHover(value)}
                                >
                                  {dateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'receipt_date' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={formatDate(value)}
                                >
                                  {expiryDateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rowsCount ? rowsCount : 0}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="custom-pagination"
        sx={{
          [`& .${tablePaginationClasses.spacer}`]: {
            display: 'none',
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: 'center',
          },
        }}
      />
      {isOpenModal && rowData && <CaseModal rowData={rowData} />}
    </div>
  );
};
