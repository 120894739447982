const apiURL = 'https://edscstageapi.mcd.com';

export const environment = {
  production: true,
  baseURL: apiURL,
  caseURL: `${apiURL}/reports/case`,
  suppliers_dd: `${apiURL}/reports/suppliers`,
  wrin_dd: `${apiURL}/reports/wrin`,
  category_dd: `${apiURL}/reports/category`,
  wrin_desc_dd: `${apiURL}/reports/wrindescription`,
  lotNoURL: `${apiURL}/reports/lotNumbers`,
  dcURL: `${apiURL}/reports/dc`,
  restaurantsURL: `${apiURL}/reports/restaurants`,
  orderNoURL: `${apiURL}/reports/orderNumbers`,
  restaurantOrderNumbersURL: `${apiURL}/reports/restaurantOrderNumbers`,
  reportURL: `${apiURL}/reports/cte1`,
  inventoryURL: `${apiURL}/reports/cte1_vs_cte2`,
  report_six_downloadURL: `${apiURL}/reports/cte1_vs_cte2_download`,
  reportCasesSupplierURL: `${apiURL}/reports/cte2_shelf_life`,
  reportCasesSupplierDownloadURL: `${apiURL}/reports/cte2_shelf_life_download`,
  report_threeURL: `${apiURL}/reports/cte2_vs_cte3`,
  report_threeDownloadURL: `${apiURL}/reports/cte2_vs_cte3_download`,
  report_fiveURL: `${apiURL}/reports/cte4_dc_shelf_life`,
  report_fiveDownloadURL: `${apiURL}/reports/cte4_dc_shelf_life_download`,
  reportFourURL: `${apiURL}/reports/cte3_vs_cte4`,
  reportFourDownloadURL: `${apiURL}/reports/cte3_vs_cte4_download`,
  reportSixURL: `${apiURL}/reports/cte4_vs_cte5`,
  reportSixDownloadURL: `${apiURL}/reports/cte4_vs_cte5_download`,
  reportEightURL: `${apiURL}/reports/cte5_shelf_life`,
  reportEightWrinViewURL: `${apiURL}/reports/cte5_shelf_life_wrin_view`,
  reportEightWrinExpansionURL: `${apiURL}/reports/wrin_case_id_details`,
  reportEightDownloadURL: `${apiURL}/reports/cte5_shelf_life_download`,
  reportNineURL: `${apiURL}/reports/cte5_vs_cte6`,
  reportNineDownloadURL: `${apiURL}/reports/cte5_vs_cte6_download`,
  reportNineWRINURL: `${apiURL}/reports/cte5_vs_cte6_cases_by_wrin`,
  reportTenTransferInURL: `${apiURL}/reports/restaurant_case_transfer_in`,
  reportTenTransferInDownloadURL: `${apiURL}/reports/restaurant_case_transfer_in_download`,
  reportTenTransferOutURL: `${apiURL}/reports/restaurant_case_transfer_out`,
  reportTenTransferOutDownloadURL: `${apiURL}/reports/restaurant_case_transfer_out_download`,
  reportThirteenURL: `${apiURL}/reports/cte45_order_accuracy`,
  reportThirteenDownloadURL: `${apiURL}/reports/cte45_order_accuracy_download`,
  reportFourteenURL: `${apiURL}/reports/cte5_vs_cte6_inventory_summary`,
  reportFourteenDownloadURL: `${apiURL}/reports/cte5_vs_cte6_inventory_summary_download`,
  CTE5_CTE6_CONSUMED_INV_URL: `${apiURL}/reports/cte56_consumed_inventory`,
  CTE5_CTE6_CONSUMED_INV_DOWNLOAD_URL: `${apiURL}/reports/cte56_consumed_inventory_download`,
};
