// src/App.tsx
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './components/login/login';
import PrivateRoutes from './utils/PrivateRoutes';
import './App.scss';
import './assets/scss/common.scss';
import Reports from './components/reports/reports';

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(true);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  return (
    <div className="container">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route element={loggedIn && <PrivateRoutes />}>
          <Route path="/reports" element={<Reports />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
