import React, { useEffect, useRef } from 'react';

const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

interface Props {
  children: React.ReactNode;
  onClickOutside: () => void;
}

const OutsideClickDetector: React.FC<Props> = ({
  children,
  onClickOutside,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperRef, onClickOutside);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickDetector;
