import { AspectRatio, Column, Grid } from '@carbon/react';
import './case-digital-journey.scss';
import React, { useEffect } from 'react';
import { Box, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { RadioButtonChecked, WarningFilled } from '@carbon/icons-react';
import { apiGet } from '../../../services/apiService';
import { environment } from '../../../environment';
import { CASE, initialCaseData } from '../../../interfaces/case';
import { DigitalJourneyRightPanel } from '../../../assets/data/digital-journey.json';
import { ReactComponent as Expiry } from '../../../assets/svg/Expiry.svg';
import { ReactComponent as UnderReview } from '../../../assets/svg/under-review.svg';
import Loading from '@carbon/react/lib/components/Loading/Loading';
import { expiryDateFormat } from '../../../utils/date';
import { debounce } from 'lodash';
import {
  DIGITAL_JOURNEY,
  initialData,
} from '../../../interfaces/digital-journey';
import moment from 'moment';

const CaseDigitalJourney = ({ searchValue }: { searchValue: string }) => {
  const rightPanel = DigitalJourneyRightPanel;
  const [isBeginning, setBeginning] = React.useState(true);
  const [isDataLoaded, setDataLoaded] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [discrepancyStep, setDiscrepancyStep] = React.useState(-1);
  const [caseDetails, setDetails] = React.useState<CASE>(initialCaseData);
  const [detailedView, setDetailedView] =
    React.useState<DIGITAL_JOURNEY>(initialData);
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [steps, setSteps] = React.useState<DIGITAL_JOURNEY[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const CustomStepIcon = () => {
    return (
      <div>
        <RadioButtonChecked size={24} />
      </div>
    );
  };

  // Function to parse the custom date format to a Date object
  // const parseCustomDate = (dateString: string): Date => {
  //   const [datePart, timePart] = dateString.split(' ');
  //   const [month, day, year] = datePart.split('/').map(Number);
  //   const [hours, minutes, seconds] = timePart.split(':').map(Number);
  //   return new Date(year, month - 1, day, hours, minutes, seconds);
  // };

  useEffect(() => {
    if (searchValue) {
      setBeginning(false);
      setIsLoading(true);
      const fetchData = async () => {
        setIsLoading(true);
        await apiGet(`${environment.caseURL}/${searchValue}`)
          .then(async (data: any) => {
            if (data && data.length) {
              // Sort the array by timestamp
              // data.sort((a: any, b: any) => {
              //   const dateA = parseCustomDate(a.reporttime).getTime();
              //   const dateB = parseCustomDate(b.reporttime).getTime();
              //   return dateA - dateB;
              // });
              setDataLoaded(true);
              setSteps([...data]);
              if (data.length) {
                const lastValidStepIndex = data.length - 1; // Initialize index of the last valid step
                onStepClick(data[0], 0); // Call onStepClick with the last valid step index
                setDetailedView({ ...data[0] });
                setDetails({ ...data[0] });
                setActiveStep(lastValidStepIndex);
              }
            } else {
              setDataLoaded(false);
            }
          })
          .catch((error: { message: string }) => {
            setDataLoaded(false);
            setIsLoading(false); // Stop loading
            console.log('Error fetching data: ' + error.message);
          })
          .finally(() => {
            setIsLoading(false); // Stop loading
          });
      };
      const debouncedFetchData = debounce(fetchData, 400); // Debounce for 400 milliseconds

      // Call the debounced function when dependencies change
      debouncedFetchData();

      // Cleanup function to cancel any pending debounce calls when the component unmounts
      return () => {
        debouncedFetchData.cancel();
      };
    } else {
      setDetails(initialCaseData);
    }
  }, [searchValue]);

  const onStepClick = (step_data: any, index: any) => {
    const step = { ...step_data };
    setDetails({ ...step_data });
    setSelectedStep(index);
    const getData = async () => {
      await apiGet(
        `${environment.caseURL}/${searchValue}/${step.reportname}/${step.event_type_id}/${step.orgnode_id}/${step.action_event_type_id}`
      )
        .then(async (data: any) => {
          if (data.length) {
            if (data[0].discrepancy) {
              const dataArray = data[0].discrepancy.split(',');
              dataArray.map((dt: string, index: number) => {
                if (dt === 'delayed') {
                  dataArray[index] = 'Delayed';
                }

                if (dt === 'under_review') {
                  dataArray[index] = 'Under Review';
                }
              });
              const classFromString: string[] = dataArray
                .map((value: string) => value.trim())
                .filter(
                  (value: string) =>
                    value !== '' &&
                    value !== 'Delayed' &&
                    value !== 'red_missing'
                ); // Filter out empty strings
              const arrayFromString: string[] = dataArray
                .map((value: string) => value.trim())
                .filter(
                  (value: string) =>
                    value !== '' &&
                    value !== 'light_orange_delayed' &&
                    value !== 'dark_orange_delayed' &&
                    value !== 'orange_delayed' &&
                    value !== 'red_missing'
                ); // Filter out empty strings
              step.discrepancy_class = classFromString || [];
              step.discrepancy = arrayFromString || [];
              setDiscrepancyStep(arrayFromString.length ? index : -1);
            }
            setDetailedView({ ...step });
          } else {
            setDetailedView({
              ...step,
              discrepancy: [],
              discrepancy_class: [],
            });
            setDiscrepancyStep(-1);
          }
        })
        .catch((error: { message: string }) => {
          setDetailedView({ ...step, discrepancy: [], discrepancy_class: [] });
          setDiscrepancyStep(-1);
          console.log('Error fetching data: ' + error.message);
        });
    };
    getData();
  };

  return (
    <>
      <div className="digital-journey">
        {isLoading && <Loading />}
        {!isLoading && isDataLoaded && (
          <Grid>
            <Column sm={2} md={2} lg={10}>
              <AspectRatio ratio="1x1" className="grid-1">
                <div className="case-id-heading">
                  <p className="case-id">Case ID: {searchValue}</p>
                </div>
                <Box sx={{ maxWidth: 800 }}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map(
                      (step: any, index: number) =>
                        index <= activeStep && (
                          <Step key={step.param} active>
                            <StepLabel
                              className={`${selectedStep === index ? 'purpleRadio' : 'defaultRadio'}`}
                              StepIconComponent={CustomStepIcon}
                              onClick={() => onStepClick(step, index)}
                            >
                              <p className="step-timestamp">
                                {step.reporttime
                                  ? moment(step.reporttime).format(
                                      'MM/DD/YYYY; hh:mm:ss A'
                                    )
                                  : '--'}
                                {index > -1 && index === discrepancyStep && (
                                  <WarningFilled
                                    size={24}
                                    className="discrepancy-icon"
                                  />
                                )}
                              </p>
                            </StepLabel>
                            <StepContent>
                              <p className="step-label">
                                {step.node_name || '--'}
                              </p>
                              <p className="step-type">
                                {step.reportname || '--'}
                              </p>
                              <p className="step-process">
                                {step.process || '--'}
                              </p>
                            </StepContent>
                          </Step>
                        )
                    )}
                  </Stepper>
                </Box>
              </AspectRatio>
            </Column>
            <Column sm={2} md={2} lg={6}>
              <AspectRatio ratio="1x2" className="sub-grid">
                <div className="sub-grid-content">
                  <div className="row">
                    <div className="label col-field">WRIN</div>
                    <div className="description">WRIN Description</div>
                    <div className="category col-field">Category</div>
                    <div className="lotno col-field">Lot No</div>
                    <div className="exp-date col-field">Expiration Dt.</div>
                  </div>
                  <div className="row">
                    <div className="value col-field">{caseDetails.wrin}</div>
                    <div className="value description">
                      {caseDetails.wrin_description || '--'}
                    </div>
                    <div className="value col-field category">
                      {caseDetails.category_name || '--'}
                    </div>
                    <div className="value col-field">
                      {caseDetails.lot_no || '--'}
                    </div>
                    <div className="value col-field exp-date ">
                      {caseDetails.exp_dt
                        ? expiryDateFormat(caseDetails.exp_dt)
                        : '--'}
                    </div>
                  </div>
                </div>
              </AspectRatio>
              <AspectRatio ratio="2x3" className="grid-2">
                {Object.entries(rightPanel).map(([section, detailsArray]) => (
                  <div className="details" key={section}>
                    <div className="detail-content">
                      {detailsArray.length &&
                        detailsArray.map((detail, index) => (
                          <React.Fragment key={index}>
                            <p className="label">{detail.label}</p>
                            {detail.field !== 'discrepancy' && (
                              <p className="field">
                                {detailedView && detailedView[detail.field]
                                  ? detailedView[detail.field]
                                  : '--'}
                              </p>
                            )}
                            <div className="discrepancy">
                              {detail.field === 'discrepancy' &&
                                detailedView &&
                                detailedView.discrepancy &&
                                detailedView[detail.field] !== undefined &&
                                Array.isArray(detailedView.discrepancy) &&
                                detailedView.discrepancy.length > 0 &&
                                detailedView.discrepancy.map(
                                  (item: string, index: number) => (
                                    <p
                                      className={`discrepancy-col ${detailedView.discrepancy_class[index]}`}
                                      key={index}
                                    >
                                      {(item === 'missing_under_review' ||
                                        item === 'delayed_under_review' ||
                                        item === 'Under Review') && (
                                        <UnderReview className="under-review-svg" />
                                      )}
                                      {item !== 'Nearing Expiry' &&
                                        item !== 'Under Review' && (
                                          <WarningFilled size={24} />
                                        )}
                                      {item === 'Nearing Expiry' && (
                                        <Expiry className="expiry" />
                                      )}
                                      {item === 'missing_under_review'
                                        ? 'Missing'
                                        : item === 'delayed_under_review'
                                          ? 'Delayed'
                                          : item}
                                    </p>
                                  )
                                )}
                            </div>
                            {detail.field === 'discrepancy' &&
                              (!detailedView ||
                                (detailedView && !detailedView.discrepancy) ||
                                (detailedView &&
                                  detailedView.discrepancy &&
                                  detailedView.discrepancy.length === 0)) &&
                              '--'}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                ))}
              </AspectRatio>
            </Column>
          </Grid>
        )}
      </div>
      {!isLoading && !isDataLoaded && (
        <div className="noRecords">
          {isBeginning
            ? 'Please search for a case ID to view the digital journey of the case.'
            : 'No cases found. Please try again using some other valid case IDs.'}
        </div>
      )}
    </>
  );
};

export default CaseDigitalJourney;
