import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { WarningFilled } from '@carbon/icons-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  tablePaginationClasses,
  Box,
  Collapse,
  IconButton,
  AddBoxOutlinedIcon,
  IndeterminateCheckBoxOutlinedIcon,
} from '../../../utils/MuiComponents';
import {
  Data,
  columns,
  Column,
  columns_2,
  SubColumn,
  subColumns,
} from '../../../interfaces/report-comparison';
import { DefinitionTooltip, Tooltip } from '@carbon/react';
import { expiryDateFormat, dateFormatHover } from '../../../utils/date';
import { ReactComponent as Expiry } from '../../../assets/svg/Expiry.svg';
import './report-comparison.scss';
import { TableSortLabel, Typography } from '@mui/material';
import { apiPost } from '../../../services/apiService';
import { environment } from '../../../environment';
import eventBus from '../../../utils/EventBus';
import Loading from '@carbon/react/lib/components/Loading/Loading';

const tooltip_data = require('../../../assets/data/tooltipdesc.json');

interface ReportComparisionProps {
  type: string;
  form: any;
}

export const ReceiptComparison: React.FC<ReportComparisionProps> = ({
  type,
  form,
}) => {
  const setExpansion = true;
  const [expansionColumns, setSubColumns] = React.useState<SubColumn[]>([]);
  const [rowsCount, setRowsCount] = React.useState(0);
  const [expPage, setExpPage] = React.useState(0);
  const [exprowsPerPage, setExpRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(null);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sortBy, setSortBy] = React.useState<{
    field: keyof Data | string | undefined;
    order: 'asc' | 'desc' | undefined;
  }>({
    field: undefined,
    order: undefined,
  });
  const [reportColumns, setColumns] = React.useState<Column[]>([]);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [caseList, setCaseList] = React.useState<any>();

  const handleSort = (columnId: string) => {
    // Determine the sorting order for the clicked column
    if (columnId !== 'inner_pack_count' && columnId !== 'discrepancy') {
      const column = columnId === 'storageCount' ? 'total' : columnId;
      setSortBy({
        field: column,
        order:
          sortBy.field === column && sortBy.order === 'asc' ? 'desc' : 'asc',
      });
    }
    if (type === 'cte5_vs_cte6_compare_daily_inv') {
      setOpen(null);
      setCaseList(null);
    }
  };

  useEffect(() => {
    let URI: string;
    const formData: any = {};

    if (type === 'cte5_vs_cte6_consumed_inv') {
      URI = environment.CTE5_CTE6_CONSUMED_INV_URL;
      setColumns(
        columns.filter(
          (item) =>
            item.id !== 'storageCount' &&
            item.id !== 'inner_pack_count' &&
            item.id !== 'prep' &&
            item.id !== 'total' &&
            item.id !== 'trash'
        )
      );
      if (form) {
        formData.date = form.startReceiptDate || 'all';
        formData.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);

        formData.wrin =
          form.item && form.item.wrin_description
            ? form.item.param
            : formData.wrin;
        formData.category =
          form.category && form.category.category_name
            ? form.category.param
            : formData.category;
      }
    } else if (type === 'cte5_vs_cte6_compare_daily_inv') {
      URI = environment.reportNineURL;
      setColumns(
        columns.filter(
          (item) => item.id !== 'sales' && item.id !== 'edsc_consumption'
        )
      );
      if (form) {
        formData.date = form.startReceiptDate || 'all';
        formData.restaurant =
          form.restaurant.node_name && form.restaurant.node_name === 'All'
            ? []
            : form.restaurant
                .filter(
                  (node: { node_name: string }) => node.node_name !== 'All'
                )
                .map((node: { node_name: string }) => node.node_name);

        formData.wrin =
          form.item && form.item.wrin_description
            ? form.item.param
            : formData.wrin;
        formData.category =
          form.category && form.category.category_name
            ? form.category.param
            : formData.category;
      }
    }
    setSubColumns(subColumns);
    setOpen(null);
    setCaseList(null);
    formData.sorting_for = sortBy ? sortBy.field : '';
    formData.sorting_type = sortBy ? sortBy.order : '';
    const getData = async () => {
      setIsLoading(true);
      const offset = controller.page * controller.rowsPerPage;
      apiPost(
        `${URI}?offset=${offset}&limit=${controller.rowsPerPage}`,
        formData
      )
        .then((data: any) => {
          setRowsCount(data.totalListCount);
          eventBus.emit('dataEvent', data.lastUpdatedTime);

          data.list.map((data: any) => {
            data.storageCount =
              data.freezer + ' | ' + data.dryGoods + ' | ' + data.cooler;
          });
          setRows([...data.list]);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
          setIsLoading(false);
          setRowsCount(0);
        });
    };
    const debouncedFetchData = debounce(getData, 400); // Debounce for 400 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [sortBy, form, controller]); // This effect runs only once when the component mounts

  const handleChangePage = (event: unknown, newPage: number) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleExpChangePage = (event: unknown, newPage: number) => {
    setExpPage(newPage);
  };

  const handleExpChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setExpRowsPerPage(+event.target.value);
    setExpPage(0);
  };

  const handleOpen = (clickedIndex: any, row: any) => {
    if (open === clickedIndex) {
      setOpen(null);
      setCaseList(null);
    } else {
      setOpen(clickedIndex);
      setExpPage(0);
      setExpRowsPerPage(5);
      onExpansion(row);
    }
  };

  const onExpansion = (row: any) => {
    setIsLoading(true);
    setCaseList(null);
    const formData = {
      date: form.startReceiptDate || 'all',
    };
    apiPost(
      `${environment.reportNineWRINURL}/${row.node_name}/${row.wrin}`,
      formData
    )
      .then((data: any) => {
        setIsLoading(false);
        setCaseList(data);
      })
      .catch((error) => {
        setIsLoading(false);
        setCaseList(null);
        console.log('Error fetching data: ' + error.message);
      });
  };

  return (
    <div className="custom-table">
      {isLoading && <Loading />}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          key="table_1"
          size="small"
        >
          <TableHead>
            <TableRow className="header">
              {type === 'cte5_vs_cte6_compare_daily_inv' && setExpansion && (
                <TableCell colSpan={1}></TableCell>
              )}
              {reportColumns.map((column) => (
                <TableCell
                  key={column.id}
                  className={column.id}
                  sx={{ minWidth: `${column.minWidth}vw` }}
                >
                  <TableSortLabel
                    active={(sortBy && sortBy.field === column.id) || undefined} // Check if sortBy.field matches the current column id
                    direction={
                      sortBy && sortBy.field === column.id
                        ? sortBy.order
                        : undefined // default direction when the column is not active
                    }
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
            {form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' && (
              <TableRow className="header header_2">
                <TableCell colSpan={5}></TableCell>
                {columns_2.map((column_2) => (
                  <TableCell key={column_2.id} className={column_2.id}>
                    {column_2.label}
                  </TableCell>
                ))}
                <TableCell colSpan={3}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {rows.length === 0 ? ( // Check if there are no records
              <TableRow>
                <TableCell colSpan={11}>
                  {' '}
                  <Typography variant="body1" align="center">
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => {
                return (
                  <React.Fragment key={'comparison-' + index}>
                    <TableRow hover tabIndex={-1} key={'th1' + index}>
                      {type === 'cte5_vs_cte6_compare_daily_inv' &&
                        setExpansion && (
                          <TableCell key={`expand-${index}`}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleOpen(index, row)}
                            >
                              {open === index ? (
                                <IndeterminateCheckBoxOutlinedIcon />
                              ) : (
                                <AddBoxOutlinedIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        )}
                      {reportColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ minWidth: `${column.minWidth}vw` }}
                          >
                            {column.id !== 'total' &&
                              column.id !== 'discrepancy' &&
                              value}
                            {column.id === 'total' && (
                              <span
                                className="perpetual_col"
                                // onClick={() => handleOpen(index)}
                              >
                                {value}
                              </span>
                            )}
                            {column.id === 'discrepancy' &&
                              !Array.isArray(value) &&
                              value}
                            {column.id === 'discrepancy' &&
                              value &&
                              Array.isArray(value) &&
                              value.length > 0 &&
                              value.map((item: any) => (
                                <>
                                  {item !== 'Nearing Expiry' && (
                                    <p
                                      className={`discrepancy-col ${item}`}
                                      key={index}
                                    >
                                      {item === 'Expired Cases' && (
                                        <Tooltip
                                          className="tool-tip"
                                          label={tooltip_data[type][item]}
                                          align="bottom"
                                        >
                                          <WarningFilled size={18} />
                                        </Tooltip>
                                      )}
                                      {item === 'FEFO Non Compliant' && (
                                        <Tooltip
                                          className="tool-tip"
                                          label={tooltip_data[type][item]}
                                          align="bottom"
                                          autoAlign
                                        >
                                          <WarningFilled size={18} />
                                        </Tooltip>
                                      )}
                                      {item}
                                    </p>
                                  )}
                                  {item === 'Nearing Expiry' && (
                                    <span className="status-col expiry">
                                      <Tooltip
                                        className="tool-tip"
                                        label={tooltip_data[type][item]}
                                        align="bottom"
                                      >
                                        <Expiry className="near-expiry" />
                                      </Tooltip>
                                      {item}
                                    </span>
                                  )}
                                </>
                              ))}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow className="expand-row">
                      <TableCell colSpan={12}>
                        <Collapse
                          in={open === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Table
                              size="small"
                              aria-label="purchases"
                              key="table_2"
                            >
                              <TableHead>
                                <TableRow>
                                  {expansionColumns.map((column: any) => (
                                    <TableCell key={column.id}>
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              {caseList && (
                                <TableBody>
                                  {caseList.list.length === 0 ? ( // Check if there are no records
                                    <TableRow>
                                      <TableCell colSpan={11}>
                                        {' '}
                                        <Typography
                                          variant="body1"
                                          align="center"
                                        >
                                          No records found.
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    caseList.list
                                      .slice(
                                        expPage * exprowsPerPage,
                                        expPage * exprowsPerPage +
                                          exprowsPerPage
                                      )
                                      .map((historyRow: any) => (
                                        <TableRow
                                          key={historyRow && historyRow.caseId}
                                        >
                                          {expansionColumns.map((column) => (
                                            <TableCell key={column.id}>
                                              {column.id === 'exp_date' && (
                                                <span className="date-hover">
                                                  <DefinitionTooltip
                                                    openOnHover
                                                    definition={dateFormatHover(
                                                      historyRow[column.id]
                                                    )}
                                                  >
                                                    {expiryDateFormat(
                                                      historyRow[column.id]
                                                    )}
                                                  </DefinitionTooltip>
                                                </span>
                                              )}
                                              {column.id !== 'exp_date' &&
                                                column.id !== 'discrepancy' &&
                                                (historyRow[column.id] || '--')}
                                              {column.id === 'discrepancy' &&
                                                historyRow[column.id] &&
                                                Array.isArray(
                                                  historyRow[column.id]
                                                ) &&
                                                historyRow[column.id].length >
                                                  0 &&
                                                historyRow[column.id].map(
                                                  (item: any) => (
                                                    <>
                                                      {item !==
                                                        'Nearing Expiry' && (
                                                        <p
                                                          className={`discrepancy-col ${item}`}
                                                          key={index}
                                                        >
                                                          {item ===
                                                            'Expired Cases' && (
                                                            <Tooltip
                                                              className="tool-tip"
                                                              label={
                                                                tooltip_data[
                                                                  type
                                                                ][item]
                                                              }
                                                              align="bottom"
                                                            >
                                                              <WarningFilled
                                                                size={18}
                                                              />
                                                            </Tooltip>
                                                          )}
                                                          {item ===
                                                            'FEFO Non Compliant' && (
                                                            <Tooltip
                                                              className="tool-tip"
                                                              label={
                                                                tooltip_data[
                                                                  type
                                                                ][item]
                                                              }
                                                              align="bottom"
                                                              autoAlign
                                                            >
                                                              <WarningFilled
                                                                size={18}
                                                              />
                                                            </Tooltip>
                                                          )}
                                                          {item}
                                                        </p>
                                                      )}
                                                      {item ===
                                                        'Nearing Expiry' && (
                                                        <span className="status-col expiry">
                                                          <Tooltip
                                                            className="tool-tip"
                                                            label={
                                                              tooltip_data[
                                                                type
                                                              ][item]
                                                            }
                                                            align="bottom"
                                                          >
                                                            <Expiry className="near-expiry" />
                                                          </Tooltip>
                                                          {item}
                                                        </span>
                                                      )}
                                                    </>
                                                  )
                                                )}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))
                                  )}
                                </TableBody>
                              )}
                            </Table>
                            {caseList && (
                              <TablePagination
                                component="div"
                                count={caseList.list.length}
                                rowsPerPage={exprowsPerPage}
                                rowsPerPageOptions={[]}
                                page={expPage}
                                onPageChange={handleExpChangePage}
                                onRowsPerPageChange={handleExpChangeRowsPerPage}
                                sx={{
                                  [`& .${tablePaginationClasses.spacer}`]: {
                                    display: 'none',
                                  },
                                  [`& .${tablePaginationClasses.toolbar}`]: {
                                    justifyContent: 'right',
                                  },
                                }}
                              />
                            )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rowsCount ? rowsCount : 0}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="custom-pagination"
        sx={{
          [`& .${tablePaginationClasses.spacer}`]: {
            display: 'none',
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: 'center',
          },
        }}
      />
    </div>
  );
};
