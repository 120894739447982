export interface Column {
  id:
    | 'order_number'
    | 'wrin'
    | 'rop_orderdate'
    | 'restaurant_id'
    | 'rop_order_qty'
    | 'dc_shipped_qty'
    | 'restaurant_received_qty'
    | 'uom'
    | 'discrepancy';
  label?: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

export const columns: Column[] = [
  {
    id: 'order_number',
    label: 'Order No.',
  },
  {
    id: 'wrin',
    label: 'WRIN',
  },
  {
    id: 'rop_orderdate',
    label: 'ROP Order Date',
  },
  {
    id: 'restaurant_id',
    label: 'Restaurant ID',
  },
  {
    id: 'rop_order_qty',
    label: 'ROP Order Qty',
  },
  {
    id: 'dc_shipped_qty',
    label: 'DC Shipped Qty (RFID)',
  },
  {
    id: 'restaurant_received_qty',
    label: 'Rest. Received Qty (RFID)',
  },
  {
    id: 'uom',
    label: 'UOM',
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
  },
];

export interface Data {
  order_number: string;
  wrin: string;
  rop_orderdate: string;
  restaurant_id: string;
  rop_order_qty: string;
  dc_shipped_qty: string;
  restaurant_received_qty: string;
  uom: string;
  discrepancy: [];
}
