import React, { useEffect } from 'react';
import { Data, columns } from '../../../interfaces/restaurant-order-accuracy';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  tablePaginationClasses,
} from '@mui/material';
import Loading from '@carbon/react/lib/components/Loading/Loading';
import { WarningFilled } from '@carbon/icons-react';
import './restaurant-order-accuracy.scss';
import { apiPost } from '../../../services/apiService';
import { debounce } from 'lodash';
import { environment } from '../../../environment';
import eventBus from '../../../utils/EventBus';
import { DefinitionTooltip } from '@carbon/react';
import formatDate, { expiryDateFormat } from '../../../utils/date';

interface RestaurantAccuracyProps {
  form: any;
  selectedTiles: any;
  setReportsData: any;
}

export const RestaurantOrderAccuracy: React.FC<RestaurantAccuracyProps> = ({
  form,
  selectedTiles,
  setReportsData,
}) => {
  const [rows, setRows] = React.useState<Data[]>([]);
  const [rowsCount, setRowsCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [sortBy, setSortBy] = React.useState<{
    field: keyof Data | string | undefined;
    order: 'asc' | 'desc' | undefined;
  }>({
    field: undefined,
    order: undefined,
  });

  useEffect(() => {
    setController({ page: 0, rowsPerPage: controller.rowsPerPage });
  }, [selectedTiles]);

  useEffect(() => {
    const formData: any = {};
    const url = environment.reportThirteenURL;
    if (form) {
      formData.orderNo =
        form.orderNo && form.orderNo.order_no === 'All'
          ? []
          : [form.orderNo.order_no];
      formData.wrin = form.wrin && form.wrin.param;
      formData.startOrderDate = form.startReceiptDate;
      formData.endOrderDate = form.endReceiptDate;
      formData.discrepancy =
        selectedTiles && selectedTiles.label ? selectedTiles.id : 'all';
      formData.restaurant =
        form.restaurant.node_name && form.restaurant.node_name === 'All'
          ? []
          : form.restaurant
              .filter((node: { node_name: string }) => node.node_name !== 'All')
              .map((node: { node_name: string }) => node.node_name);
    }
    formData.sorting_for = sortBy ? sortBy.field : '';
    formData.sorting_type = sortBy ? sortBy.order : '';
    const getData = async () => {
      setIsLoading(true);
      const offset = controller.page * controller.rowsPerPage;
      apiPost(
        `${url}?offset=${offset}&limit=${controller.rowsPerPage}`,
        formData
      )
        .then((data: any) => {
          setRowsCount(data.totalListCount);
          eventBus.emit('dataEvent', data.lastUpdatedTime);
          data.list.forEach((dt: any) => {
            if (dt.discrepancy) {
              dt.discrepancy = dt.discrepancy
                .split(',')
                .map(
                  (word: string) =>
                    word &&
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
            }
          });
          setRows([...data.list]);
          if (formData.discrepancy === 'all') {
            setReportsData({ ...data });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
          setIsLoading(false);
          setRowsCount(0);
          setRows([]);
        });
    };
    const debouncedFetchData = debounce(getData, 400); // Debounce for 500 milliseconds

    // Call the debounced function when dependencies change
    debouncedFetchData();

    // Cleanup function to cancel any pending debounce calls when the component unmounts
    return () => {
      debouncedFetchData.cancel();
    };
  }, [sortBy, selectedTiles, form, controller]);

  const handleSort = (columnId: string) => {
    // Determine the sorting order for the clicked column
    setSortBy({
      field: columnId,
      order:
        sortBy.field === columnId && sortBy.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  return (
    <div className="custom-table">
      {isLoading && <Loading />}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          key="table_1"
          size="small"
        >
          <TableHead>
            <TableRow className="header">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={column.id}
                  sx={{ minWidth: `${column.minWidth}vw` }}
                >
                  <TableSortLabel
                    active={sortBy.field === column.id} // Check if sortBy.field matches the current column id
                    direction={
                      sortBy.field === column.id ? sortBy.order : 'asc'
                    }
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? ( // Check if there are no records
              <TableRow>
                <TableCell colSpan={11}>
                  {' '}
                  <Typography variant="body1" align="center">
                    No records found.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => {
                return (
                  <React.Fragment key={'comparison-' + index}>
                    <TableRow hover tabIndex={-1} key={'th1' + index}>
                      {columns.map((column) => {
                        const value =
                          row[column.id] !== null && row[column.id].toString()
                            ? row[column.id]
                            : '--';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ minWidth: `${column.minWidth}vw` }}
                          >
                            {column.id !== 'discrepancy' &&
                              column.id !== 'rop_orderdate' &&
                              value}
                            {column.id === 'rop_orderdate' && (
                              <span className="date-hover">
                                <DefinitionTooltip
                                  openOnHover
                                  definition={formatDate(value)}
                                >
                                  {expiryDateFormat(value)}
                                </DefinitionTooltip>
                              </span>
                            )}
                            {column.id === 'discrepancy' &&
                              row['discrepancy'] &&
                              row['discrepancy'].length > 0 &&
                              row['discrepancy'].map(
                                (item: string, index: number) => (
                                  <p
                                    className={`discrepancy-col ${item}`}
                                    key={index}
                                  >
                                    {item === 'Overage' && (
                                      <WarningFilled size={18} />
                                    )}
                                    {item === 'Missed' && (
                                      <WarningFilled size={18} />
                                    )}
                                    {item === 'Shortage' && (
                                      <WarningFilled size={18} />
                                    )}
                                    {item}
                                  </p>
                                )
                              )}
                            {column.id === 'discrepancy' &&
                              !row['discrepancy'] &&
                              '--'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={rowsCount ? rowsCount : 0}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="custom-pagination"
        sx={{
          [`& .${tablePaginationClasses.spacer}`]: {
            display: 'none',
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: 'center',
          },
        }}
      />
    </div>
  );
};
