export interface DIGITAL_JOURNEY {
  [key: string]: any; // This allows any property to be accessed with a string key
  discrepancy_class: any;
  reportname: string;
  reporttime: string;
  description: string;
  wrin: string;
  wrin_description: string;
  lot_no: string;
  exp_dt: string;
  category_name: string;
  device_type_name: string;
  tag_type: string;
  pallet_serial_number: string;
  action_event_type_id: string;
  event_type_id: number;
  process: string;
  node_name: string;
  discrepancy: string[];
}

export const initialData: DIGITAL_JOURNEY = {
  discrepancy_class: null,
  reportname: '',
  reporttime: '',
  description: '',
  wrin: '',
  wrin_description: '',
  lot_no: '',
  exp_dt: '',
  category_name: '',
  device_type_name: '',
  tag_type: '',
  pallet_serial_number: '',
  action_event_type_id: '',
  event_type_id: 0,
  process: '',
  node_name: '',
  discrepancy: [],
};
