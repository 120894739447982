import { DocumentAttachment } from '@carbon/icons-react';
import { Menu } from '../../interfaces/Menu';

const MenuData: Menu[] = [
  {
    text: 'EDSC Reports',
    link: '/reports',
    icon: DocumentAttachment,
  },
  // {
  //   text: 'Overview',
  //   link: '/overview',
  //   icon: Playlist,
  // },
  // {
  //   text: 'Map',
  //   link: '/map',
  //   icon: Map,
  // },
  // {
  //   text: 'Detailed View',
  //   link: '/overview',
  //   icon: WatsonHealthMagnify,
  // },
  // {
  //   text: 'Forecast',
  //   link: '/overview',
  //   icon: ChartClusterBar,
  // },
  // {
  //   text: 'Inventory',
  //   link: '/overview',
  //   icon: InventoryManagement,
  // },
];

export default MenuData;
