import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import './case-lifecycle.scss';
import {
  Checkmark,
  Delivery,
  DeliveryParcel,
  Receipt,
  Task,
  Document,
  Tag,
  WarningFilled,
} from '@carbon/icons-react';
import { CASE, initialCaseData } from '../../../interfaces/case';
import { useEffect } from 'react';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 24,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#c6c6c6',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#c6c6c6',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1.5,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#c6c6c6',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#D9D9D9',
  zIndex: 1,
  color: '#8d8d8d',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#6929C4',
    color: '#FFF',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#DCC7FF',
    color: '#000',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Receipt size={24} />,
    2: <Delivery size={24} />,
    3: <Task size={24} />,
    4: <Tag size={24} />,
    5: <DeliveryParcel size={24} />,
    6: <Document size={24} />,
    7: <Checkmark size={24} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  {
    heading: 'Wrapping Station',
    label: 'Supplier',
    type: 'cte1',
    field: 'supplier',
  },
  { heading: 'Shipping', label: 'Supplier', type: 'cte2', field: 'supplier' },
  { heading: 'Receipt', label: 'DC', type: 'cte3', field: 'dc' },
  { heading: 'Wrapping', label: 'DC', type: 'cte4', field: 'supplier' },
  { heading: 'Shipping', label: 'DC', type: 'cte5', field: 'receive' },
  {
    heading: 'Receipt',
    label: 'Restaurant',
    type: 'cte6',
    field: 'restaurant',
  },
  {
    heading: 'Consumption',
    label: 'Restaurant',
    type: 'cte7',
    field: 'restaurant',
  },
];
export default function CustomizedSteppers({
  caseDetails,
}: {
  caseDetails: CASE;
}) {
  const [activeStep, setActiveStep] = React.useState(0); // Should render based on status of case shipment
  const [discrepancyStep, setDiscrepancyStep] = React.useState(9);
  useEffect(() => {
    steps.map((step, index) => {
      if ((caseDetails as any)[`${step.type}_date`]) {
        setActiveStep(index);
        setDiscrepancyStep(index);
      }
      // const discrepancy = (caseDetails as any)[`${step.type}_discrepancy`];
      // if (index !== 0 && discrepancy !== 'NA' && discrepancy) {
      //   setDiscrepancyStep(index);
      //   setActiveStep(index);
      // }
    });
    return () => {
      caseDetails = initialCaseData;
    };
  }, [caseDetails]);

  return (
    <Stack sx={{ width: '100%', paddingTop: '5rem' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.type}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              key={`stepLabel-${step.type}`}
            >
              {index <= activeStep && (
                <div className="box" key={`box${step.type}`}>
                  <p className="box-label">
                    {step.heading}
                    {index !== 0 && index === discrepancyStep && (
                      <WarningFilled size={16} className="recipt-icon" />
                    )}
                  </p>
                  <div className="box-content">
                    {index < 3 && (
                      <p>
                        <strong>Pallet ID:</strong>{' '}
                        <span>{caseDetails.pallet_serial_no}</span>
                      </p>
                    )}
                    <p>
                      <strong>{step.label}:</strong>{' '}
                      <span>
                        {' '}
                        {(caseDetails as any)[`${step.type}_${step.field}`] ||
                          '--'}
                      </span>
                    </p>
                    <p>
                      <strong> Timestamp:</strong>{' '}
                      <span>
                        {(caseDetails as any)[`${step.type}_date`] || '--'}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
