export interface Column {
  id:
    | 'case_serial_number'
    | 'wrin'
    | 'wrin_description'
    | 'lot_no'
    | 'exp_dt'
    | 'supplier'
    | 'dc'
    | 'receipt_date'
    | 'put_away_time'
    | 'dispatch_timestamp'
    | 'days_in_storage'
    | 'destination'
    | 'discrepancy';
  label?: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
export const columns: Column[] = [
  {
    id: 'case_serial_number',
    label: 'Case ID',
    minWidth: 9,
  },
  {
    id: 'wrin',
    label: 'WRIN',
  },
  {
    id: 'wrin_description',
    label: 'WRIN Description',
    minWidth: 12,
  },
  {
    id: 'lot_no',
    label: 'Lot No.',
  },
  { id: 'exp_dt', label: 'Expiration Dt.' },
  {
    id: 'supplier',
    label: 'Supplier',
  },
  {
    id: 'dc',
    label: 'DC',
  },
  {
    id: 'receipt_date',
    label: 'Receipt Timestamp',
  },
  {
    id: 'put_away_time',
    label: 'Put Away Timestamp',
  },
  {
    id: 'dispatch_timestamp',
    label: 'Dispatch Timestamp',
  },
  {
    id: 'days_in_storage',
    label: 'Days in Storage',
  },
  {
    id: 'destination',
    label: 'Destination',
    minWidth: 10,
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
    minWidth: 11,
  },
];

export interface Data {
  sNo: number;
  case_id: string;
  case_serial_number: string;
  wrin: string;
  wrin_description: string;
  lot_no: string;
  exp_dt: string;
  supplier: string;
  dc: string;
  receipt_date: string;
  putaway_timestamp: string;
  dispatch_timestamp: string;
  days_in_storage: string;
  dest: string;
  discrepancy: number;
}

export const initialRowData: Data = {
  sNo: 0,
  case_id: '',
  case_serial_number: '',
  wrin: '',
  wrin_description: '',
  lot_no: '',
  exp_dt: '',
  supplier: '',
  dc: '',
  receipt_date: '',
  putaway_timestamp: '',
  dispatch_timestamp: '',
  days_in_storage: '',
  dest: '',
  discrepancy: 0,
};
