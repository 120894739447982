import moment from 'moment';

const formatDate = (date: moment.MomentInput) => {
  if (date !== '--' && date !== null && date !== '') {
    return moment(date).format('MM/DD/YYYY');
  } else {
    return '--';
  }
};

export const dateFormat = (date: moment.MomentInput) => {
  if (date !== '--' && date !== null && date !== '') {
    return moment(date).format('MM/DD; hh:mm A');
  } else {
    return '--';
  }
};

export const expiryDateFormat = (date: moment.MomentInput) => {
  if (date !== '--' && date !== null && date !== '') {
    return moment(date).format('MM/DD');
  } else {
    return '--';
  }
};

export const dateFormatHover = (date: moment.MomentInput) => {
  if (date !== '--' && date !== '' && date !== null) {
    return moment(date).format('MM/DD/YYYY; hh:mm:ss A');
  } else {
    return '--';
  }
};

export const lastUpdateDateFormat = (date: moment.MomentInput) => {
  if (date !== '--' && date !== null && date !== '') {
    return moment.utc(date).format('MM/DD; hh:mm A');
  } else {
    return '--';
  }
};

export const lastUpdateDateFormatHover = (date: moment.MomentInput) => {
  if (date !== '--' && date !== '' && date !== null) {
    return moment.utc(date).format('MM/DD/YYYY; hh:mm:ss A');
  } else {
    return '--';
  }
};

export default formatDate;
