import axios, { AxiosError, AxiosResponse } from 'axios';
import { environment } from '../environment';

const baseURL = environment.baseURL;

const getUser = () => {
  const user = sessionStorage.getItem('user');
  return user ? JSON.parse(user) : null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createAxiosInstance = (accessToken: string | null) => {
  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`, // Add the access token to the Authorization header
    },
  });
};

export const apiGet = async <T>(url: string): Promise<T> => {
  const user = getUser();
  const accessToken = user ? user.token : null;
  const axiosInstance = createAxiosInstance(accessToken);
  try {
    const response: AxiosResponse<T> = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError; // Cast error to AxiosError
    handleApiError(axiosError);
    throw error;
  }
};

export const apiPut = async <T>(url: string, data: T): Promise<void> => {
  const user = getUser();
  const accessToken = user ? user.token : null;
  const axiosInstance = createAxiosInstance(accessToken);
  try {
    await axiosInstance.put(url, data);
  } catch (error) {
    const axiosError = error as AxiosError; // Cast error to AxiosError
    handleApiError(axiosError);
    throw error;
  }
};

export const apiPatch = async <T>(url: string, data: T): Promise<void> => {
  const user = getUser();
  const accessToken = user ? user.token : null;
  const axiosInstance = createAxiosInstance(accessToken);
  try {
    await axiosInstance.patch(url, data);
  } catch (error) {
    const axiosError = error as AxiosError; // Cast error to AxiosError
    handleApiError(axiosError);
    throw error;
  }
};

export const apiPost = async <T, R>(url: string, data: T): Promise<R> => {
  const user = getUser();
  const accessToken = user ? user.token : null;
  const axiosInstance = createAxiosInstance(accessToken);
  try {
    const response = await axiosInstance.post<R>(url, data);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError; // Cast error to AxiosError
    handleApiError(axiosError);
    throw error;
  }
};

export const apiPostDownload = async <T, R>(
  url: string,
  data: T
): Promise<R> => {
  const user = getUser();
  const accessToken = user ? user.token : null;
  const header = {
    Authorization: `Bearer ${accessToken}`, // Add the access token to the Authorization header
  };
  try {
    return axios.post(url, data, {
      responseType: 'blob',
      headers: header,
    });
  } catch (error) {
    const axiosError = error as AxiosError; // Cast error to AxiosError
    handleApiError(axiosError);
    throw error;
  }
};

// Handle API errors
const handleApiError = (error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    console.error('Server Error:', error.response.status, error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Network Error:', error.request);
  } else {
    // Something else happened while setting up the request
    console.error('Request Error:', error.message);
  }
};
