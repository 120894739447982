import React, { useEffect, useState } from 'react';
import { Reset, Search } from '@carbon/icons-react';
import { Button, ComboBox, Dropdown, Link, TextInput } from '@carbon/react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import FilterableMultiSelect from '@carbon/react/lib/components/MultiSelect/MultiSelect';
import './filters.scss';
import formatDate from '../../../utils/date';
import OutsideClickDetector from '../../../utils/OutsideClickDetector';
import { apiGet } from '../../../services/apiService';
import { environment } from '../../../environment';
import {
  TYPE_1,
  TYPE_2,
  TYPE_3,
  defineds as DATE_FN,
} from '../../../assets/data/date-range.json';

const Dropdowns = require('../../../assets/data/report-filters.json');

const defined_date = DATE_FN;

// Define the type for the category data
type Category = {
  category_name: string;
  param: string[];
};

// Define the type for the wrin data
type WRIN = {
  wrin: string;
  param: string[];
};

// Define the type for the wrin data
type WRIN_Desc = {
  wrin_description: string;
  param: string[];
};
interface DateObj {
  startDate: Date | null; // Allow null as a valid value
  endDate: Date;
  key: string;
  isAll?: boolean;
}
interface Data {
  id: string;
  text: string;
  tabletext: string;
  form: any;
  range?: () => {
    startDate: null | Date;
    endDate: null | Date;
  };
}
interface Props {
  sendDataToParent: (data: Data) => void;
  selectedReport: (report: string) => void;
  backButtonClicked: string;
  savedFormData: Data | null;
  restTransViewType: string;
}

export const Filters = ({
  sendDataToParent,
  selectedReport,
  backButtonClicked,
  savedFormData,
  restTransViewType,
}: Props) => {
  const {
    reportsList,
    dc,
    categories,
    items,
    suppliers,
    lotno,
    wrins,
    origins,
  } = Dropdowns;
  const defaultAsLast7Days: DateObj = {
    startDate: defined_date.startOfLastWeek,
    endDate: defined_date.endOfLastWeek,
    key: 'selection',
  };
  const defaultDt: DateObj = {
    startDate: null,
    endDate: new Date(''),
    key: 'selection',
  };
  const defaultAsYesterday: DateObj = {
    startDate: defined_date.startOfYesterday,
    endDate: defined_date.endOfYesterday,
    key: 'selection',
  };

  const defaultAsToday: DateObj = {
    startDate: defined_date.startOfToday,
    endDate: defined_date.endOfToday,
    key: 'selection',
  };

  const defaultAsNext10Days: DateObj = {
    startDate: defined_date.startOfToday,
    endDate: defined_date.next10Days,
    key: 'selection',
  };

  // const defaultDispatchDt: DateObj = {
  //   startDate: defined_date.startOfToday,
  //   endDate: defined_date.endOfToday,
  //   key: 'selection',
  // };

  const [supplierDD, setSupplierDD] = useState([{ node_name: 'All' }]);
  const [categoryDD, setCategoryDD] = useState<Category[]>([]);
  const [categoryList, setCategoryList] = useState<Category[]>([
    { category_name: 'All', param: [] },
  ]);
  const [wrinList, setWrinList] = useState<WRIN[]>([
    { wrin: 'All', param: [] },
  ]);
  const [wrinDD, setWrinDD] = useState<WRIN[]>([]);
  const [wrinDesList, setWrinDesList] = useState<WRIN_Desc[]>([
    { wrin_description: 'All', param: [] },
  ]);
  const [wrinDescDD, setWrinDescDD] = useState<WRIN_Desc[]>([]);
  const [lotNoDD, setLotNoDD] = useState([{ lot_no: 'All' }]);
  const [dcDD, setDcDD] = useState([{ node_name: 'All' }]);
  const [restaurantDD, setrestaurantDD] = useState<{ node_name: string }[]>([
    { node_name: 'All' },
  ]);
  const [reportName, setReportName] = useState(reportsList.Admin[0]);
  const [originDD, setOriginDD] = useState([{ node_name: 'All' }]);
  const [orderNoDD, setOrderNo] = useState([{ order_no: 'All' }]);
  const [restOrderNumDD, setRestOrderNum] = useState([{ order_no: 'All' }]);
  const [reports, setReports] = useState(reportsList.Admin);

  const [form, setForm] = React.useState({
    reportName: reportsList.Admin[0],
    supplier: suppliers[0],
    expirationDt: `All`,
    expirationStartDate: `all`,
    expirationEndDate: `all`,
    dispatchDate: '',
    receiptDtRange: 'All',
    // reportFourReceiptDtRange: `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`,
    reportFourReceiptDtRange: '',
    startDispatchDate: 'all',
    endDispatchDate: 'all',
    startPutAwayDate: `${formatDate(defaultAsLast7Days.startDate)}`,
    endPutAwayDate: `${formatDate(defaultAsLast7Days.endDate)}`,
    // startPutAwayDate: `All`,
    // endPutAwayDate: `All`,
    startReceiptDate: `all`,
    endReceiptDate: `all`,
    // startReceiptDate: `${formatDate(defaultAsLast7Days.startDate)}`,
    // endReceiptDate: `${formatDate(defaultAsLast7Days.startDate)}`,
    dispatchTo: dc[0],
    category: categories[0],
    item: items[0],
    restaurant: [],
    wrin: wrins[0],
    putAwayDtRange: `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`,
    //putAwayDtRange: `All`,
    lotNo: lotno[0],
    dc: dc[0],
    origin: origins[0],
    caseIDSearch: '',
    orderNo: orderNoDD[0],
    summary_restaurant: restaurantDD[0],
  });

  const [state, setState] = useState([defaultDt]);

  const [putAwayState, setPutAwayState] = useState([defaultDt]);
  const [expiryState, setExpiryState] = useState([defaultDt]);
  // const [receiptState, setReceiptState] = useState([defaultAsLast7Days]);
  const [receiptState, setReceiptState] = useState([defaultDt]);
  const [isRcptDtOpen, setReceiptDt] = useState(false);

  const [isOpen, setDataRange] = useState(false);
  const [isExpDtOpen, setExpDt] = useState(false);
  const [isPutAwayDtOpen, setPutAwayDt] = useState(false);
  const [showDtRangeCalender, setDtRangeCalender] = useState('defined');
  const [showPutAwayDtRangeCalender, setPutAwayDtRangeCalender] =
    useState('defined');
  const [showReceiptDtRangeCalender, setReceiptDtRangeCalender] =
    useState('defined');
  const [caseSearch, setCaseSearch] = useState('');
  const [backBtnClicked, resetBackButtonClicked] = useState(backButtonClicked);

  React.useEffect(() => {
    if (!backBtnClicked) {
      // Perform any actions that depend on the updated form state here
      sendDataToParent({
        id: form.reportName.id,
        text: form.reportName.text,
        tabletext: form.reportName.tabletext,
        form: backBtnClicked
          ? savedFormData && { ...savedFormData.form }
          : { ...form },
      });
    }
  }, [form]); // Dependency on form state

  const setCategoryFilter = () => {
    if (
      form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
      form.reportName.id === 'cte5_vs_cte6_consumed_inv'
    ) {
      setCategoryDD([...categoryList]);
    } else {
      const data: Category[] = [
        {
          category_name: 'All',
          param: ['Cheese Processed', 'Shell Eggs'],
        },
        {
          category_name: 'Cheese Processed',
          param: ['Cheese Processed'],
        },
        {
          category_name: 'Shell Eggs',
          param: ['Shell Eggs'],
        },
      ];
      setCategoryDD([...data]);
    }
  };

  const setWRINFilter = () => {
    if (
      form.reportName.id === 'restaurant_case_transfer' ||
      form.reportName.id === 'cte4_vs_cte5' ||
      form.reportName.id === 'cte4_vs_cte5_order_accuracy'
    ) {
      setWrinDD([...wrinList]);
    } else {
      const data: WRIN[] = [
        {
          wrin: 'All',
          param: ['13297', '16160'],
        },
        {
          wrin: '13297',
          param: ['13297'],
        },
        {
          wrin: '16160',
          param: ['16160'],
        },
      ];
      setWrinDD([...data]);
    }
  };

  const setWRINDescFilter = () => {
    if (
      form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
      form.reportName.id === 'cte5_vs_cte6_consumed_inv'
    ) {
      setWrinDescDD([...wrinDesList]);
    } else {
      const data: WRIN_Desc[] = [
        {
          wrin_description: 'All',
          param: ['Cheese Slices', 'Eggs - Shell Cage Free'],
        },
        {
          wrin_description: 'Cheese Slices',
          param: ['Cheese Slices'],
        },
        {
          wrin_description: 'Eggs - Shell Cage Free',
          param: ['Eggs - Shell Cage Free'],
        },
      ];
      setWrinDescDD([...data]);
    }
  };

  useEffect(() => {
    if (!backBtnClicked) {
      selectedReport(form.reportName);
      clearFilter();
    }
  }, [form.reportName, restTransViewType]);

  useEffect(() => {
    setCategoryFilter();
    setWRINFilter();
    setWRINDescFilter();
  }, [form.reportName, wrinList, categoryList, wrinDesList]);

  const setSavedForm = async (savedFormData: any) => {
    for (const key in savedFormData.form) {
      if (Object.prototype.hasOwnProperty.call(savedFormData.form, key)) {
        form[key as keyof typeof form] = savedFormData.form[key];
      }
    }
    return { ...form };
  };

  useEffect(() => {
    if (backBtnClicked && savedFormData && savedFormData.form) {
      resetBackButtonClicked('');
      setSavedForm(savedFormData).then((result) => {
        setForm({ ...result });
        savedFormData = null;
      });
    }
  }, [backButtonClicked, savedFormData]);

  const handleClick = (item: any, params: string) => {
    if (params !== 'restaurant') {
      setForm({
        ...form,
        ...{ [params]: item },
      });
    }
    if (params === 'orderNo') {
      if (item && item.order_no && item.order_no === 'All') {
        setState([defaultAsLast7Days]);
        setForm((form) => {
          form.receiptDtRange = `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`;
          form.startDispatchDate = `${formatDate(defaultAsLast7Days.startDate)}`;
          form.endDispatchDate = `${formatDate(defaultAsLast7Days.endDate)}`;
          return { ...form };
        });
      } else {
        setState([defaultDt]);
        setForm((form) => {
          form.receiptDtRange = '';
          form.startDispatchDate = 'all';
          form.endDispatchDate = 'all';
          return { ...form };
        });
      }
    }
  };

  const onChangeRestaurant = (item: []) => {
    form.restaurant = item;
    if (reportName == form.reportName) {
      setForm({ ...form });
    }
  };
  const getRestaurantLabel = () => {
    return form.restaurant.length > 0
      ? form.restaurant
          .map((item: { node_name: string }) => item.node_name)
          .join(', ')
      : 'All';
  };

  const setDefaultReport = () => {
    const user = sessionStorage.getItem('user');
    const userRole = user && JSON.parse(user).role;
    setReportName(reportsList[userRole][0]);
    setReports(reportsList[userRole]);
    setForm({
      ...form,
      reportName: reportsList[userRole][0],
    });
  };

  useEffect(() => {
    setDefaultReport();

    const getSupplierData = async () => {
      apiGet<any>(environment.suppliers_dd)
        .then((data: any) => {
          data.unshift({ node_name: 'All' });
          setSupplierDD([...data]);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getSupplierData();
    const getWrinData = async () => {
      apiGet<any>(environment.wrin_dd)
        .then((data: any) => {
          if (data.length) {
            data.sort((a: any, b: any) => {
              const topValues = ['13297', '16160'];

              const aIsTop = topValues.includes(a.wrin);
              const bIsTop = topValues.includes(b.wrin);

              if (aIsTop && !bIsTop) return -1;
              if (!aIsTop && bIsTop) return 1;

              return a.wrin.localeCompare(b.wrin);
            });
          }
          data.map((data: any) => {
            data.param = [data.wrin];
          });
          data.unshift({
            wrin: '13297, 16160',
            param: ['13297', '16160'],
          });
          data.unshift({ wrin: 'All', param: [] });
          setWrinList(data);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getWrinData();
    const getCategoryData = async () => {
      apiGet<any>(environment.category_dd)
        .then((data: any) => {
          if (data.length) {
            data.sort((a: any, b: any) => {
              const topValues = ['Cheese Processed', 'Shell Eggs'];

              const aIsTop = topValues.includes(a.category_name);
              const bIsTop = topValues.includes(b.category_name);

              if (aIsTop && !bIsTop) return -1;
              if (!aIsTop && bIsTop) return 1;

              return a.category_name.localeCompare(b.category_name);
            });
          }
          data.map((data: any) => {
            data.param = [data.category_name];
          });
          data.unshift({
            category_name: 'Cheese Processed, Shell Eggs',
            param: ['Cheese Processed', 'Shell Eggs'],
          });
          data.unshift({ category_name: 'All', param: [] });
          setCategoryList([...data]);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getCategoryData();
    const getwrinDescDData = async () => {
      apiGet<any>(environment.wrin_desc_dd)
        .then((data: any) => {
          if (data.length) {
            data.sort((a: any, b: any) => {
              const topValues = ['Cheese Slices', 'Eggs - Shell Cage Free'];

              const aIsTop = topValues.includes(a.wrin_description);
              const bIsTop = topValues.includes(b.wrin_description);

              if (aIsTop && !bIsTop) return -1;
              if (!aIsTop && bIsTop) return 1;

              return a.wrin_description.localeCompare(b.wrin_description);
            });
          }
          data.map((data: any) => {
            data.param = [data.wrin_description];
          });

          data.unshift({
            wrin_description: 'Cheese Slices, Eggs - Shell Cage Free',
            param: ['Cheese Slices', 'Eggs - Shell Cage Free'],
          });
          data.unshift({ wrin_description: 'All', param: [] });
          setWrinDesList(data);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getwrinDescDData();

    const getLotNoData = async () => {
      apiGet<any>(environment.lotNoURL)
        .then((data: any) => {
          data.unshift({ lot_no: 'All' });
          setLotNoDD(data);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getLotNoData();
    const getOrderNoData = async () => {
      apiGet<any>(environment.orderNoURL)
        .then((data: any) => {
          data.unshift({ order_no: 'All' });
          data.sort((a: any, b: any) =>
            a.order_no === 'All'
              ? -1
              : b.order_no === 'All'
                ? 1
                : Number(a.order_no) - Number(b.order_no)
          );
          setOrderNo(data);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getOrderNoData();
    const getRestOrderNumData = async () => {
      apiGet<any>(environment.restaurantOrderNumbersURL)
        .then((data: any) => {
          data.unshift({ order_no: 'All' });
          data.sort((a: any, b: any) =>
            a.order_no === 'All'
              ? -1
              : b.order_no === 'All'
                ? 1
                : Number(a.order_no) - Number(b.order_no)
          );
          setRestOrderNum(data);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getRestOrderNumData();
    const getDcData = async () => {
      apiGet<any>(environment.dcURL)
        .then((data: any) => {
          data.unshift({ node_name: 'All' });
          setDcDD([...data]);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getDcData();
    const getRestaurantData = async () => {
      apiGet<any>(environment.restaurantsURL)
        .then((data: any) => {
          const originData = [...data];
          originData.unshift({ node_name: 'All' });
          setrestaurantDD([...data]);
          setOriginDD([...originData]);
        })
        .catch((error) => {
          console.log('Error fetching data: ' + error.message);
        });
    };
    getRestaurantData();
  }, []);

  const staticRanges = createStaticRanges(TYPE_1);

  const staticRanges_2 = createStaticRanges(TYPE_2);

  const staticRanges_3 = createStaticRanges(TYPE_1);

  const staticRanges_4 = createStaticRanges(TYPE_3);

  const onPutAwayDateChange = (item: any) => {
    const { startDate, endDate, key, label } = item.selection;
    setPutAwayDtRangeCalender(label);
    if (startDate && endDate && key) {
      if (label === 'defined') {
        setDataRange(false);
        setPutAwayDt(false);
        const startPutAwayDate = formatDate(startDate);
        const endPutAwayDate = formatDate(endDate);
        const date =
          startPutAwayDate === endPutAwayDate
            ? startPutAwayDate
            : startPutAwayDate + ' - ' + endPutAwayDate;
        setPutAwayState([item.selection]);
        setForm({
          ...form,
          startPutAwayDate,
          endPutAwayDate,
          putAwayDtRange: date,
        });
      } else {
        setPutAwayState([item.selection]);
      }
    } else {
      if (!item.selection.isAll) {
        setPutAwayState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          },
        ]);
      } else {
        closeDropdown();
        setForm({
          ...form,
          startPutAwayDate: 'all',
          endPutAwayDate: 'all',
          putAwayDtRange: 'All',
        });
        setPutAwayState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
            isAll: true,
          },
        ]);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      console.log(event.key);
      event.preventDefault(); // Prevent default action, if needed
      const target = event.target as HTMLInputElement;
      if (target.value) {
        setCaseSearch(target.value);
        onCaseIDSearch();
      }
    }
  };

  const onReceiptDateChange = (item: any) => {
    const { startDate, endDate, key, label } = item.selection;
    setReceiptDtRangeCalender(label);
    if (startDate && endDate && key) {
      if (label === 'defined') {
        setReceiptDt(false);
        const startReceiptDate = formatDate(startDate);
        const endReceiptDate = formatDate(endDate);
        const date =
          startReceiptDate === endReceiptDate
            ? startReceiptDate
            : startReceiptDate + ' - ' + endReceiptDate;
        setReceiptState([item.selection]);
        setForm({
          ...form,
          startReceiptDate,
          endReceiptDate,
          reportFourReceiptDtRange: date,
        });
      } else {
        setReceiptState([item.selection]);
      }
    } else {
      if (!item.selection.isAll) {
        setReceiptState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          },
        ]);
      } else {
        closeDropdown();
        setForm({
          ...form,
          startReceiptDate: 'all',
          endReceiptDate: 'all',
          reportFourReceiptDtRange: 'All',
        });
        setReceiptState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
            isAll: true,
          },
        ]);
      }
    }
  };

  const onExpiryDateChange = (item: any) => {
    const { startDate, endDate, key, label } = item.selection;
    setDtRangeCalender(label);
    if (startDate && endDate && key) {
      if (label === 'defined') {
        setExpDt(false);
        const start_Date = formatDate(startDate);
        const end_Date = formatDate(endDate);
        const date =
          start_Date === end_Date ? start_Date : start_Date + ' - ' + end_Date;
        setExpiryState([item.selection]);
        setForm({
          ...form,
          expirationStartDate: start_Date,
          expirationEndDate: end_Date,
          expirationDt: date,
        });
      } else {
        setExpiryState([item.selection]);
      }
    } else {
      if (!item.selection.isAll) {
        setExpiryState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          },
        ]);
      } else {
        closeDropdown();
        setForm({
          ...form,
          expirationStartDate: 'all',
          expirationEndDate: 'all',
          expirationDt: 'All',
        });
        setExpiryState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
            isAll: true,
          },
        ]);
      }
    }
  };

  const onDateChange = (item: any) => {
    const { startDate, endDate, key, label } = item.selection;
    setDtRangeCalender(label);
    if (startDate && endDate && key) {
      if (label === 'defined') {
        setDataRange(false);
        setExpDt(false);
        const startDispatchDate = formatDate(startDate);
        const endDispatchDate = formatDate(endDate);
        const date =
          startDispatchDate === endDispatchDate
            ? startDispatchDate
            : startDispatchDate + ' - ' + endDispatchDate;
        setState([item.selection]);
        setForm({
          ...form,
          startDispatchDate,
          endDispatchDate,
          receiptDtRange: date,
          expirationDt: date,
        });
      } else {
        setState([item.selection]);
      }
    } else {
      if (!item.selection.isAll) {
        setState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          },
        ]);
      } else {
        closeDropdown();
        setForm({
          ...form,
          endDispatchDate: 'all',
          startDispatchDate: 'all',
          receiptDtRange: 'All',
          expirationDt: 'All',
        });
        setState([
          {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
            isAll: true,
          },
        ]);
      }
    }
  };

  const onReceiptDtChange = () => {
    setDataRange(!isOpen);
  };

  const onReceiptDtChange_2 = () => {
    setExpDt(!isExpDtOpen);
  };

  const onReceiptDtChange_3 = () => {
    setReceiptDt(!isRcptDtOpen);
  };

  const onPutAwayDtChange = () => {
    setPutAwayDt(!isPutAwayDtOpen);
  };

  const closeDropdown = () => {
    setDataRange(false);
    setExpDt(false);
    setPutAwayDt(false);
    setReceiptDt(false);
  };

  const onDateRangeApply = (dateType: string) => {
    if (dateType === 'putAway') {
      const startPutAwayDate = formatDate(putAwayState[0].startDate);
      const endPutAwayDate = formatDate(putAwayState[0].endDate);
      const date =
        startPutAwayDate === endPutAwayDate
          ? startPutAwayDate
          : startPutAwayDate + ' - ' + endPutAwayDate;
      setForm({
        ...form,
        startPutAwayDate,
        endPutAwayDate,
        putAwayDtRange: date,
      });
    } else if (dateType === 'expiry') {
      const startDate = formatDate(expiryState[0].startDate);
      const endDate = formatDate(expiryState[0].endDate);
      const date =
        startDate === endDate ? startDate : startDate + ' - ' + endDate;
      setForm({
        ...form,
        expirationStartDate: startDate,
        expirationEndDate: endDate,
        expirationDt: date,
      });
    } else if (dateType === 'receipt_date') {
      const startReceiptDate = formatDate(receiptState[0].startDate);
      const endReceiptDate = formatDate(receiptState[0].endDate);
      const date =
        startReceiptDate === endReceiptDate
          ? startReceiptDate
          : startReceiptDate + ' - ' + endReceiptDate;
      setForm({
        ...form,
        startReceiptDate,
        endReceiptDate,
        reportFourReceiptDtRange: date,
      });
    } else {
      const startDispatchDate = formatDate(state[0].startDate);
      const endDispatchDate = formatDate(state[0].endDate);
      const date =
        startDispatchDate === endDispatchDate
          ? startDispatchDate
          : startDispatchDate + ' - ' + endDispatchDate;
      setForm({
        ...form,
        startDispatchDate,
        endDispatchDate,
        receiptDtRange: date,
      });
    }
    closeDropdown();
  };

  const clearFilter = () => {
    sessionStorage.removeItem('controller');
    sessionStorage.removeItem('sortBy');
    setPutAwayDtRangeCalender('defined');
    setDtRangeCalender('defined');
    setState([defaultDt]);
    setPutAwayState([defaultDt]);
    // setPutAwayState([defaultAsLast7Days]);
    setExpiryState([defaultDt]);
    setForm((form) => {
      form.dispatchDate = '';
      form.receiptDtRange = 'All';
      // form.reportFourReceiptDtRange = `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`;
      form.reportFourReceiptDtRange = `All`;

      if (
        form.reportName.id === 'cte2_shelf_life' ||
        form.reportName.id === 'cte4_dc_shelf_life'
      ) {
        form.expirationDt = `${formatDate(defaultAsNext10Days.startDate)} - ${formatDate(defaultAsNext10Days.endDate)}`;
        form.expirationStartDate = `${formatDate(defaultAsNext10Days.startDate)}`;
        form.expirationEndDate = `${formatDate(defaultAsNext10Days.endDate)}`;
        setExpiryState([defaultAsNext10Days]);
      } else {
        form.expirationDt = `All`;
        form.expirationStartDate = `all`;
        form.expirationEndDate = `all`;
      }
      form.dispatchTo = dcDD[0];
      form.category =
        form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
        form.reportName.id === 'cte5_vs_cte6_consumed_inv'
          ? categoryList[0]
          : {
              category_name: 'All',
              param: ['Cheese Processed', 'Shell Eggs'],
            };
      form.supplier = supplierDD[0];
      form.item =
        form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
        form.reportName.id === 'cte5_vs_cte6_consumed_inv'
          ? wrinDesList[0]
          : {
              wrin_description: 'All',
              param: ['Cheese Slices', 'Eggs - Shell Cage Free'],
            };
      form.restaurant = [];
      form.putAwayDtRange = `All`;
      // form.putAwayDtRange = `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`;
      form.startDispatchDate = 'all';
      form.endDispatchDate = 'all';
      form.startPutAwayDate = `All`;
      form.endPutAwayDate = `All`;
      // form.startPutAwayDate = `${formatDate(defaultAsLast7Days.startDate)}`;
      // form.endPutAwayDate = `${formatDate(defaultAsLast7Days.endDate)}`;
      const user = sessionStorage.getItem('user');
      const userRole = user && JSON.parse(user).role;
      if (
        form.reportName.id === 'cte1_vs_cte2' ||
        (form.reportName.id === 'cte4_vs_cte5' && userRole === 'Restaurant')
      ) {
        form.putAwayDtRange = `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`;
        form.startPutAwayDate = `${formatDate(defaultAsLast7Days.startDate)}`;
        form.endPutAwayDate = `${formatDate(defaultAsLast7Days.endDate)}`;
        setPutAwayState([defaultAsLast7Days]);
      }
      form.wrin =
        form.reportName.id === 'restaurant_case_transfer' ||
        form.reportName.id === 'cte4_vs_cte5' ||
        form.reportName.id === 'cte4_vs_cte5_order_accuracy'
          ? wrinList[0]
          : {
              wrin: 'All',
              param: ['13297', '16160'],
            };
      form.lotNo = lotNoDD[0];
      form.dc = dcDD[0];
      form.origin = originDD[0];
      form.caseIDSearch = '';
      form.orderNo = orderNoDD[0];

      if (
        form.reportName.id === 'cte4_vs_cte5_order_accuracy' ||
        form.reportName.id === 'cte3_vs_cte4'
      ) {
        form.reportFourReceiptDtRange = `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`;
        form.startReceiptDate = `${formatDate(defaultAsLast7Days.startDate)}`;
        form.endReceiptDate = `${formatDate(defaultAsLast7Days.endDate)}`;
        setReceiptState([defaultAsLast7Days]);
        setReceiptDtRangeCalender('defined');
      }
      if (
        form.reportName.id === 'cte5_vs_cte6_consumed_inv' ||
        form.reportName.id === 'daily_summary_inventory'
      ) {
        form.reportFourReceiptDtRange = `${formatDate(defaultAsYesterday.startDate)}`;
        form.startReceiptDate = `${formatDate(defaultAsYesterday.startDate)}`;
        form.endReceiptDate = `${formatDate(defaultAsYesterday.endDate)}`;
        setReceiptState([defaultAsYesterday]);
        setReceiptDtRangeCalender('defined');
      }

      if (form.reportName.id === 'cte5_vs_cte6_compare_daily_inv') {
        form.reportFourReceiptDtRange = `${formatDate(defaultAsToday.startDate)}`;
        form.startReceiptDate = `${formatDate(defaultAsToday.startDate)}`;
        form.endReceiptDate = `${formatDate(defaultAsToday.endDate)}`;
        setReceiptState([defaultAsToday]);
        setReceiptDtRangeCalender('defined');
      }

      // if (
      //   // Removed default filters for the reports below since data does not have dispatch date
      //   // form.reportName.id === 'cte2_vs_cte3' ||
      //   // form.reportName.id === 'cte4_vs_cte5' ||
      //   form.reportName.id === 'restaurant_case_transfer'
      // ) {
      //   setState([defaultDispatchDt]);
      //   form.receiptDtRange = `${formatDate(defaultDispatchDt.startDate)} - ${formatDate(defaultDispatchDt.endDate)}`;
      //   form.startDispatchDate = `${formatDate(defaultDispatchDt.startDate)}`;
      //   form.endDispatchDate = `${formatDate(defaultDispatchDt.endDate)}`;
      //   form.startPutAwayDate = 'all';
      //   form.endPutAwayDate = 'all';
      //   form.putAwayDtRange = '';
      // }
      if (
        form.reportName.id === 'cte4_vs_cte5' ||
        form.reportName.id === 'cte2_vs_cte3'
      ) {
        setState([defaultAsLast7Days]);
        form.receiptDtRange = `${formatDate(defaultAsLast7Days.startDate)} - ${formatDate(defaultAsLast7Days.endDate)}`;
        form.startDispatchDate = `${formatDate(defaultAsLast7Days.startDate)}`;
        form.endDispatchDate = `${formatDate(defaultAsLast7Days.endDate)}`;
      }
      if (
        form.reportName.id === 'cte3_vs_cte4' ||
        form.reportName.id === 'restaurant_case_transfer'
      ) {
        form.receiptDtRange = '';
        form.startDispatchDate = 'all';
        form.endDispatchDate = 'all';
      }
      if (
        form.reportName.id === 'cte2_vs_cte3' ||
        form.reportName.id === 'restaurant_case_transfer'
      ) {
        setReceiptDtRangeCalender('defined');
        setReceiptState([defaultDt]);
        form.startReceiptDate = 'all';
        form.endReceiptDate = 'all';
        form.startPutAwayDate = 'all';
        form.endPutAwayDate = 'all';
        form.putAwayDtRange = '';
      }
      form.summary_restaurant = restaurantDD[0];
      return { ...form }; // Return a new object to trigger re-render
    });
  };

  const onCaseIDSearch = () => {
    setForm({
      ...form,
      caseIDSearch: caseSearch,
    });
  };

  const getUserRole = () => {
    const user = sessionStorage.getItem('user');
    const userRole = user && JSON.parse(user).role;
    return userRole;
  };

  const filterItems = (menu: any) => {
    if (menu && menu.inputValue === 'All') {
      menu.inputValue = ''; // This is to show all the menu when the default menu is set to ALL
    }
    return menu?.item?.order_no
      .toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };

  const filterWrinItems = (menu: any) => {
    if (menu && menu.inputValue === 'All') {
      menu.inputValue = ''; // This is to show all the menu when the default menu is set to ALL
    }
    return menu?.item?.wrin
      .toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };

  const filterWrinDescItems = (menu: any) => {
    if (menu && menu.inputValue === 'All') {
      menu.inputValue = ''; // This is to show all the menu when the default menu is set to ALL
    }
    return menu?.item?.wrin_description
      .toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };
  const filterOrderNoItems = (menu: any) => {
    if (menu && menu.inputValue === 'All') {
      menu.inputValue = ''; // This is to show all the menu when the default menu is set to ALL
    }
    return menu?.item?.order_no
      .toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };

  const filterLotNoItems = (menu: any) => {
    if (menu && menu.inputValue === 'All') {
      menu.inputValue = ''; // This is to show all the menu when the default menu is set to ALL
    }
    return menu?.item?.lot_no
      .toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };

  const filterCategoryItems = (menu: any) => {
    if (menu && menu.inputValue === 'All') {
      menu.inputValue = ''; // This is to show all the menu when the default menu is set to ALL
    }
    return menu?.item?.category_name
      .toLowerCase()
      .includes(menu?.inputValue?.toLowerCase());
  };

  return (
    <div className={`filter-section ${form.reportName.id}`}>
      <Dropdown
        className="report-name"
        id="reportName"
        titleText="Report Name"
        initialSelectedItem={reports[0]}
        label="Select"
        items={reports}
        size="md"
        itemToString={(report) => (report ? report.text : '')}
        onChange={({ selectedItem }) => {
          selectedItem && setReportName(selectedItem);
          handleClick(selectedItem, 'reportName');
        }}
        selectedItem={form.reportName}
      />
      {form.reportName.id === 'cte1_vs_cte2' && (
        <>
          <Dropdown
            id="suplier"
            titleText="Supplier"
            label="Select"
            items={supplierDD}
            size="md"
            itemToString={(item) => (item ? item.node_name : '')}
            onChange={({ selectedItem }) => {
              selectedItem && handleClick(selectedItem, 'supplier');
            }}
            selectedItem={form.supplier}
          />
          <TextInput
            className="daterange-text"
            id="putAwayDateRange"
            labelText="Put Away Date"
            onClick={onPutAwayDtChange}
            value={form.putAwayDtRange}
            placeholder="Date Range"
            autoComplete="off"
          />
        </>
      )}
      {isPutAwayDtOpen && (
        <div
          className={`date-range ${form.reportName.id}-putaway ${form.reportName.id}-putaway-${getUserRole()} ${showPutAwayDtRangeCalender === 'defined' ? 'date-range-hidden' : 'date-range-display'}`}
        >
          <OutsideClickDetector onClickOutside={closeDropdown}>
            <DateRangePicker
              onChange={(item: any) => onPutAwayDateChange(item)}
              moveRangeOnFirstSelection={false}
              months={2}
              showDateDisplay={false}
              ranges={putAwayState}
              direction="horizontal"
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              staticRanges={staticRanges_3}
              showPreview={false}
              showMonthAndYearPickers={true}
              dragSelectionEnabled={true}
            />
            <Button
              className={`applyBtn ${showPutAwayDtRangeCalender === 'defined' ? 'applyBtnHidden' : ''}`}
              onClick={() => onDateRangeApply('putAway')}
            >
              Apply
            </Button>
          </OutsideClickDetector>
        </div>
      )}
      {form.reportName.id === 'cte2_vs_cte3' && (
        <Dropdown
          className="supplier-name"
          id="supplier"
          titleText="Supplier"
          initialSelectedItem={supplierDD[0]}
          label="Select"
          items={supplierDD}
          size="md"
          itemToString={(supplier) => (supplier ? supplier.node_name : '')}
          onChange={({ selectedItem }) => {
            selectedItem && handleClick(selectedItem, 'supplier');
          }}
          selectedItem={form.supplier}
        />
      )}
      {form.reportName.id === 'cte4_vs_cte5' && (
        <>
          <ComboBox
            className="order-no"
            id="orderNo"
            allowCustomValue
            shouldFilterItem={filterItems}
            items={orderNoDD}
            titleText="Order No."
            itemToString={(items: any) => (items ? items.order_no : '')}
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'orderNo');
            }}
            selectedItem={form.orderNo}
          />
          <ComboBox
            className="wrin-no custom-width"
            id="wrin"
            titleText="WRIN"
            allowCustomValue
            shouldFilterItem={filterWrinItems}
            items={wrinDD}
            size="md"
            itemToString={(items: any) => (items ? items.wrin : '')}
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'wrin');
            }}
            selectedItem={form.wrin}
          />
          <Dropdown
            className="dc"
            id="default"
            titleText="DC"
            initialSelectedItem={dcDD[0]}
            label="Select"
            items={dcDD}
            size="md"
            itemToString={(item) => (item ? item.node_name : '')}
            onChange={({ selectedItem }) => {
              selectedItem &&
                setForm({
                  ...form,
                  dc: selectedItem,
                });
            }}
            selectedItem={form.dc}
          />
          <FilterableMultiSelect
            className="mt-wrap dispatch-to"
            label={getRestaurantLabel()}
            id="dispatch"
            titleText="Dispatch To"
            key={JSON.stringify(form.restaurant)}
            initialSelectedItems={form.restaurant}
            items={restaurantDD}
            itemToString={(item: { node_name: string }) =>
              item ? item.node_name : ''
            }
            selectionFeedback="fixed"
            onChange={({ selectedItems }: any) => {
              selectedItems && onChangeRestaurant(selectedItems);
            }}
            selectedItems={form.restaurant}
          />
          {(getUserRole() === 'Admin' ||
            getUserRole() === 'DC' ||
            getUserRole() === 'Supplier') && (
            <TextInput
              className={`daterange-text cte4_vs_cte5-${getUserRole()}`}
              id="receiptDtRange"
              labelText="DC Dispatch Date"
              onClick={onReceiptDtChange}
              value={form.receiptDtRange}
              placeholder="All"
              autoComplete="off"
            />
          )}
          {(getUserRole() === 'Admin' ||
            getUserRole() === 'Restaurant' ||
            getUserRole() === 'Supplier') && (
            <TextInput
              className="daterange-text mt-wrap"
              id="putAwayDateRange"
              labelText="Rest. Put Away Date"
              onClick={onPutAwayDtChange}
              value={form.putAwayDtRange}
              placeholder="All"
              autoComplete="off"
            />
          )}
        </>
      )}
      {form.reportName.id === 'cte2_vs_cte3' && (
        <>
          <ComboBox
            className="wrin-no"
            id="wrin"
            titleText="WRIN"
            allowCustomValue
            shouldFilterItem={filterWrinItems}
            items={wrinDD}
            size="md"
            itemToString={(items: any) => (items ? items.wrin : '')}
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'wrin');
            }}
            selectedItem={form.wrin}
          />
          <ComboBox
            className="lot-no"
            id="lotNo"
            titleText="Lot No."
            allowCustomValue
            shouldFilterItem={filterLotNoItems}
            items={lotNoDD}
            size="md"
            itemToString={(items: any) => (items ? items.lot_no : '')}
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'lotNo');
            }}
            selectedItem={form.lotNo}
          />
        </>
      )}
      {form.reportName.id === 'cte3_vs_cte4' && (
        <Dropdown
          id="default"
          titleText="DC"
          initialSelectedItem={dcDD[0]}
          label="Select"
          items={dcDD}
          size="md"
          itemToString={(item) => (item ? item.node_name : '')}
          onChange={({ selectedItem }) => {
            selectedItem &&
              setForm({
                ...form,
                dc: selectedItem,
              });
          }}
          selectedItem={form.dc}
        />
      )}
      {(form.reportName.id === 'cte3_vs_cte4' ||
        form.reportName.id === 'cte2_vs_cte3' ||
        form.reportName.id === 'cte4_vs_cte5_order_accuracy') && (
        <>
          <TextInput
            className="daterange-text"
            id="reportFourReceiptDtRange"
            labelText={
              form.reportName.id === 'cte4_vs_cte5_order_accuracy'
                ? 'Order Date'
                : 'Receipt Date'
            }
            onClick={onReceiptDtChange_3}
            value={form.reportFourReceiptDtRange}
            placeholder="Date Range"
            autoComplete="off"
          />
          {isRcptDtOpen && (
            <div
              className={`date-range ${form.reportName.id}-custom-dt-range margin-left-putaway ${showReceiptDtRangeCalender === 'defined' ? 'date-range-hidden' : 'date-range-display'}`}
            >
              <OutsideClickDetector onClickOutside={closeDropdown}>
                <DateRangePicker
                  onChange={(item: any) => onReceiptDateChange(item)}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  showDateDisplay={false}
                  ranges={receiptState}
                  direction="horizontal"
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  staticRanges={staticRanges_3}
                  showPreview={false}
                  showMonthAndYearPickers={true}
                  dragSelectionEnabled={true}
                />
                <Button
                  className={`applyBtn ${showReceiptDtRangeCalender === 'defined' ? 'applyBtnHidden' : ''}`}
                  onClick={() => onDateRangeApply('receipt_date')}
                >
                  Apply
                </Button>
              </OutsideClickDetector>
            </div>
          )}
        </>
      )}
      {form.reportName.id === 'cte4_vs_cte5_order_accuracy' && (
        <ComboBox
          className="order-no"
          id="orderNo"
          titleText="Order No."
          allowCustomValue
          shouldFilterItem={filterOrderNoItems}
          items={restOrderNumDD}
          size="md"
          itemToString={(items: any) => (items ? items.order_no : '')}
          onChange={({ selectedItem }: any) => {
            selectedItem && handleClick(selectedItem, 'orderNo');
          }}
          selectedItem={form.orderNo}
        />
      )}
      {form.reportName.id === 'restaurant_case_transfer' && (
        <>
          <Dropdown
            className="origin"
            label="select"
            id="origin"
            titleText="Origin"
            items={originDD}
            size="md"
            itemToString={(item: { node_name: string }) =>
              item ? item.node_name : ''
            }
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'origin');
            }}
            selectedItem={form.origin}
          />
          <ComboBox
            className="wrin-no"
            id="wrin"
            titleText="WRIN"
            allowCustomValue
            shouldFilterItem={filterWrinItems}
            items={wrinDD}
            size="md"
            itemToString={(items: any) => (items ? items.wrin : '')}
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'wrin');
            }}
            selectedItem={form.wrin}
          />
          <TextInput
            className="daterange-text"
            id="receiptDtRange"
            labelText={
              restTransViewType == 'Transfer_In'
                ? 'Receipt Date'
                : 'Dispatch Date'
            }
            onClick={onReceiptDtChange}
            value={form.receiptDtRange}
            placeholder="All"
            autoComplete="off"
          />
        </>
      )}
      {(form.reportName.id === 'cte2_vs_cte3' ||
        form.reportName.id === 'cte1_vs_cte2' ||
        form.reportName.id === 'cte3_vs_cte4') && (
        <TextInput
          className="daterange-text"
          id="receiptDtRange"
          labelText="Dispatch Date"
          onClick={onReceiptDtChange}
          value={form.receiptDtRange}
          placeholder="All"
          autoComplete="off"
        />
      )}
      {isOpen && (
        <div
          className={`date-range ${form.reportName.id} ${form.reportName.id}-${getUserRole()} ${showDtRangeCalender === 'defined' ? 'date-range-hidden' : 'date-range-display'}`}
        >
          <OutsideClickDetector onClickOutside={closeDropdown}>
            <DateRangePicker
              onChange={(item: any) => onDateChange(item)}
              moveRangeOnFirstSelection={false}
              months={2}
              showDateDisplay={false}
              ranges={state}
              direction="horizontal"
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              staticRanges={staticRanges}
              showPreview={false}
              showMonthAndYearPickers={true}
              dragSelectionEnabled={true}
            />
            <Button
              className={`applyBtn ${showDtRangeCalender === 'defined' ? 'applyBtnHidden' : ''}`}
              onClick={() => onDateRangeApply('dispatch')}
            >
              Apply
            </Button>
          </OutsideClickDetector>
        </div>
      )}
      {form.reportName.id === 'cte2_vs_cte3' && (
        <Dropdown
          id="default"
          titleText="Dispatch To"
          initialSelectedItem={dcDD[0]}
          label="Select"
          items={dcDD}
          size="md"
          itemToString={(item) => (item ? item.node_name : '')}
          onChange={({ selectedItem }) => {
            selectedItem && handleClick(selectedItem, 'dispatchTo');
          }}
          selectedItem={form.dispatchTo}
        />
      )}
      {form.reportName.id === 'cte5_shelf_life' && (
        <TextInput
          className="daterange-text"
          id="expirationDt"
          labelText={'Expiration Date'}
          onClick={onReceiptDtChange_2}
          value={form.expirationDt}
          // placeholder="All"
          autoComplete="off"
        />
      )}
      {isExpDtOpen && (
        <div
          className={`date-range ${form.reportName.id} ${showDtRangeCalender === 'defined' ? 'date-range-hidden' : 'date-range-display'}`}
        >
          <OutsideClickDetector onClickOutside={closeDropdown}>
            <DateRangePicker
              id="expirationDt"
              onChange={(item: any) => onExpiryDateChange(item)}
              moveRangeOnFirstSelection={false}
              months={2}
              showDateDisplay={false}
              ranges={expiryState}
              direction="horizontal"
              staticRanges={staticRanges_2}
              showPreview={false}
              showMonthAndYearPickers={true}
              dragSelectionEnabled={true}
            />
            <Button
              className={`applyBtn ${showDtRangeCalender === 'defined' ? 'applyBtnHidden' : ''}`}
              onClick={() => onDateRangeApply('expiry')}
            >
              Apply
            </Button>
          </OutsideClickDetector>
        </div>
      )}
      {(form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
        form.reportName.id === 'cte5_vs_cte6_consumed_inv' ||
        form.reportName.id === 'daily_summary_inventory') && (
        <>
          <TextInput
            className="daterange-text"
            id="date"
            labelText={
              form.reportName.id === 'daily_summary_inventory'
                ? 'Date'
                : 'Date (EST)'
            }
            onClick={onReceiptDtChange_3}
            value={form.reportFourReceiptDtRange}
            placeholder="Date Range"
            autoComplete="off"
          />
          {isRcptDtOpen && (
            <div
              className={`date-range ${form.reportName.id} ${showReceiptDtRangeCalender === 'defined' ? 'date-range-hidden' : 'date-range-display'}`}
            >
              <OutsideClickDetector onClickOutside={closeDropdown}>
                <DateRangePicker
                  onChange={(item: any) => onReceiptDateChange(item)}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  showDateDisplay={false}
                  ranges={receiptState}
                  direction="horizontal"
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  staticRanges={staticRanges_4}
                  showPreview={false}
                  showMonthAndYearPickers={
                    form.reportName.id !== 'cte5_vs_cte6_compare_daily_inv'
                  }
                  dragSelectionEnabled={false}
                  maxDate={new Date()}
                  focusedRange={[0, 0]}
                  minDate={
                    form.reportName.id === 'cte5_vs_cte6_compare_daily_inv'
                      ? defined_date.endOfLast30Days
                      : undefined
                  }
                  preventSnapRefocus={true}
                />
                <Button
                  className={`applyBtn ${showReceiptDtRangeCalender === 'defined' ? 'applyBtnHidden' : ''}`}
                  onClick={() => onDateRangeApply('receipt_date')}
                >
                  Apply
                </Button>
              </OutsideClickDetector>
            </div>
          )}
        </>
      )}
      {form.reportName.id === 'cte2_shelf_life' && (
        <>
          <TextInput
            className="daterange-text"
            id="expirationDt"
            labelText="Expiration Date"
            onClick={onReceiptDtChange_2}
            value={form.expirationDt}
            placeholder="All"
            autoComplete="off"
          />
          <Dropdown
            id="suplier"
            titleText="Supplier"
            initialSelectedItem={supplierDD[0]}
            label="Select"
            items={supplierDD}
            size="md"
            itemToString={(item) => (item ? item.node_name : '')}
            onChange={({ selectedItem }) => {
              selectedItem &&
                setForm({
                  ...form,
                  supplier: selectedItem,
                });
            }}
            selectedItem={form.supplier}
          />
        </>
      )}
      {form.reportName.id === 'cte4_dc_shelf_life' && (
        <>
          <TextInput
            className="daterange-text"
            id="expirationDt"
            labelText="Expiration Date"
            onClick={onReceiptDtChange_2}
            value={form.expirationDt}
            placeholder="All"
            autoComplete="off"
          />
          <Dropdown
            id="default"
            titleText="DC"
            initialSelectedItem={dcDD[0]}
            label="Select"
            items={dcDD}
            size="md"
            itemToString={(item) => (item ? item.node_name : '')}
            onChange={({ selectedItem }) => {
              selectedItem &&
                setForm({
                  ...form,
                  dc: selectedItem,
                });
            }}
            selectedItem={form.dc}
          />
        </>
      )}
      {(form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
        form.reportName.id === 'cte5_shelf_life' ||
        form.reportName.id === 'cte5_vs_cte6_consumed_inv') && (
        <FilterableMultiSelect
          label={getRestaurantLabel()}
          id="restaurant"
          titleText="Restaurant"
          key={JSON.stringify(form.restaurant)}
          initialSelectedItems={form.restaurant}
          items={restaurantDD}
          itemToString={(item: { node_name: string }) =>
            item ? item.node_name : ''
          }
          selectionFeedback="fixed"
          onChange={({ selectedItems }: any) => {
            selectedItems && onChangeRestaurant(selectedItems);
          }}
          selectedItems={form.restaurant}
        />
      )}
      {form.reportName.id === 'daily_summary_inventory' && (
        <Dropdown
          id="restaurant"
          titleText="Restaurant"
          initialSelectedItem={form.summary_restaurant}
          label="Select"
          items={restaurantDD}
          itemToString={(item: any) => (item ? item.node_name : '')}
          onChange={({ selectedItem }: any) => {
            selectedItem &&
              setForm({
                ...form,
                summary_restaurant: selectedItem,
              });
          }}
          selectedItem={form.summary_restaurant}
        />
      )}
      {(form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
        form.reportName.id === 'cte5_shelf_life' ||
        form.reportName.id === 'cte2_shelf_life' ||
        form.reportName.id === 'cte4_dc_shelf_life' ||
        form.reportName.id === 'cte5_vs_cte6_consumed_inv') && (
        <ComboBox
          id="category"
          titleText="Category"
          allowCustomValue
          shouldFilterItem={filterCategoryItems}
          items={categoryDD}
          size="md"
          itemToString={(item) => (item ? item.category_name : '')}
          onChange={({ selectedItem }) => {
            selectedItem && handleClick(selectedItem, 'category');
          }}
          selectedItem={form.category}
        />
      )}
      {(form.reportName.id === 'cte5_shelf_life' ||
        form.reportName.id === 'cte2_shelf_life' ||
        form.reportName.id === 'cte4_dc_shelf_life' ||
        form.reportName.id === 'cte5_vs_cte6_compare_daily_inv' ||
        form.reportName.id === 'cte5_vs_cte6_consumed_inv') && (
        <ComboBox
          id="item"
          titleText="WRIN Description"
          allowCustomValue
          shouldFilterItem={filterWrinDescItems}
          items={wrinDescDD}
          size="md"
          itemToString={(item) => (item ? item.wrin_description : '')}
          onChange={({ selectedItem }) => {
            selectedItem && handleClick(selectedItem, 'item');
          }}
          selectedItem={form.item}
        />
      )}
      {form.reportName.id === 'cte3_vs_cte4' && (
        <ComboBox
          className="wrin-no"
          id="wrin"
          titleText="WRIN"
          allowCustomValue
          shouldFilterItem={filterWrinItems}
          items={wrinDD}
          size="md"
          itemToString={(items: any) => (items ? items.wrin : '')}
          onChange={({ selectedItem }: any) => {
            selectedItem && handleClick(selectedItem, 'wrin');
          }}
          selectedItem={form.wrin}
        />
      )}
      {form.reportName.id === 'cte4_vs_cte5_order_accuracy' && (
        <>
          <ComboBox
            className="wrin-no"
            id="wrin"
            titleText="WRIN"
            allowCustomValue
            shouldFilterItem={filterWrinItems}
            items={wrinDD}
            size="md"
            itemToString={(items: any) => (items ? items.wrin : '')}
            onChange={({ selectedItem }: any) => {
              selectedItem && handleClick(selectedItem, 'wrin');
            }}
            selectedItem={form.wrin}
          />
          <FilterableMultiSelect
            label={getRestaurantLabel()}
            id="restaurant"
            titleText="Restaurant"
            key={JSON.stringify(form.restaurant)}
            initialSelectedItems={form.restaurant}
            items={restaurantDD}
            itemToString={(item: { node_name: string }) =>
              item ? item.node_name : ''
            }
            selectionFeedback="fixed"
            onChange={({ selectedItems }: any) => {
              selectedItems && onChangeRestaurant(selectedItems);
            }}
            selectedItems={form.restaurant}
          />
        </>
      )}
      {form.reportName.id !== 'digital_journey' && (
        <div
          onClick={clearFilter}
          className={`clear-filter ${form.reportName.id}`}
        >
          <Link>
            <Reset size="24" />
            <span>Clear Filter</span>
          </Link>
        </div>
      )}
      {form.reportName.id === 'digital_journey' && (
        <div className="search-box">
          <Search size={18} onClick={onCaseIDSearch} />
          <TextInput
            className="case-input-box"
            id="caseSearch"
            labelText=" "
            onChange={(e) => setCaseSearch(e.target.value)}
            placeholder="Search Case ID"
            autoComplete="off"
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      )}
    </div>
  );
};
