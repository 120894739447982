import React from 'react';
import PropTypes from 'prop-types';
import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon';

interface MenuItemProps {
  dynamicComponent: CarbonIconType;
  size: number;
  className?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  dynamicComponent,
  size,
  className,
}) => {
  // Render the dynamic component
  const IconComponent = dynamicComponent;
  return <IconComponent size={size} className={className} />;
};

// Define prop types for prop validation
MenuItem.propTypes = {
  size: PropTypes.number.isRequired, // Make size required
};

export default MenuItem;
