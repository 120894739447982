import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Column, Grid, TextInput } from '@carbon/react';
import PasswordInput from '@carbon/react/lib/components/TextInput/PasswordInput';
import './login.scss';
const DEV_Users = require('../../assets/data/login_dev.json');
const PROD_Users = require('../../assets/data/login_prod.json');
import { environment } from '../../environment';
import Logo from '../../assets/img/McD.png';
import LoginLogo from '../../assets/img/loginpage.png';

interface LoginProps {
  onLogin: (username: string, password: string) => void;
}

export const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setError] = useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    // Validate credentials
    if (username && password) {
      onLogin(username, password);
    }
  };

  onLogin = (username, password) => {
    const USERS = environment.production ? PROD_Users : DEV_Users;
    USERS.map((user: any) => {
      if (username === user.username && password === user.password) {
        setError(false);
        const loggedInUser = { ...user };
        delete loggedInUser.password;
        sessionStorage.setItem('user', JSON.stringify(loggedInUser));
        navigate('/reports');
      }
    });
    setError(true);
  };

  return (
    <Grid className="login grid">
      <Column lg={6} className="left_panel">
        {/* <p className="title">IBM Consulting</p> */}
        <img src={Logo} alt="LoginLogo" className="login-logo" />
        <p className="description">Efficient Digital</p>
        <p className="description description_2">Supply Chain Platform</p>
        <img src={LoginLogo} alt="login-image" className="login-image" />
      </Column>
      <Column lg={10} className="right_panel">
        <h2 className="title">Login to get started</h2>
        {/* <p className="description">
          Description that can two or three lines. It describe what this section
          is for and what options the users need to select.
        </p> */}
        <label>
          <TextInput
            className="input-test-class"
            id="username"
            invalidText="Error message goes here"
            labelText="User Name"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            placeholder="User Name"
            size="md"
            type="text"
            warnText="Warning message"
            autoComplete="nope"
          />
        </label>
        <br />
        <label>
          <PasswordInput
            id="password"
            labelText="Password"
            autoComplete="new-password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        {errorMsg && (
          <p className="errorMsg">
            Please enter a valid username and password.
          </p>
        )}
        <br />
        <Button onClick={handleLogin} className="button">
          Submit
        </Button>
      </Column>
    </Grid>
  );
};
