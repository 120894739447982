import { Navigate, useLocation } from 'react-router-dom';
import Layout from '../components/layout/layout';
import Header from '../assets/data/menu.json';
import { Menu } from '../interfaces/Menu';

const PrivateRoutes = () => {
  const location = useLocation().pathname;
  const header: Menu[] = Header;
  const route = header.find((item: Menu) => item.link.includes(location));
  const auth = sessionStorage.getItem('user');
  return auth && route ? <Layout header={route} /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
