export interface Column {
  id:
    | 'wrin'
    | 'wrin_description'
    | 'node_name'
    | 'category_name'
    | 'systemCount'
    | 'total'
    | 'storageCount'
    | 'sales'
    | 'edsc_consumption'
    | 'prep'
    | 'inner_pack_count'
    | 'location'
    | 'discrepancy'
    | 'trash';
  label?: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}
export const columns: Column[] = [
  {
    id: 'wrin',
    label: 'WRIN',
    minWidth: 7,
  },
  { id: 'wrin_description', minWidth: 13, label: 'WRIN Description' },
  {
    id: 'node_name',
    label: 'Restaurant ID',
    minWidth: 6,
  },
  {
    id: 'category_name',
    minWidth: 12,
    label: 'Category',
  },
  {
    id: 'total',
    label: '',
  },
  {
    id: 'storageCount',
    label: 'EDSC Perpetual Inv.(Cases)',
    minWidth: 13,
  },
  {
    id: 'sales',
    label: 'Sales (In Cases)',
    minWidth: 10,
  },
  {
    id: 'edsc_consumption',
    label: 'EDSC Consumption (In Cases)',
  },
  {
    id: 'prep',
    label: '',
    minWidth: 12,
  },
  {
    id: 'inner_pack_count',
    label: 'Inner Packs',
  },
  {
    id: 'trash',
    label: 'Trash (Cases)',
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
  },
];

export const columns_2: Column[] = [
  {
    id: 'total',
    label: 'Total',
  },
  {
    id: 'storageCount',
    label: 'Freezer | Dry | Cooler',
  },
  {
    id: 'prep',
    label: 'Prep Area',
  },
];

export interface SubColumn {
  id:
    | 'case_serial_number'
    | 'lot_no'
    | 'exp_date'
    | 'item_shelf_life'
    | 'discrepancy';
  label?: string;
  minWidth?: number;
  align?: 'right';
}

export const subColumns: SubColumn[] = [
  {
    id: 'case_serial_number',
    label: 'Case ID',
  },
  {
    id: 'lot_no',
    label: 'Lot No.',
  },
  {
    id: 'exp_date',
    label: 'Expiration Dt.',
  },
  {
    id: 'item_shelf_life',
    label: 'Days of Shelf Life',
  },
  {
    id: 'discrepancy',
    label: 'Discrepancy',
  },
];

export interface Data {
  wrin: string;
  node_name: string;
  wrin_description: string;
  category_name: string;
  systemCount: string;
  total: string;
  storageCount: string;
  sales: string;
  edsc_consumption: string;
  prep: string;
  trash: string;
  inner_pack_count: string;
  location: string;
  discrepancy: [];
  cases: [];
}
